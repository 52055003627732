class Curiosity {
  static object = {
    asteroidField: {
      name: "Asteroid Field",
      description:
        "A field of asteroids, dangerous for unequiped ships. Ships require the technology mass deflector to enter or pass through an asteroid field.",
      shortDescription: "Require mass deflector to enter",
    },
    mineralField: {
      name: "Mineral Field",
      description:
        "A field of minerals, from which ships can mine. If you control the space area of this system at the start of the round, you gain 1 mineral.",
      shortDescription: "Gain 1 mineral",
    },
    uteriumDust: {
      name: "Uterium Dust",
      description:
        "A field of uterium dust, a very rare resource. If you control the space area of this system at the start of the round, you gain 1 credit.",
      shortDescription: "Gain 1 credit",
    },
    fluxAnomaly: {
      name: "Flux Anomaly",
      description:
        "A flux anomaly, never observed in history. If you control the space area of this system at the start of the round, you gain 1 science.",
      shortDescription: "Gain 1 science",
    },
    nebula: {
      name: "Nebula",
      description:
        "A nebula, disturbing ships navigation systems. Ships cannot pass through a nebula. They have to stop in the nebula. When leaving a nebulae, the range of all ships are set to 1.",
      shortDescription: "Reduce ship range.",
    },
    storm: {
      name: "Storm",
      description:
        "A violent storm that damages ships. At the start of each round, each ship in the storm system takes 1 damage.",
      shortDescription: "Deal 1 damage to ships",
    },
    crystalField: {
      name: "Crystal Field",
      description:
        "Crystal are pure minerals. If you control the space area of this system at the start of the round, you gain 2 mineral.",
      shortDescription: "Gain 2 mineral",
    },
  };

  static getCuriosityObject(name) {
    if (name === this.object.asteroidField.name) {
      return this.object.asteroidField;
    }
    if (name === this.object.mineralField.name) {
      return this.object.mineralField;
    }
    if (name === this.object.uteriumDust.name) {
      return this.object.uteriumDust;
    }
    if (name === this.object.fluxAnomaly.name) {
      return this.object.fluxAnomaly;
    }
    if (name === this.object.nebula.name) {
      return this.object.nebula;
    }
    if (name === this.object.storm.name) {
      return this.object.storm;
    }
    if (name === this.object.crystalField.name) {
      return this.object.crystalField;
    }
    return null;
  }
}

module.exports = Curiosity;
