import React, { useState } from "react";
import LifeBarView from "../Units/LifeBarView.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";

const ImagesUrl = require("../../Data/Other/ImagesUrl.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");

const UnitImageView = ({
  unit,
  size = "1em",
  scale = null,
  labelBelow = null,
  showLifeBar = true,
}) => {
  const playerData = StaticGameData.getPlayerData();
  const color = unit.color;
  console.log("UnitImageView: unit : ", unit);
  console.log(
    "Unit.getSuperShortAbbreviation(unit.type) : ",
    Unit.getSuperShortAbbreviation(unit.type)
  );
  const getImageFileName = (unit, color) => {
    return (
      ImagesUrl.URL_UNIT_BASE +
      Unit.getSuperShortAbbreviation(unit.type) +
      "_" +
      color +
      ".png"
    );
  };

  const colorUnit = CustomChars.replaceFirstLetterByLowerCase(color);
  //const imageUrl = getImageFileName(unit, color);

  const imageUrl = getImageFileName(unit, colorUnit);
  //console.log("UnitImageView: size : ", size);

  const imageStyle = {
    //pointerEvents: "none"
    zIndex: -5000,
  };
  if (!scale) {
    imageStyle.width = "auto";
    imageStyle.height = size;
  }
  if (scale) {
    //imageStyle.transformOrigin = "bottom center";
    imageStyle.transform = `scale(${scale}) `;
    //imageStyle.backgroundColor = "blue";
  }

  //console.log("UnitImageView: imageStyle : ", imageStyle);

  return (
    <span
      style={{
        position: "relative",
        //pointerEvents: "none",
        //width: "auto",
        //height: "auto",
        //backgroundColor: "RGB(0,0,0,0.3)",
        //display: "flex",
        //flexDirection: "column",
        //justifyContent: "center",
        //alignItems: "center",
        //zIndex: -2000,
      }}
    >
      <img
        //style={imageStyle}
        style={imageStyle}
        src={imageUrl} // Replace with the actual image URL
        alt={"UNIT_" + unit.type}
      />
      {labelBelow && <div>{labelBelow}</div>}
      <div
        style={{
          width: size,
          position: "absolute",
          top: "70%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {showLifeBar && unit.class !== Unit.CLASS_STRUCTURE && (
          <LifeBarView
            hp={Unit.getHP(playerData, unit)}
            maxHp={Unit.getMaxHP(playerData, unit)}
          ></LifeBarView>
        )}
      </div>
    </span>
  );
};

export default UnitImageView;
