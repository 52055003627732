const LogBook = require("../Connection/LogBook");
const LogMessage = require("../Connection/LogMessage");
const Map = require("../MapData/Map");
const TechTree = require("../Technology/TechTree");

class ScoreData {
  static VP_PER_PLANET = 1;
  static VP_PER_TECH = 0.2;

  static create() {
    const data = {
      logBoook: LogBook.createLogBook(),
      score: 0,
    };

    LogBook.generateAddMessage(
      data.logBoook,
      "Initial victory points (VP) is " + data.score + "."
    );

    LogBook.createMessage(
      data.logBoook,
      [{ content: "ROUND " + 1 }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );

    return data;
  }

  static getLastLog(data) {
    LogBook.correctCursor(data.logBoook);
    return data.logBoook;
  }

  static getScore(data) {
    return data.score;
  }

  static generateAddMessage(scoreData, text, itemArray) {
    const log = ScoreData.getLastLog(scoreData);
    LogBook.generateAddMessage(log, text, itemArray);
  }

  /*static createNewLogBook(scoreData) {
    scoreData.logArray.push(LogBook.createLogBook());
  }*/

  static adminAfterRound(playerData, scoreData, newRoundNumber) {
    const log = ScoreData.getLastLog(scoreData);
    //this.createNewLogBook(scoreData);

    //Map
    const map = playerData.map;

    //get data to calculate score
    const planets = Map.getPlanetsFromFaction(map, playerData.faction.name);
    const tech = TechTree.getTechOwned(playerData.techTree);

    const amountPlanets = planets.length;
    const amountTech = tech.length;

    const vpForPlanet = amountPlanets * ScoreData.VP_PER_PLANET;
    const vpForTech = amountTech * ScoreData.VP_PER_TECH;

    LogBook.createMessage(
      log,
      [{ content: "ROUND " + newRoundNumber }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );

    //add points
    ScoreData.addScore(
      scoreData,
      vpForPlanet,
      "Controlling " + amountPlanets + " planets."
    );

    ScoreData.addScore(
      scoreData,
      vpForTech,
      "Owning " + amountTech + " technologies."
    );

    LogBook.generateAddMessage(
      log,
      "Total victory points (VP) is " + scoreData.score + "."
    );
  }

  static addScore(scoreData, amount, explanationText, explanationItems = []) {
    LogBook.correctCursor(scoreData.logBoook);
    scoreData.score += amount;
    const log = ScoreData.getLastLog(scoreData);
    const explanationTextWithVP = explanationText + " : " + amount + " VP.";
    LogBook.generateAddMessage(log, explanationTextWithVP, explanationItems);
  }
}

module.exports = ScoreData;
