import React, { useState } from "react";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction.js";

const IMAGE_LOGO_BASE = "/Images/";
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");

const Logo = ({
  logoName,
  heightRem,
  centerYToText = true,
  assetType = null,
}) => {
  if (!logoName) {
    //return;
    throw new Error("No logo to display");
  }

  const getMinorFactionFileName = (minorFactionName) => {
    return ImagesUrl.URL_POPULATION;
  };

  const getImageFileName = (logoName) => {
    if (assetType) {
      if (assetType === "minor_faction") {
        return (
          IMAGE_LOGO_BASE +
          "MinorFaction/" +
          MinorFaction.getImageFileName(logoName)
        );
      }
    }

    if (logoName === "mineral") {
      return ImagesUrl.URL_MINERAL;
    }

    if (logoName === "energy") {
      return ImagesUrl.URL_ENERGY;
    }

    if (logoName === "science") {
      return ImagesUrl.URL_SCIENCE;
    }
    if (logoName === "credit") {
      return ImagesUrl.URL_CREDIT;
    }
    if (logoName === "population") {
      return ImagesUrl.URL_POPULATION;
    }
    if (logoName === "size") {
      return ImagesUrl.URL_SIZE;
    }
    if (logoName === "influence") {
      return ImagesUrl.URL_INFLUENCE;
    }

    if (logoName === "cargo") {
      return ImagesUrl.URL_CARGO;
    }

    if (logoName === "space combat") {
      return ImagesUrl.URL_SPACE_COMBAT;
    }

    if (logoName === "ground combat") {
      return ImagesUrl.URL_GROUND_COMBAT;
    }

    if (logoName === "chat icon") {
      return ImagesUrl.URL_CHAT_ICON;
    }

    if (logoName === "transaction icon") {
      return ImagesUrl.URL_TRANSACTION_ICON;
    }

    if (logoName === "menu icon") {
      return ImagesUrl.URL_MENU_ICON;
    }

    if (logoName === "info icon") {
      return ImagesUrl.URL_INFO_ICON;
    }

    if (logoName === "tech blue") {
      return ImagesUrl.URL_IMAGE_TECH_BLUE;
    }
    if (logoName === "tech yellow") {
      return ImagesUrl.URL_IMAGE_TECH_YELLOW;
    }
    if (logoName === "tech red") {
      return ImagesUrl.URL_IMAGE_TECH_RED;
    }
    if (logoName === "tech green") {
      return ImagesUrl.URL_IMAGE_TECH_GREEN;
    }
    if (logoName === "context_open") {
      return ImagesUrl.URL_CONTEXT_OPEN;
    }
    if (logoName === "context_close") {
      return ImagesUrl.URL_CONTEXT_CLOSE;
    }
    if (logoName === "planet_info") {
      return ImagesUrl.URL_PLANET_INFO;
    }
    if (logoName === "build") {
      return ImagesUrl.URL_BUILD;
    }

    if (logoName === "mission") {
      return ImagesUrl.URL_MISSION;
    }

    if (logoName === "mission_accomplished") {
      return ImagesUrl.URL_MISSION_ACCOMPLISHED;
    }

    if (logoName === "action") {
      return ImagesUrl.URL_ACTION;
    }

    if (logoName === "vp") {
      return ImagesUrl.URL_VP;
    }

    throw new Error("Logo image not found : " + logoName);
  };

  //console.log("FactionLogo heightRem: ", heightRem);
  const imageStyle = {
    width: "auto", // Adjust this percentage as needed
    height: heightRem ? `${heightRem}` : "1.2em", // Conditional height
    //height: "1rem",
  };

  return (
    <span
      style={{
        position: centerYToText ? "relative" : "unset",
        top: centerYToText ? "0.1em" : "unset",
        left: centerYToText ? 0 : "unset",
        //backgroundColor: "yellow",
      }}
    >
      <img
        style={imageStyle}
        src={getImageFileName(logoName)} // Replace with the actual image URL
        alt={"LOGO_" + logoName}
      />
    </span>
  );
};

export default Logo;
