import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const LoginMainScreen = ({ setIsUserLogged }) => {
  const [activeScreen, setActiveScreen] = useState("Login");

  return (
    <div>
      {activeScreen == "Login" && (
        <LoginScreen
          setActiveScreen={setActiveScreen}
          setIsUserLogged={setIsUserLogged}
        ></LoginScreen>
      )}
      {activeScreen == "Register" && <RegisterScreen></RegisterScreen>}
    </div>
  );
};

export const LoginScreen = ({ setActiveScreen, setIsUserLogged }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform login validation and logic here
    // You can use the username and password states to perform authentication
    // Create an object with the login credentials
    const loginData = {
      username,
      password,
    };

    // Send a POST request to the server for login validation
    fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        if (data.success) {
          // Login successful, do something (e.g., update UI, set user as logged in)
          console.log("Login successful");
          setIsUserLogged(true);
        } else {
          // Login failed, handle error (e.g., show error message)
          console.log("Login failed:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error occurred during login:", error);
      });

    // Reset the form
    setUsername("");
    setPassword("");
  };

  const handleForgotPassword = () => {
    // Handle forgot password logic here
  };

  const handleRegister = () => {
    // Handle register logic here
    setActiveScreen("Register");
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username : </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div>
          <label htmlFor="password">Password : </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <button onClick={handleForgotPassword}>Forgot Password</button>
      <button onClick={handleRegister}>Create Account</button>
    </div>
  );
};

export const RegisterScreen = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the email fields match
    if (formData.email !== formData.confirmEmail) {
      setErrorMessage("Emails do not match.");
      return;
    }

    // Check if the password fields match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    // Clear any previous error message if the form is valid
    setErrorMessage("");

    try {
      // Send the form data to the server for account creation
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();
      console.log("Registration response:", data);

      if (data.success) {
        // Registration successful, do something (e.g., show success message, redirect, etc.)
        setRegisteredUser(data.user); // Save the registered user information
        setRegistrationSuccess(true); // Set registration success flag to true
      } else {
        // Registration failed, handle the error
        setErrorMessage(data.message);
      }

      // Handle the server response as needed (e.g., show success message, redirect, etc.)
    } catch (error) {
      console.error("Error occurred during registration:", error);
      // Handle the error as needed (e.g., show error message)
    }
  };

  if (registrationSuccess && registeredUser) {
    return <RegistrationSuccess user={registeredUser} />;
  }

  return (
    <div>
      <h1>Register Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username: </label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email: </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmEmail">Confirm Email: </label>
          <input
            type="email"
            id="confirmEmail"
            name="confirmEmail"
            value={formData.confirmEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password: </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password: </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <div>
          <button type="submit">Register</button>
        </div>
      </form>
    </div>
  );
};

const RegistrationSuccess = ({ user }) => {
  return (
    <div>
      <h2>Registration Successful!</h2>
      <p>User Information:</p>
      <p>Username: {user.username}</p>
      <p>Email: {user.email}</p>

      <button onClick={() => window.location.reload()}>Go Back to Login</button>
    </div>
  );
};
