import React, { useState } from "react";
import FactionLogo from "../Utils/FactionLogo.jsx";
import UnitImageView from "./UnitImageView.jsx";
import Popup from "../../Data/Other/Popup.js";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const FleetOneLineView = ({
  fleet,
  classToDisplay = Fleet.UNIT_CLASS_ALL,
  showFactionLogo = true,
}) => {
  const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
  const groundForces = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE);
  const structures = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE);

  let allUnits = [];
  if (
    classToDisplay === Fleet.UNIT_CLASS_ALL ||
    classToDisplay === Fleet.UNIT_CLASS_SHIP
  ) {
    allUnits = allUnits.concat(ships);
  }
  if (
    classToDisplay === Fleet.UNIT_CLASS_ALL ||
    classToDisplay === Fleet.UNIT_CLASS_GROUND_FORCE
  ) {
    allUnits = allUnits.concat(groundForces);
  }
  if (
    classToDisplay === Fleet.UNIT_CLASS_ALL ||
    classToDisplay === Fleet.UNIT_CLASS_STRUCTURE
  ) {
    allUnits = allUnits.concat(structures);
  }

  const DisplayUnits = ({ units }) => {
    if (units.length === 0) {
      return;
    }
    return (
      <span>
        {showFactionLogo && (
          <FactionLogo factionName={fleet.faction}></FactionLogo>
        )}
        {units.map((unit, index) => {
          return (
            <span key={index}>
              <UnitImageView unit={unit}></UnitImageView>
            </span>
          );
        })}{" "}
      </span>
    );
  };

  const handleClick = () => {
    Popup.addLayer({ name: "FleetDetailedView", fleet: fleet });
  };

  return (
    <span onClick={handleClick} style={{ fontSize: "3em" }}>
      <DisplayUnits units={allUnits}></DisplayUnits>
    </span>
  );
};

export default FleetOneLineView;
