import React, { useState, useEffect, useMemo } from "react";
import useViewportSize from "./CustomHook/UseViewportSize";
import "../CSS/Menu.css";
import "../CSS/MapGrid.css";
import ArrowButton from "./Utils/ArrowButton";
import SystemView from "./Map/SystemView";
import System from "../Data/GameData/MapData/System";
import StaticGameData from "../Data/GameData/StaticGameData";
import TaskBarView from "./TaskBarView";
import NavigationView from "./NavigationView";
import GameGlobalLayout from "./GameGlobalLayout";
import FactionLogo from "./Utils/FactionLogo";
import Logo from "./Utils/Logo";
import ResourceBarView from "./Utils/ResourceBarView";
import BaseButton from "./Utils/BaseButton";
import PlanetViewActivation from "./ManageSystem/ActivationView/Objects/PlanetViewActivation";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import WarningAction from "../Data/GameData/ActionCommon/WarningAction";

const CreateMap = require("../Data/GameData/MapData/CreateMap.js");
const Request = require("../Common/Requests/Request.js");
const Map = require("../Data/GameData/MapData/Map.js");
const Popup = require("../Data/Other/Popup.js");

const GameMenuView = ({ setRouteMainHandler }) => {
  // Using the custom hook to manage viewport size
  const viewportSize = useViewportSize();
  //console.log("Viewport size: ", viewportSize);
  //console.log("MapView playerData: ", playerData);

  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [
      {
        text: "Map",
        callback: () => {
          Popup.unStackToMap();
        },
      },
    ],
  };

  const nextRound = () => {
    UIMessage.displayConfirmMessage(
      "Next Round",
      "Are you sure you want to go finish this round?",
      () => {
        Request.nextRound();
        /*WarningAction.warningNextRound(playerData, () => {
          Request.nextRound();
        });*/
      }
    );
  };

  const info = () => {
    Popup.addLayer({
      name: "HelpPopup",
      helpReference: "helpMainScreen",
    });
  };

  const load = () => {
    UIMessage.displayConfirmMessage(
      "Load Game",
      "Are you sure you want to load the game?",
      () => {
        Request.updateAdmin("LoadTestState");
      }
    );
  };

  const save = () => {
    UIMessage.displayConfirmMessage(
      "Save Game",
      "Are you sure you want to save the game?",
      () => {
        Request.updateAdmin("SaveTestState");
      }
    );
  };

  const refresh = () => {
    Request.getGameState();
  };

  const quitGame = () => {
    setRouteMainHandler({ activeScreen: "MainMenu" });
  };

  const technologies = () => {
    Popup.addLayer({
      name: "TechTreeView",
      playerInGameId: playerData.playerInGameId,
    });
  };

  const DisplayMenuButtons = () => {
    return (
      <div className="div-ver-fill" style={{ fontSize: "2em" }}>
        <div>
          {" "}
          <BaseButton onClick={nextRound}>Next Round</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={save}>Save State</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={load}>Load State</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={refresh}>Refresh Game</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={quitGame}>Quit Game</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={technologies}>Technologies</BaseButton>
        </div>
        <div>
          {" "}
          <BaseButton onClick={info}>Info</BaseButton>
        </div>
      </div>
    );
  };

  const DisplayMenu = () => {
    return (
      <div>
        <DisplayMenuButtons></DisplayMenuButtons>
      </div>
    );
  };

  return (
    <div className="div-fill">
      <GameGlobalLayout>
        <div className="div-fill">
          <DisplayMenu></DisplayMenu>
        </div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
    </div>
  );
};

export default GameMenuView;
