import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "../Map/IconGridView";
import IconView from "../Icon/IconView";
import FactionLogo from "../Utils/FactionLogo";
import TitleView from "../TitleView";
import Logo from "../Utils/Logo";
import ImagesUrl from "../../Data/Other/ImagesUrl";
import Planet from "../../Data/GameData/MapData/Planet";
import Unit from "../../Data/GameData/MapData/Unit";
import UnitsStackView from "../Units/UnitsStackView";
import Style from "../../Common/Config/Style";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Rules from "../../Data/GameData/Game/Rules";
import UnitImageView from "./UnitImageView";
import Popup from "../../Data/Other/Popup.js";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject.js";
import PlanetBonusView from "./PlanetBonusView.jsx";
import Map from "../../Data/GameData/MapData/Map.js";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");

const IMAGE_PLANET_VOLCANIC = "/Images/Map/Objects/planet_volcanic.png";
const IMAGE_PLANET_ICE = "/Images/Map/Objects/planet_ice.png";
const IMAGE_PLANET_WATER = "/Images/Map/Objects/planet_water.png";
const IMAGE_PLANET_EARTH = "/Images/Map/Objects/planet_earth.png";
const IMAGE_PLANET_DESERT = "/Images/Map/Objects/planet_desert.png";
const IMAGE_PLANET_GAZ = "/Images/Map/Objects/planet_gaz.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ICON_BACKGROUND_OPACITY = 0.75;
const ICON_FONT_SIZE_PC = 100;

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

const SpaceObjectImageView = ({
  spaceObject,
  maxHeight = null,
  maxWidth = null,
  showFleet = true,
  showResources = false,
  showProductionHalo = false,
  showInvasion = false,
  showFactionLogo = true,
  showAsLogo = false,
  showMapIcon = false,
  showBonus = true,
  showBuildIcon = false,
}) => {
  if (!spaceObject) {
    throw new Error("SpaceObjectView : Space object data is null");
  }

  const playerData = StaticGameData.getPlayerData();

  const PlanetView = ({ planet }) => {
    //console.log("planet : ", planet);
    const percentSize = 40 + planet.size * 5;
    //console.log("planet.type : ", planet.type);
    let imgUrl = "";
    if (planet.type === "Volcanic") {
      imgUrl = IMAGE_PLANET_VOLCANIC;
    } else if (planet.type === "Ice") {
      imgUrl = IMAGE_PLANET_ICE;
    } else if (planet.type === "Oceanic") {
      imgUrl = IMAGE_PLANET_WATER;
    } else if (planet.type === "Terran") {
      imgUrl = IMAGE_PLANET_EARTH;
    } else if (planet.type === "Desert") {
      imgUrl = IMAGE_PLANET_DESERT;
    } else if (planet.type === "Gaz") {
      imgUrl = IMAGE_PLANET_GAZ;
    }

    const containerStyle = {
      overflow: "hidden",
    };

    const imageStyle = {
      //If maxWidth defined, use it here, otherwise use 100%
      maxWidth: maxWidth ? maxWidth : "100%",
      maxHeight: maxHeight ? maxHeight : "100%",
      height: "auto",
      display: "block",
    };

    return (
      <div className={"div-fill"} style={containerStyle}>
        <img
          style={imageStyle}
          src={imgUrl} // Replace with the actual image URL
          alt="IMAGE_PLANET"
        />
      </div>
    );
  };

  //const DisplayPlanet = React.memo(
  const DisplayPlanet = ({ spaceObject }) => {
    //console.log("DisplayPlanet : ", spaceObject);

    const DisplayBonus = ({ planet }) => {
      if (planet.bonus.length <= 0) return null;
      return (
        <div
          //className="div-fill"
          style={{
            position: "absolute",
            top: "90%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            whiteSpace: "nowrap",
            //height: "2em",

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",

            fontSize: "1em",
            backgroundColor: Color.COLOR_MEDIUM_SHADOW,
            borderRadius: "0.2em",
            //padding: "0.2em",
          }}
        >
          {planet.bonus.map((bonus, index) => {
            return (
              <div key={index}>
                <PlanetBonusView bonusName={bonus} size="1em"></PlanetBonusView>
              </div>
            );
          })}
        </div>
      );
    };

    const DisplayResources = ({ planet }) => {
      return (
        <div
          style={{
            position: "absolute",
            top: "90%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            whiteSpace: "nowrap",
            ...Style.STYLE_MAP_TEXT_BOX,
          }}
        >
          {false && planet.mineral > 0 && (
            <span>
              <Logo logoName="mineral"></Logo>
              <span>{planet.mineral} </span>
            </span>
          )}
          {false && planet.population > 0 && (
            <span>
              <Logo logoName="population"></Logo>
              <span>{planet.population} </span>
            </span>
          )}
        </div>
      );
    };

    const DisplayInfoText = () => {
      const planet = spaceObject;
      if (
        playerData.faction &&
        Planet.isFactionInvading(planet, playerData.faction.name)
      ) {
        return (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "red",
              backgroundColor: Color.COLOR_LOWLIGHT,
              textAlign: "center",
              width: "auto",
              fontSize: "0.6em",
              whiteSpace: "nowrap",
            }}
          >
            Will be invaded
          </div>
        );
      } else if (playerData.colonists > 0) {
        const homeSystem = PlayerData.getHomeSystemOfFaction(
          playerData,
          playerData.faction.name
        );
        if (Map.isPlanetColonizable(playerData, homeSystem, planet)) {
          return (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: Color.COLOR_GREEN_THEME_LIGHT,
                backgroundColor: Color.COLOR_LOWLIGHT,
                textAlign: "center",
                width: "auto",
                fontSize: "0.6em",
                whiteSpace: "nowrap",
              }}
            >
              Colonize !
            </div>
          );
        }
      }
    };

    const DisplayProductionHalo = ({ planet }) => {
      if (
        !showProductionHalo ||
        planet.hasProduced ||
        !playerData.faction ||
        planet.faction !== playerData.faction.name
      )
        return null;

      return (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            backgroundColor: "gold" /* Choose the color you want */,
            borderRadius: "50%",
            zIndex: -10,
            /* Set the border-radius to 50% to make a circle */
            //backgroundColor: "rgba(255, 255, 255, 0.5)",
            //border: "1px solid black",
            //borderRadius: "50%",
            //zIndex: 100,
          }}
        >
          {" "}
        </div>
      );
    };

    const DisplayUnits = ({ planet }) => {
      const allFleets = Planet.getFleets(planet);
      const slots = Fleet.UnitDisplaySlots();
      slots.addEmptySlot(Unit.UNIT_TYPE_INFANTRY, 20, 20);
      slots.addEmptySlot(Unit.UNIT_TYPE_MECH, 50, 20);
      /*slots.addEmptySlot(Unit.UNIT_TYPE_ASSEMBLY_ORBITAL, 5, 60);
      slots.addEmptySlot(Unit.UNIT_TYPE_FACTORY, 35, 60);
      slots.addEmptySlot(Unit.UNIT_TYPE_GENERATOR, 65, 60);
      slots.addEmptySlot(Unit.UNIT_TYPE_SUPPLY_BASE, 95, 60);*/

      for (let i = 0; i < allFleets.length; i++) {
        slots.addFleet(allFleets[i]);
      }

      slots.splitStack();

      //creating an array with the structures
      const structureSlots = [];
      structureSlots.push({ x: 5, y: 60 });
      structureSlots.push({ x: 35, y: 60 });
      structureSlots.push({ x: 65, y: 60 });
      structureSlots.push({ x: 95, y: 60 });
      let structures = [];
      for (let i = 0; i < allFleets.length; i++) {
        structures = structures.concat(
          Fleet.getUnits(allFleets[i], Fleet.UNIT_CLASS_STRUCTURE)
        );
      }

      return (
        <div>
          <div>
            {slots.getSlots.map((slot, index) => {
              //const scaleUnit = 0.15;
              if (slot.units.length <= 0) return;
              const scaleUnit = 0.3;
              //PAY ATTENTION : in the UnitsStackView : the scale is adapted for structures to make it smaller.
              return (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    left: slot.x + "%",
                    top: slot.y + "%",
                    zIndex: 100,
                    //backgroundColor: Color.COLOR_LIGHT_SHADOW,
                    //transform: `translate(-0%, -${100 - 40 + 20}%)`,
                  }}
                >
                  <UnitsStackView
                    slot={slot}
                    scale={scaleUnit}
                  ></UnitsStackView>
                </div>
              );
            })}
          </div>
          <div>
            {structures.map((structure, index) => {
              const scaleUnit = 0.25;
              return (
                <div
                  key={index}
                  style={{
                    zIndex: 2000,
                    position: "absolute",
                    left: structureSlots[index].x,
                    top: structureSlots[index].y,

                    //transform: "translate(-50%, -50%)",
                    //backgroundColor: "purple",
                    //width: "auto" /* Automatically adjusts width based on content */,
                    //height: "auto",
                    transform: `translate(-50%, -50%)`,
                    display: "flex",
                    //justifyContent: "center",
                    //alignItems: "flex-end",
                  }}
                >
                  <UnitImageView
                    unit={structure}
                    scale={scaleUnit}
                    //labelBelow={units[0].type}
                    showLifeBar={false}
                  ></UnitImageView>{" "}
                </div>
              );
            })}{" "}
          </div>
        </div>
      );
    };

    const hasSomeUnits = Planet.hasSomeUnits(spaceObject);
    console.log("showProductionHalo", showProductionHalo);

    return (
      <div>
        {showProductionHalo && !showAsLogo && (
          <DisplayProductionHalo planet={spaceObject}></DisplayProductionHalo>
        )}
        <PlanetView planet={spaceObject}></PlanetView>
        {hasSomeUnits && !showAsLogo && showFleet && (
          <DisplayUnits planet={spaceObject}></DisplayUnits>
        )}

        {showResources && !showAsLogo && (
          <DisplayResources planet={spaceObject}></DisplayResources>
        )}
        {showBonus && !showAsLogo && (
          <DisplayBonus planet={spaceObject}></DisplayBonus>
        )}
        {!hasSomeUnits &&
          !showAsLogo &&
          spaceObject.faction &&
          showFactionLogo && (
            <div
              className="div-fill"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                //backgroundColor: "rgba(100, 100, 100, 0.5)",
                //display: "flex",
                //justifyContent: "center" /* Horizontally center */,
                //alignItems: "center" /* Vertically center */,
              }}
            >
              <div
                className="div-fill"
                style={{
                  width: "50%",
                  height: "50%",
                  //backgroundColor: "rgba(200, 255, 200, 0.5)",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={Faction.getImageUrlFromFaction(spaceObject.faction)}
                  alt={"LOGO_" + "test"}
                />
              </div>
            </div>
          )}
        {showInvasion && !showAsLogo && <DisplayInfoText></DisplayInfoText>}
      </div>
    );
  }; /*,
    (prevProps, nextProps) =>
      prevProps.spaceObject.faction === nextProps.spaceObject.faction
  );*/

  //console.log("SpaceObjectView RENDER : ", spaceObject);
  //console.log("SpaceObjectView RENDER .faction : ", spaceObject.faction);

  const DisplayBuildIcon = () => {
    if (
      (!showBuildIcon &&
        Popup.getMapDisplayMode() !== Popup.MAP_DISPLAY_BUILD) ||
      !playerData.faction ||
      spaceObject.faction !== playerData.faction.name ||
      spaceObject.hasBuiltUnits === true ||
      !showMapIcon
    ) {
      return;
    }
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "0%",
          transform: "translate(-50%, -50%)",
          //backgroundColor: Color.COLOR_LOWLIGHT,
          width: "auto",
          fontSize: "3em",
        }}
      >
        <img
          style={{ width: "35%", height: "35%" }}
          src={ImagesUrl.URL_BUILD}
          alt={"LOGO_" + "test"}
        />
      </div>
    );
  };
  const DisplayPlanetInfo = () => {
    if (
      Popup.getMapDisplayMode() !== Popup.MAP_DISPLAY_PLANET_INFO ||
      !showMapIcon
    ) {
      return;
    }

    const energy = Planet.getEnergyProduction(playerData, spaceObject);
    const mineral = Planet.getMineralProduction(playerData, spaceObject);
    const science = Planet.getScienceProduction(playerData, spaceObject);
    const population = Planet.getPopulationProduction(playerData, spaceObject);
    const constructionValue = Planet.getConstructionValue(
      playerData,
      spaceObject
    );

    return (
      <div
        style={{
          position: "absolute",
          top: "90%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //backgroundColor: Color.COLOR_LOWLIGHT,
          width: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            padding: "0.5em",
            borderRadius: "0.5em",
            border: "1px solid white",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            fontSize: "0.5em",
          }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <Logo logoName="mineral"></Logo>
            <span> {mineral}</span>
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <Logo logoName="science"></Logo>
            <span> {science}</span>
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <Logo logoName="energy"></Logo>
            <span> {energy}</span>
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <Logo logoName="population"></Logo>
            <span> {population}</span>
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            <Logo logoName="build"></Logo>
            <span> {constructionValue}</span>
          </div>
        </div>
      </div>
    );
  };

  if (spaceObject.isPlanet) {
    return (
      <div style={{ position: "relative" }}>
        <DisplayPlanet spaceObject={spaceObject}></DisplayPlanet>
        <DisplayBuildIcon></DisplayBuildIcon>
        <DisplayPlanetInfo></DisplayPlanetInfo>
      </div>
    );
  }

  return null;
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default SpaceObjectImageView;
