import React, { useState } from "react";
import PropTypes from "prop-types";
import ResourceView from "../../Label/ResourceView.jsx";
import Logo from "../../Utils/Logo.jsx";
import FactionLogo from "../../Utils/FactionLogo.jsx";
import FleetSummaryView from "../../ManageSystem/FleetSummaryView.jsx";
import FleetLineView from "../../Object/FleetLineView.jsx";
import FleetOneLineView from "../../Object/FleetOneLineView.jsx";
import UnitLineView from "../../Units/UnitLineView.jsx";
import ItemView from "../../Transaction/ItemView.jsx";

const LogMessage = require("../../../Data/GameData/Connection/LogMessage.js");

const LogMessageView = ({ logMessage }) => {
  console.log("LogMessageView", logMessage);

  const DisplayUnit = (data) => {
    return <UnitLineView unit={data} size="2em"></UnitLineView>;
  };

  const DisplayTypeResourceLogo = (data) => {
    //console.log("DisplayTypeResourceLogo data: ", data);
    return <Logo logoName={data} heightRem={"1em"}></Logo>;
  };

  const DisplayTypeFactionLogo = (data) => {
    return <FactionLogo factionName={data} heightRem={"1em"}></FactionLogo>;
  };

  const DisplaySystem = (name) => {
    return <span>{name}</span>;
  };

  const DisplaySpaceObject = (name) => {
    return <span>{name}</span>;
  };

  const DisplayFleet = ({ fleet }) => {
    console.log("DisplayFleet fleet: ", fleet);
    return (
      <span style={{ fontSize: "0.75em" }}>
        <FleetOneLineView fleet={fleet}></FleetOneLineView>
      </span>
    );
  };

  const DisplayLogo = ({ logoName }) => {
    return <Logo logoName={logoName}></Logo>;
  };

  const DisplayText = (data) => {
    return <span>{data}</span>;
  };

  const DisplayLineTypeTitleSection = (data) => {
    return <hr></hr>;
  };

  const DisplayLine = (data) => {
    return <hr></hr>;
  };

  console.log("DisplaySpaceObject : ", logMessage);
  return (
    <span>
      {logMessage.type === LogMessage.TYPE_TITLE_SECTION && (
        <DisplayLineTypeTitleSection></DisplayLineTypeTitleSection>
      )}
      {logMessage.type === LogMessage.TYPE_LINE && <DisplayLine></DisplayLine>}
      {logMessage.items.map((logItem, index) => {
        //console.log("logItem", logItem);
        return (
          <span key={index}>
            {logItem.type === LogMessage.ITEM_TYPE_UNIT &&
              DisplayUnit(logItem.content)}
            {logItem.type === LogMessage.ITEM_TYPE_RESOURCE_LOGO &&
              DisplayTypeResourceLogo(logItem.content)}
            {logItem.type === LogMessage.ITEM_TYPE_FACTION_LOGO &&
              DisplayTypeFactionLogo(logItem.content)}
            {logItem.type === LogMessage.ITEM_TYPE_SYSTEM &&
              DisplaySystem(logItem.content)}
            {logItem.type === LogMessage.ITEM_TYPE_TEXT &&
              DisplayText(logItem.content)}
            {logItem.type === LogMessage.ITEM_TYPE_FLEET && (
              <DisplayFleet fleet={logItem.content}></DisplayFleet>
            )}
            {logItem.type === LogMessage.ITEM_TYPE_ITEM && (
              <ItemView item={logItem.content}></ItemView>
            )}
            {logItem.type === LogMessage.ITEM_TYPE_LOGO && (
              <DisplayLogo logoName={logItem.content}></DisplayLogo>
            )}

            {logItem.type === LogMessage.ITEM_TYPE_SPACE_OBJECT &&
              DisplaySpaceObject(logItem.content)}
          </span>
        );
      })}
      {logMessage.type === LogMessage.TYPE_TITLE_SECTION && (
        <DisplayLineTypeTitleSection></DisplayLineTypeTitleSection>
      )}
    </span>
  );
};

export default LogMessageView;
