import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Color from "../../Common/Config/Colors";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

import ChatRoom from "../../Data/ChatData/ChatRoom.js";
import FactionLogo from "../Utils/FactionLogo";
import ChatFactionLogo from "./ChatFactionLogo";
import ChatStaticData from "../../Data/ChatData/ChatStaticData";
import ChatData from "../../Data/ChatData/ChatData";
import useChatData from "./UseChatData";
import BaseButton from "../Utils/BaseButton";
import useScrollPosition from "../Utils/Hooks/UseScrollPosition";

const ChatRequest = require("../../Data/ChatData/ChatRequest");

const ChatRoomInputView = ({
  setChatItems,
  room,
  styles,
  scrollContainerRef,
}) => {
  const [newMessage, setNewMessage] = useState("");

  const playerData = StaticGameData.getPlayerData();
  const playerInGameId = playerData.playerInGameId;
  const popup = StaticGameData.popup;

  const inputRef = useRef(null); // Create a ref for the input field

  /*console.log("popup.shouldFocusInput  : ", popup.shouldFocusInput);
  console.log("popup.shouldFocusInput  inputRef.current : ", inputRef.current);*/
  if (popup.shouldFocusInput && inputRef.current) {
    inputRef.current.focus(); // Focus on the input field
    delete popup.shouldFocusInput; // Reset the flag after focusing
  }

  const handleSubmit = () => {
    //e.preventDefault();
    if (newMessage.trim()) {
      // Assuming there's a function to send the new message
      // sendMessage(newMessage);

      //ChatRoom.test();

      ChatStaticData.addChatItemText(room, newMessage, playerInGameId);
      setChatItems(ChatStaticData.getChatItems(room.id));

      popup.shouldFocusInput = true;

      Popup.touch();

      /*console.log("updateChatItemsState ", updateChatItemsState);
        if (updateChatItemsState) {
          console.log("updateChatItemsState triggered");
          updateChatItemsState(ChatStaticData.getChatItems(roomData.id));
        }*/
      setNewMessage("");
      //Popup.touch();
      //ChatRoom.addChatItemText(roomData, newMessage, playerInGameId);

      // Update the local state for demonstration

      const updateData = {
        playerInGameId: playerInGameId,
        text: newMessage,
      };
      /*Request.updateChatData(
          updateData,
          ChatRequest.ACTION_ADD_CHAT_ITEM,
          false,
          false
        );*/
    }
  };

  return (
    <form
      //ref={inputRef}
      style={styles.messageInput}
      onSubmit={handleSubmit}
    >
      <input
        //type="text"
        ref={inputRef} // Assign the ref to the input field
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
        //inputMode="text"
        style={styles.input}
      />
      <BaseButton onClick={handleSubmit}>Send</BaseButton>
    </form>
  );
};

export default ChatRoomInputView;
