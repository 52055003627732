import React, { useState, useEffect } from "react";
import GameListItem from "./GameListItem";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";

export const GameListView = ({ setRouteMainHandler }) => {
  const [games, setGames] = useState([]);

  const fetchGames = async () => {
    try {
      const response = await fetch("/api/list-games"); // Replace with your API endpoint to get the list of games
      if (response.ok) {
        const result = await response.json();
        setGames(result.listOfGames); // Update the state with the received game data
      } else {
        console.error("Failed to fetch games:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  };

  const handleJoinGame = async () => {
    console.debug("Joining game...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-game", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined game successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join game:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining game:", error);
    }
  };

  const handleJoinTutorial = async () => {
    console.debug("Joining Tutorial...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-tutorial", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined tutorial successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join tutorial:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining tutorial:", error);
    }
  };

  useEffect(() => {
    // Fetch the list of games from the server and update the state

    fetchGames();
  }, []); // Run this effect only once when the component mounts

  console.log("list of games : ", games);

  return (
    <div>
      <h1>Game List</h1>
      <div>
        {/* Button to create a new game */}
        <button onClick={handleJoinTutorial} style={{ marginRight: "2em" }}>
          Join Tutorial
        </button>
        <button onClick={handleJoinGame}>Join Game</button>
      </div>
      <div>
        {/* Render the list of games */}
        {games.map((game) => {
          return (
            <GameListItem
              key={game.gameId}
              game={game}
              setRouteMainHandler={setRouteMainHandler}
            />
          );
        })}
      </div>
    </div>
  );
};
