import React, { useState } from "react";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import ValueView from "../Utils/ValueView";
import UnitDescription from "./UnitDescription";
import UnitLineView from "./UnitLineView";
import UnitImageView from "../Object/UnitImageView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const Color = require("../../Common/Config/Colors");

const LifeBarView = ({ hp, maxHp, height = "0.1em" }) => {
  // Calculate the width percentage of the life bar
  const widthPercentage = (hp / maxHp) * 100;

  if (hp === maxHp) {
    return null;
  }

  const styles = {
    lifeBarContainer: {
      width: "80%",
      height: height,
      backgroundColor: "red" /* Background color for the max HP */,
      border: "1px solid #000" /* Optional: border for the life bar */,
      borderRadius: "5px" /* Optional: rounded corners */,
      overflow: "hidden" /* Ensure the inner bar stays within the container */,
    },
    lifeBar: {
      height: "100%",
      //backgroundColor: "#4caf50" /* Color for the current HP */,
      backgroundColor: "#4caf50" /* Color for the current HP */,
    },
  };

  return (
    <div style={styles.lifeBarContainer}>
      <div style={{ ...styles.lifeBar, width: `${widthPercentage}%` }} />
    </div>
  );
};

export default LifeBarView;
