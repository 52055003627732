const Map = require("../MapData/Map");
const SpaceObject = require("../MapData/SpaceObject");
const Color = require("../../../Common/Config/Colors");
const Phase = require("../Game/Phase");
const Planet = require("../MapData/Planet");
const Unit = require("../MapData/Unit");
const Cost = require("../Utils/Cost");
const LogBook = require("../Connection/LogBook");
const System = require("../MapData/System");
const LogAttachment = require("../Connection/LogAttachment");
const CustomMath = require("../../../Common/Math/CustomMath");
const LogMessage = require("../Connection/LogMessage");
const UIMessage = require("../Connection/UIMessage");
const Rules = require("../../../Data/GameData/Game/Rules");
const PlayerData = require("../PlayerData/PlayerData");
const Faction = require("../PlayerData/Faction");

class ResolveData {
  static create(gamestateList, globalMap) {
    const data = {
      map: globalMap,
      gamestateList: gamestateList,
    };

    return data;
  }

  static getNeutralPlayerData(resolveData, factionName) {
    const neutralPlayerData = PlayerData.getNeutralPlayerData(factionName);
    const gamestateList = resolveData.gamestateList;
    for (let i = 0; i < gamestateList.length; i++) {
      PlayerData.createOtherPlayer(
        neutralPlayerData,
        gamestateList[i].playerData
      );
    }

    neutralPlayerData.map = resolveData.map;

    return neutralPlayerData;
  }

  //Get
  static getPlayerDataFromFaction(resolveData, factionName) {
    for (let i = 0; i < resolveData.gamestateList.length; i++) {
      const playerData = resolveData.gamestateList[i].playerData;
      if (playerData.faction.name === factionName) {
        return playerData;
      }
    }
    if (Faction.isMinorFaction(factionName)) {
      const neutralPlayerData = this.getNeutralPlayerData(
        resolveData,
        factionName
      );
      return neutralPlayerData;
    }

    throw new Error("PlayerData not found for faction : " + factionName);
  }

  static getAnyPlayerData(resolveData) {
    return resolveData.gamestateList[0].playerData;
  }

  static getAnyGameState(resolveData) {
    return resolveData.gamestateList[0];
  }

  static getGameId(resolveData) {
    return resolveData.gamestateList[0].gameId;
  }

  static getAllPlayerData(resolveData) {
    const playerDataList = [];
    for (let i = 0; i < resolveData.gamestateList.length; i++) {
      playerDataList.push(resolveData.gamestateList[i].playerData);
    }
    return playerDataList;
  }
}

module.exports = ResolveData;
