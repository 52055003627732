const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const UIMessage = require("../../Data/GameData/Connection/UIMessage");

class Request {
  static afterGameStateChanged() {}

  static async updateGameState(updateData, updateTransactions = false) {
    console.log("updateGameState", updateData);
    Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        updateData: updateData,
        clientPhase: StaticGameData.getPlayerData().phase,
        clientStep: StaticGameData.getPlayerData().step,
      };
      const response = await fetch("/api/update-game-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.errorMessage) {
          console.log("Error message : ", result.errorMessage);
          UIMessage.setMessageToDisplay(
            StaticGameData.getPlayerData(),
            result.errorMessage
          );
        } else {
          if (updateTransactions) {
            await StaticGameData.updateTransactions();
          }
          StaticGameData.setGameState(result);
        }

        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  /*static async getPlayerData() {
    const gameState = await this.getGameState();
    return gameState.playerData;
  }*/

  static async nextRound() {
    const reqData = {
      gameId: StaticGameData.gameState.gameId,
    };
    Popup.setClientWaitingServer(true);
    try {
      const response = await fetch("/api/next-round", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const gameStateData = await response.json();
        StaticGameData.setGameState(gameStateData);
        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async getGameState() {
    console.log("VWA DEBUG ********** getGameState");
    try {
      // Fetch the gameState from the Node.js server
      // Game state is the combination of player data and hidden data
      // Hidden data is always null in the client

      Popup.setClientWaitingServer(true);

      const response = await fetch("/api/get-game-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const gameState = await response.json();

      // Update the component state with the fetched gameState
      //console.log("gameState : ", gameState);
      StaticGameData.setGameState(gameState);

      /*console.log(
        "Faction draft system",
        Map.getSystemFromCoords(gameState.playerData.map, 1, 1)
      );*/

      Popup.setClientWaitingServer(false);

      console.log("*******************Popup.touch()");
      return gameState;
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error fetching gameState:", error);
    }
  }

  static async triggerNextRound() {
    const reqData = {
      gameId: StaticGameData.gameState.gameId,
    };
    Popup.setClientWaitingServer(true);

    try {
      const response = await fetch("/api/next-round", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });
      const result = await response.json();
      StaticGameData.setGameState(result);
      Popup.touch();
      // Update the component state with the fetched gameState
      //setGameState(gameStateData);
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error triggering next round:", error);
    }
  }

  static async updateAdmin(reqSubType) {
    console.log("VWA DEBUG ********** updateAdmin");
    Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        reqSubType: reqSubType,
      };
      const response = await fetch("/api/update-admin-state", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const gameStateData = await response.json();
        StaticGameData.setGameState(gameStateData);
        Popup.touch();
      } else {
        return response.json();
      }
      Popup.setClientWaitingServer(false);
    } catch (error) {
      Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async getChatData() {
    console.log("getChatData");
    try {
      const response = await fetch("/api/get-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const chatData = await response.json();
      return chatData;
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  }

  static async updateChatData() {}

  static async getChatRoomData(playerInGameId) {
    try {
      const response = await fetch("/api/get-chat-room", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            gameId: StaticGameData.gameState.gameId,
            playerInGameId: playerInGameId,
          },
        }),
      });

      const room = await response.json();
      return room;
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  }

  static async updateChatData(
    updateData,
    action,
    returnChatData = true,
    loading = true
  ) {
    console.log("updateGameState", updateData);
    if (loading) Popup.setClientWaitingServer(true);

    try {
      const reqData = {
        gameId: StaticGameData.gameState.gameId,
        updateData: updateData,
        returnChatData: returnChatData,
        action: action,
      };
      const response = await fetch("/api/update-chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reqData: reqData,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.errorMessage) {
          console.log("Error message : ", result.errorMessage);
          UIMessage.setMessageToDisplay(
            StaticGameData.getPlayerData(),
            result.errorMessage
          );
        }

        //Popup.touch();
      } else {
        return response.json();
      }
      if (loading) Popup.setClientWaitingServer(false);
    } catch (error) {
      if (loading) Popup.setClientWaitingServer(false);

      console.error("Error :", error);
    }
  }

  static async getChatItems(roomId, startIndex, endIndex = null) {
    console.log("getChatItems", roomId, startIndex, endIndex);
    try {
      const response = await fetch("/api/get-chat-items", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          roomId: roomId,
          startIndex: startIndex,
          endIndex: endIndex,
        }),
      });

      const chatItems = await response.json();
      return chatItems;
    } catch (error) {
      console.error("Error fetching chat items:", error);
    }
  }

  static async insertChatItem(roomId, chatItem) {
    try {
      const response = await fetch("/api/insert-chat-item", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          roomId: roomId,
          chatItem: chatItem,
        }),
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error inserting chat item:", error);
    }
  }

  static async getCombat(combatId) {
    try {
      const response = await fetch("/api/get-combat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
          combatId: combatId,
        }),
      });

      const combatData = await response.json();
      return combatData;
    } catch (error) {
      console.error("Error fetching combat data:", error);
    }
  }

  static async getUpdateRequiredInfo() {
    try {
      const response = await fetch("/api/get-updateRequiredInfo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameId: StaticGameData.gameState.gameId,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching update required info data:", error);
    }
  }
}

module.exports = Request;
