import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ResearchActionData from "../../Data/GameData/ActionData/ResearchActionData";
import FleetLineView from "../Object/FleetLineView";
import LogBookView from "../Utils/LogMessage/LogBookView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");
const Tech = require("../../Data/GameData/Technology/Tech.js");
const LogBook = require("../../Data/GameData/Connection/LogBook");
const Request = require("../../Common/Requests/Request.js");
const CombatRecording = require("../../Data/GameData/Combat/CombatRecording.js");
const Planet = require("../../Data/GameData/MapData/Planet.js");

const CombatView = ({ combatKey }) => {
  const playerData = StaticGameData.getPlayerData();
  console.log("CombatView");

  const [combat, setCombat] = useState(null);
  const [step, setStep] = useState(0);
  //const [system, setSystem] = useState(null);

  const storedDetailed = Popup.getGlobalState(
    "combat_detailed_" + combatKey.id
  );
  const [detailed, setDetailed] = useState(
    storedDetailed ? storedDetailed : false
  );

  if (combat) {
    console.log("combat", combat);
  }

  const fetchCombat = async () => {
    const storedCombat = Popup.getGlobalState("combat_" + combatKey.id);
    if (storedCombat) {
      setCombat(storedCombat);
    } else {
      Popup.setClientWaitingServer(true);
      const combatRequested = await Request.getCombat(combatKey.id);
      setCombat(combatRequested);

      CombatRecording.initialize(combatRequested);
      setStep(CombatRecording.getCurrentStep(combatRequested));
      Popup.addGlobalState("combat_" + combatKey.id, combatRequested);
      Popup.setClientWaitingServer(false);
    }
  };

  useEffect(() => {
    fetchCombat();
  }, []);

  useEffect(() => {
    Popup.addGlobalState("combat_detailed_" + combatKey.id, detailed);
  }, [detailed]);

  /*useEffect(() => {
    if (combat) {
      console.log(
        "updating system step",
        CombatRecording.getCurrentSystem(combat)
      );
      setSystem(CombatRecording.getCurrentSystem(combat));
    }
  }, [step]);*/

  const navigationData = {
    buttonsData: [],
  };

  const DisplayCombat = ({ step }) => {
    navigationData.buttonsData.push(
      {
        text: "<<",
        callback: () => {
          setStep(CombatRecording.getPreviousSection(combat));
          Popup.touch();
        },
      },
      {
        text: "<",
        callback: () => {
          setStep(CombatRecording.getPreviousStep(combat));
          Popup.touch();
        },
      },
      {
        text: "Q",
        callback: () => {
          Popup.goBack();
        },
      },
      {
        text: ">",
        callback: () => {
          setStep(CombatRecording.getNextStep(combat));
          Popup.touch();
        },
      },
      {
        text: ">>",
        callback: () => {
          setStep(CombatRecording.getNextSection(combat));
          Popup.touch();
        },
      }
    );

    return (
      <div>
        <DisplayFleets step={step}></DisplayFleets>
      </div>
    );
  };

  const DisplayFleets = ({ step }) => {
    console.log("get system combat", combat);

    const system = CombatRecording.getCurrentSystem(combat);
    let fleets = [];
    const combatType = CombatRecording.getCombatType(combat);
    if (combatType === CombatRecording.TYPE_GROUND) {
      const planet = System.getPlanetFromName(
        system,
        CombatRecording.getPlanetName(combat)
      );
      fleets = Planet.getFleets(planet);
    } else {
      fleets = System.getFleets(system);
    }

    const filteredFleets = [];

    for (let i = 0; i < fleets.length; i++) {
      if (combatType === CombatRecording.TYPE_GROUND) {
        filteredFleets.push(
          Fleet.excludeClass(fleets[i], Fleet.UNIT_CLASS_SHIP)
        );
      } else {
        filteredFleets.push(
          Fleet.excludeClass(fleets[i], Fleet.UNIT_CLASS_GROUND_FORCE)
        );
      }
    }

    //Fileter to show only units of correct class
    const playerFleet = Fleet.getFleetFromFleets(
      filteredFleets,
      playerData.faction.name
    );
    const otherFleets = filteredFleets.filter(
      (fleet) => fleet.faction !== playerData.faction.name
    );

    return (
      <div>
        <FleetLineView fleet={playerFleet}></FleetLineView>
        {otherFleets.map((fleet, index) => (
          <span key={index}>
            {" "}
            <FleetLineView fleet={fleet}></FleetLineView>
          </span>
        ))}
      </div>
    );
  };

  const displayLog = () => {
    if (!combat || detailed) return null;
    return (
      <div style={{ width: "95%" }}>
        <BaseButton
          width="100%"
          onClick={() => {
            setDetailed(true);
          }}
        >
          <LogBookView
            logBook={CombatRecording.getLogBook(combat)}
          ></LogBookView>
        </BaseButton>
      </div>
    );
  };

  const DisplayDetailedLog = () => {
    navigationData.buttonsData.push({
      text: "Back to combat",
      callback: () => {
        setDetailed(false);
      },
    });
    return (
      <div className="text-box" style={{ backgroundColor: Color.COLOR_GREY_1 }}>
        <LogBookView
          logBook={CombatRecording.getDetailedLogBook(combat)}
        ></LogBookView>
      </div>
    );
  };

  return (
    <GameGlobalLayout displayComponentOnTopNavBar={displayLog}>
      <div>
        {combat && !detailed && <DisplayCombat step={step}></DisplayCombat>}
        {combat && detailed && <DisplayDetailedLog></DisplayDetailedLog>}
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default CombatView;
