const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const System = require("../MapData/System.js");
const Unit = require("../MapData/Unit.js");
const Map = require("../MapData/Map.js");
const Fleet = require("../MapData/Fleet.js");
const LogBook = require("../Connection/LogBook.js");
const LogMessage = require("../Connection/LogMessage.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const Curiosity = require("../MapData/Curiosity.js");

class SystemAction {
  /*static activateSystem(playerData, system) {
    if (system.hasBeenActivated) {
      throw new Error(
        "System has already been activated. A system can only be activated once per round."
      );
    }

    console.log("playerData", playerData);
    if (ActivationData.isASystemActivated(playerData)) {
      throw new Error(
        "System " +
          ActivationData.getActivatedSystem(playerData).name +
          " has already activated. You can't activate 2 systems at once. Finish activation phase of the activated system first."
      );
    }

    ActivationData.activateSystem(playerData, system);
  }

  static endActivation(playerData) {
    ActivationData.endActivation(playerData);
  }*/

  //Move Functions
  static canUnitMove(originSystem, targetSystem, unit, playerData) {
    //If unit needs to be transported, return, she does not move itself.
    const result = {
      log: LogBook.createLogBook(), // for the moment not used because not returned
      allowed: true, // for the moment not used because not returned
      sourceIsNebula: false,
    };

    if (originSystem.backgroundObject === Curiosity.object.nebula.name) {
      LogBook.generateAddMessage(
        result.log,
        "All ships have a range of 1 leaving a nebula."
      );
      result.sourceIsNebula = true;
    }

    if (
      (unit.range === null || unit.range === 0) &
      (Unit.getRequiredCapacity(playerData, unit) > 1)
    ) {
      return;
    }

    //Obsolete new in MoveActionData
    const moveDistance = Map.getMoveDistanceBetweenSystems(
      playerData,
      originSystem,
      targetSystem,
      playerData.map,
      true
    );

    if (!Unit.shouldUnitBeTransported(playerData, unit)) {
      let unitRange = Unit.getRange(playerData, unit);
      if (result.sourceIsNebula) {
        unitRange = 1;
      }
      if (moveDistance > unitRange) {
        let errorMessage =
          Unit.getPrintingName(unit) +
          " can't move to target system. Range of the unit is " +
          unitRange +
          " and move distance is " +
          moveDistance +
          ".";
        if (result.sourceIsNebula) {
          errorMessage += " All ships have a range of 1 leaving a nebula.";
        }
        throw new Error(errorMessage);
      }
    }
  }

  /*static resolveMove(playerData) {
    const moveStep = playerData.activationData.moveStep;
    const systemsFrom = this.getSystemsFromList(playerData);
    const activeSystem = this.getActiveSystem(playerData);

    this.moveUnitsSystemsFrom(playerData, activeSystem, systemsFrom);
  }*/

  static moveUnitsFromToSystem(
    playerData,
    targetSystem,
    fromSystem,
    fleetToMove,
    logBook = null
  ) {
    if (System.areSystemsTheSame(fromSystem, targetSystem)) {
      throw new Error("You can't move units from a system to the same system.");
    }

    if (!Fleet.isEmpty(fleetToMove))
      if (fromSystem.hasBeenActivated) {
        throw new Error(
          "System " +
            fromSystem.name +
            " has already been activated. You can't move units from it."
        );
      }

    const unitsToMove = Fleet.getUnits(fleetToMove);
    for (let i = 0; i < unitsToMove.length; i++) {
      const unit = unitsToMove[i];
      this.canUnitMove(fromSystem, targetSystem, unit, playerData);
    }

    const fleetToMoveCapacity = Fleet.getTotalCapacity(
      PlayerData.getPlayerDataFromFaction(playerData, fleetToMove.faction),
      fleetToMove
    );
    const fleetToMoveRequiredCapacity = Fleet.getTotalRequiredCapacity(
      playerData,
      fleetToMove
    );
    if (fleetToMoveCapacity < fleetToMoveRequiredCapacity) {
      throw new Error(
        "Not enough capacity in the fleet to move all the units. You are trying to move units for a total of " +
          fleetToMoveRequiredCapacity +
          " mass, but the fleet has only " +
          fleetToMoveCapacity +
          " capacity."
      );
    }

    System.removeFleetFromSystem(fromSystem, fleetToMove);
    System.addFleetToSystem(targetSystem, fleetToMove);

    if (!Fleet.isEmpty(fleetToMove)) {
      LogAttachment.logActivity(targetSystem, [
        {
          type: LogMessage.ITEM_TYPE_FACTION_LOGO,
          content: playerData.faction.name,
        },
        {
          content: " Moved fleet ",
        },
        {
          type: LogMessage.ITEM_TYPE_FLEET,
          content: Fleet.getLogItem(fleetToMove),
        },
        {
          content: " from ",
        },
        {
          type: LogMessage.ITEM_TYPE_SYSTEM,
          content: fromSystem,
        },
        {
          content: ".",
        },
      ]);
    }
  }
}

module.exports = SystemAction;
