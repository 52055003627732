import React, { useState, useEffect } from "react";

const DynamicView = ({ displayComponent, popup }) => {
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    setRenderKey((prevKey) => prevKey + 1);
  }, [popup]);

  return (
    <div key={renderKey} style={{ width: "100%", height: "100%" }}>
      {displayComponent()}
    </div>
  );
};

export default DynamicView;
