class StaticGameData {
  static gameState = { gameId: -999 }; // 333 to test
  static popup = null;
  static setPopupReference = null;
  static setGameStateReference = null;
  static serverMode = null;
  static transactionData = null;

  //Transactions update function
  static updateTransactionsFunction = null;

  //User info
  static username = null;

  static setGameState(gameState) {
    StaticGameData.gameState = gameState;
    StaticGameData.setGameStateReference(gameState);
  }

  static getPlayerData() {
    return StaticGameData.gameState.playerData;
  }

  static setPopup(popup) {
    StaticGameData.popup = popup;

    StaticGameData.setPopupReference(popup);
  }

  static setDummyValue(serverMode) {
    StaticGameData.gameState = null;
    StaticGameData.popup = {};
    StaticGameData.setPopupReference = () => {};
    StaticGameData.setGameStateReference = () => {};
    StaticGameData.serverMode = serverMode;
  }

  static setTransactionData(transactionData) {
    StaticGameData.transactionData = transactionData;
  }

  static getTransactionData() {
    return StaticGameData.transactionData;
  }

  static setUpdatesTransactions(updateTransactions) {
    StaticGameData.updateTransactionsFunction = updateTransactions;
  }

  static async updateTransactions() {
    await StaticGameData.updateTransactionsFunction();
  }
}

module.exports = StaticGameData;
