import React, { useState, useEffect } from "react";
import { LoginMainScreen } from "../login/login";
import { MainMenu } from "../MainMenu/MainMenu";
import GameView from "../GameView/GameView";
import TestView from "./TestView";
import StaticGameData from "../Data/GameData/StaticGameData";

const MainComponent = () => {
  const screenSize = [window.innerWidth, window.innerHeight];
  const testView = false;

  // State to keep track of whether the user is logged in or not
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [routeMain, setRouteMain] = useState({
    activeScreen: "MainMenu",
  });

  const setRouteMainHandler = (routeMain) => {
    //console.log("Setting routeMain to:", routeMain);
    setRouteMain(routeMain);
  };

  useEffect(() => {
    // Function to check the token's validity on the server
    const checkTokenValidity = async () => {
      try {
        // Make an API request to check the token's validity
        const response = await fetch("/api/check-token-validity", {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          // Token is valid, user is logged in
          console.log("Token is valid");
          setIsUserLogged(true);
          StaticGameData.username = result.username;
        } else {
          // Token is invalid or not present, user is not logged in
          console.log("Token is invalid");
          setIsUserLogged(false);
        }
      } catch (error) {
        console.error("Error occurred during API request:", error);
      }
    };

    // Call the function to check token validity on component mount
    checkTokenValidity();
  }, []);

  // Function to handle user login
  const handleLogin = () => {
    // Implement your login logic here
    // For example, you could call an authentication API endpoint
    // and upon successful login, set isUserLogged to true
    setIsUserLogged(true);
  };

  // Function to handle user logout
  const handleLogout = () => {
    // Implement logout logic here
    // For example, clear authentication tokens or user data
    setIsUserLogged(false);
  };

  if (testView && isUserLogged) {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <TestView></TestView>
      </div>
    );
  }

  return (
    <div>
      {isUserLogged ? (
        <div>
          {routeMain.activeScreen === "MainMenu" && (
            <MainMenu setRouteMainHandler={setRouteMainHandler} />
          )}
          {routeMain.activeScreen === "GameView" && (
            <GameView
              setRouteMainHandler={setRouteMainHandler}
              game={routeMain.game}
              screenSize={screenSize}
            />
          )}
        </div>
      ) : (
        <div>
          <LoginMainScreen setIsUserLogged={setIsUserLogged}></LoginMainScreen>
        </div>
      )}
    </div>
  );
};

export default MainComponent;
