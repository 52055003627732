import React, { useState } from "react";

import BaseButton from "../Utils/BaseButton";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import UnitImageView from "../Object/UnitImageView";
import LifeBarView from "./LifeBarView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const Color = require("../../Common/Config/Colors");
const Cost = require("../../Data/GameData/Utils/Cost");

const UnitLineView = ({
  unit,
  factionName,
  hpOnBottom = false,
  showHP = true,
  showName = false,
  showRequirements = false,
  showCost = false,
  onClick = null,
  size = null,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const style = {
    //display: "grid",
    //gridTemplateColumns: "1fr auto",
    gap: "1em",
    justifyContent: "center",
    alignItems: "center",
  };

  if (hpOnBottom) {
    style.gridTemplateColumns = "1fr";
  }

  //console.log("UnitLineView style", style);

  const DisplayRequirements = () => {
    if (!unit.requiredStructures) return null;
    const requiredStructures = unit.requiredStructures;
    if (requiredStructures.length === 0) return null;
    return (
      <span>
        Required :
        {requiredStructures.map((structure, index) => (
          <span key={structure}>
            {structure}
            {index !== requiredStructures.length - 1 && ", "}
          </span>
        ))}
      </span>
    );
  };

  const DisplayCost = () => {
    const cost = Unit.getCost(playerData, unit);

    if (Cost.isEmpty(cost)) return null;
    return <CostView compactView={true} cost={cost}></CostView>;
  };

  if (unit.class === Unit.CLASS_STRUCTURE) {
    showHP = false;
  }

  return (
    <BaseButton
      type="fleet"
      onClick={
        onClick
          ? onClick
          : () => {
              Popup.addLayer({
                name: "UnitDetailedView",
                unit: unit,
                factionName: factionName,
              });
            }
      }
      /*textColor={Color.getColorFromPlayerColor(
        PlayerData.getPlayerDataFromFaction(playerData, factionName).color
      )}*/
      textColor="white"
    >
      <div style={style}>
        {showName && <div>{Unit.getPrintingName(unit)}</div>}
        <div>
          {false && (
            <div>
              <FactionLogo factionName={factionName}></FactionLogo>
              <span> </span>
              {Unit.getPrintingName(unit)}
            </div>
          )}
          <span style={{ fontSize: size ? size : "3em" }}>
            {" "}
            <UnitImageView showLifeBar={showHP} unit={unit}></UnitImageView>
          </span>
        </div>
        {showHP && false && (
          <div>
            <LifeBarView
              hp={Unit.getHP(playerData, unit)}
              maxHp={Unit.getMaxHP(playerData, unit)}
            ></LifeBarView>
          </div>
        )}
      </div>
      {showCost && (
        <div>
          <DisplayCost />
        </div>
      )}
      {showRequirements && (
        <div>
          <DisplayRequirements />
        </div>
      )}
    </BaseButton>
  );
};

export default UnitLineView;
