import React, { useState } from "react";
import FactionLogo from "../Utils/FactionLogo.jsx";
import UnitImageView from "./UnitImageView.jsx";
import Popup from "../../Data/Other/Popup.js";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const FleetLineView = ({
  fleet,
  classToDisplay = Fleet.UNIT_CLASS_ALL,
  showFactionLogo = true,
  size = "3em",
  onClick = null,
}) => {
  const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
  const groundForces = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE);
  const structures = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE);

  const DisplayUnits = ({ units }) => {
    if (units.length === 0) {
      return;
    }
    return (
      <span>
        {showFactionLogo && (
          <span>
            {" "}
            <FactionLogo factionName={fleet.faction}></FactionLogo>
            <span> </span>
          </span>
        )}
        {units.map((unit, index) => {
          return (
            <span key={index}>
              <UnitImageView unit={unit}></UnitImageView>
            </span>
          );
        })}{" "}
      </span>
    );
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      Popup.addLayer({ name: "FleetDetailedView", fleet: fleet });
    }
  };

  return (
    <span onClick={handleClick} style={{ fontSize: size }}>
      {(classToDisplay === Fleet.UNIT_CLASS_ALL ||
        classToDisplay === Fleet.UNIT_CLASS_SHIP) && (
        <div>
          <DisplayUnits units={ships}></DisplayUnits>
        </div>
      )}
      {(classToDisplay === Fleet.UNIT_CLASS_ALL ||
        classToDisplay === Fleet.UNIT_CLASS_GROUND_FORCE) && (
        <div>
          <DisplayUnits units={groundForces}></DisplayUnits>
        </div>
      )}
      {(classToDisplay === Fleet.UNIT_CLASS_ALL ||
        classToDisplay === Fleet.UNIT_CLASS_STRUCTURE) && (
        <div>
          <DisplayUnits units={structures}></DisplayUnits>
        </div>
      )}
    </span>
  );
};

export default FleetLineView;
