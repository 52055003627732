import React, { useState } from "react";

import FactionLogo from "./FactionLogo";

const IMAGE_PLANET_VOLCANIC = "/Images/Map/Objects/planet_volcanic.png";
const IMAGE_PLANET_ICE = "/Images/Map/Objects/planet_ice.png";
const IMAGE_PLANET_WATER = "/Images/Map/Objects/planet_water.png";
const IMAGE_PLANET_EARTH = "/Images/Map/Objects/planet_earth.png";
const IMAGE_PLANET_DESERT = "/Images/Map/Objects/planet_desert.png";
const IMAGE_PLANET_GAZ = "/Images/Map/Objects/planet_gaz.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const PlanetLogo = ({ planet, centerYToText = true }) => {
  const getImageUrl = (planet) => {
    let imgUrl = "";
    if (planet.type === "Volcanic") {
      return IMAGE_PLANET_VOLCANIC;
    } else if (planet.type === "Ice") {
      return IMAGE_PLANET_ICE;
    } else if (planet.type === "Oceanic") {
      return IMAGE_PLANET_WATER;
    } else if (planet.type === "Terran") {
      return IMAGE_PLANET_EARTH;
    } else if (planet.type === "Desert") {
      return IMAGE_PLANET_DESERT;
    } else if (planet.type === "Gaz") {
      return IMAGE_PLANET_GAZ;
    }
  };

  //console.log("FactionLogo heightRem: ", heightRem);
  const imageStyle = {
    width: "auto", // Adjust this percentage as needed
    height: "1em", // Conditional height
    //height: "1rem",
  };

  return (
    <span
      style={{
        position: centerYToText ? "relative" : "unset",
        top: centerYToText ? "0.1em" : "unset",
        left: centerYToText ? 0 : "unset",
        position: "relative",
      }}
    >
      {planet.faction && (
        <FactionLogo factionName={planet.faction}></FactionLogo>
      )}
      <img
        style={imageStyle}
        src={getImageUrl(planet)} // Replace with the actual image URL
        alt={"LOGO_" + planet.name}
      />
    </span>
  );
};

export default PlanetLogo;

/*        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)",
          }}
        >
          <FactionLogo factionName={planet.faction}></FactionLogo>
        </div>*/
