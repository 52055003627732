const SpaceObject = require("../MapData/SpaceObject");
const System = require("../MapData/System");
const Color = require("../../../Common/Config/Colors");
const Fleet = require("../MapData/Fleet");
const Unit = require("../MapData/Unit");
const Planet = require("../MapData/Planet");
const ImagesUrl = require("../../Other/ImagesUrl");
const Rules = require("../Game/Rules");
const Item = require("../Transactions/Item");

class Faction {
  static FACTION_NAME_ARBOREC = "The Arborec";
  static FACTION_NAME_LETNEV = "The Barony of Letnev";
  static FACTION_NAME_SAAR = "The Clan of Saar";
  static FACTION_NAME_MUAAT = "The Embers of Muaat";
  static FACTION_NAME_HACAN = "The Emirates of Hacan";
  static FACTION_NAME_SOL = "The Federation of Sol";
  static FACTION_NAME_CREUSS = "The Ghosts of Creuss";
  static FACTION_NAME_L1Z1X = "The L1Z1X Mindnet";
  static FACTION_NAME_MENTAK = "The Mentak Coalition";
  static FACTION_NAME_NAALU = "The Naalu Collective";
  static FACTION_NAME_NEKRO = "The Nekro Virus";
  static FACTION_NAME_SARDAKK = "Sardakk N’orr";
  static FACTION_NAME_JOL = "The Universities of Jol-Nar";
  static FACTION_NAME_WINNU = "The Winnu";
  static FACTION_NAME_XXCHA = "The Xxcha Kingdom";
  static FACTION_NAME_YIN = "The Yin Brotherhood";
  static FACTION_NAME_YSSARIL = "The Yssaril Tribes";
  static FACTION_NAME_ARGENT = "The Argent Flight";
  static FACTION_NAME_EMPYREAN = "The Empyrean";
  static FACTION_NAME_MAHACT = "The Mahact Gene-Sorcerers";
  static FACTION_NAME_NAAZ = "The Naaz-Rokha Alliance";
  static FACTION_NAME_NOMAD = "The Nomad";
  static FACTION_NAME_TITANS = "The Titans of Ul";
  static FACTION_NAME_CABAL = "The Vuil'Raith Cabal";

  //MinorFactions
  static MINOR_FACTION_NAME_KOHOR = "(m) Kohor, the merchants";
  static MINOR_FACTION_NAME_BOROUl = "(m) Boroul, the pirates";
  static NAME_E_AETHERION = "Exiled Aetherion";
  static NAME_E_ANDAR = "Exiled Andar";
  static NAME_E_BOREALIS = "Exiled Borealis";
  static NAME_E_CALVARIA = "Exiled Calvaria";
  static NAME_E_DELPHAS = "Exiled Elphas";
  static NAME_E_DRAKOR = "Exiled Drakor";
  static NAME_E_ERINYS = "Exiled Erinys";
  static NAME_E_EROSIUM = "Exiled Erosium";
  static NAME_E_FELTOR = "Exiled Feltor";
  static NAME_E_GHELOR = "Exiled Ghelor";
  static NAME_E_HELKARAN = "Exiled Helkaran";
  static NAME_E_ICANTA = "Exiled Icanta";
  static NAME_E_JELORIA = "Exiled Jeloria";
  static NAME_E_KORATH = "Exiled Korath";
  static NAME_E_KOBAMDA = "Exiled Quobamba";
  static NAME_E_LARAN = "Exiled Laran";
  static NAME_E_MALTRION = "Exiled Maltrion";
  static NAME_E_MINARI = "Exiled Minari";
  static NAME_E_OPHARIAN = "Exiled Opharian";
  static NAME_E_PROTHIAN = "Exiled Prothian";
  static NAME_E_QUORIDIOM = "Exiled Quoridiom";
  static NAME_E_RYNZORATH = "Exiled Rynzorath";
  static NAME_E_SELTAAR = "Exiled Seltaar";
  static NAME_E_TERRAN = "Exiled Terran";
  static NAME_E_TAARKIR = "Exiled Taarkir";
  static NAME_E_UTARION = "Exiled Utarion";
  static NAME_E_VOIDBORN = "Exiled Voidborn";
  static NAME_E_WOLTHAAR = "Exiled Wolthaar";
  static NAME_E_XELPHAR = "Exiled Xelphar";
  static NAME_E_XENOS = "Exiled Xenos";
  static NAME_E_ZYLORIAN = "Exiled Zylorian";

  static factions = [
    {
      name: Faction.FACTION_NAME_ARBOREC,
      color1: Color.COLOR_PLAYER_GREEN,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_LETNEV,
      color1: Color.COLOR_PLAYER_RED,
      color2: Color.COLOR_PLAYER_GREY,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_SAAR,
      color1: Color.COLOR_PLAYER_YELLOW,
      color2: Color.COLOR_PLAYER_ORANGE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_MUAAT,
      color1: Color.COLOR_PLAYER_RED,
      color2: Color.COLOR_PLAYER_ORANGE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_HACAN,
      color1: Color.COLOR_PLAYER_YELLOW,
      color2: Color.COLOR_PLAYER_RED,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_SOL,
      color1: Color.COLOR_PLAYER_BLUE,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_CREUSS,
      color1: Color.COLOR_PLAYER_BLUE,
      color2: Color.COLOR_PLAYER_PINK,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_L1Z1X,
      color1: Color.COLOR_PLAYER_RED,
      color2: Color.COLOR_PLAYER_GREY,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_MENTAK,
      color1: Color.COLOR_PLAYER_YELLOW,
      color2: Color.COLOR_PLAYER_ORANGE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_NAALU,
      color1: Color.COLOR_PLAYER_GREEN,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_NEKRO,
      color1: Color.COLOR_PLAYER_RED,
      color2: Color.COLOR_PLAYER_GREY,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_SARDAKK,
      color1: Color.COLOR_PLAYER_RED,
      color2: Color.COLOR_PLAYER_GREY,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_JOL,
      color1: Color.COLOR_PLAYER_BLUE,
      color2: Color.COLOR_PLAYER_PURPLE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_WINNU,
      color1: Color.COLOR_PLAYER_PINK,
      color2: Color.COLOR_PLAYER_PURPLE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_XXCHA,
      color1: Color.COLOR_PLAYER_GREEN,
      color2: Color.COLOR_PLAYER_BLUE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_YIN,
      color1: Color.COLOR_PLAYER_PURPLE,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_YSSARIL,
      color1: Color.COLOR_PLAYER_GREEN,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_ARGENT,
      color1: Color.COLOR_PLAYER_ORANGE,
      color2: Color.COLOR_PLAYER_YELLOW,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_EMPYREAN,
      color1: Color.COLOR_PLAYER_PURPLE,
      color2: Color.COLOR_PLAYER_RED,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_MAHACT,
      color1: Color.COLOR_PLAYER_YELLOW,
      color2: Color.COLOR_PLAYER_PURPLE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_NAAZ,
      color1: Color.COLOR_PLAYER_GREEN,
      color2: Color.COLOR_PLAYER_GREY,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_NOMAD,
      color1: Color.COLOR_PLAYER_BLUE,
      color2: Color.COLOR_PLAYER_PURPLE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_TITANS,
      color1: Color.COLOR_PLAYER_PINK,
      color2: Color.COLOR_PLAYER_PURPLE,
      startingColonists: 2,
    },
    {
      name: Faction.FACTION_NAME_CABAL,
      color2: Color.COLOR_PLAYER_RED,
      color1: Color.COLOR_PLAYER_ORANGE,
      startingColonists: 2,
    },
  ];

  static createFaction(factionName) {
    const faction = Faction.factions.find((faction) => {
      return faction.name === factionName;
    });

    const factionCopy = JSON.parse(JSON.stringify(faction));
    this.enrichFaction(factionCopy);

    if (factionCopy) {
      return factionCopy;
    } else {
      throw new Error("Faction not found");
    }
  }

  static createHomeSystem(playerData, factionName, x, y, previousSystem) {
    let homeSystem;
    switch (factionName) {
      default:
        homeSystem = System.createHomeSystem(
          x,
          y,
          [
            { isPlanet: true, type: "Terran" },
            { isPlanet: false, type: "void" },
          ],
          factionName
        );
        //planet Naalu 1
        let object = System.getSpaceObjects(homeSystem)[0];
        Planet.addPopulation(object, 5);
        const fleetPlanet_1 = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_1,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );

        Planet.addFleetToPlanet(object, fleetPlanet_1);

        //planet Naalu 2
        /*object = System.getSpaceObjects(homeSystem)[1];
        Planet.addPopulation(object, 3);
        const fleetPlanet_2 = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(
          playerData,
          fleetPlanet_2,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );
        Fleet.createUnit(
          playerData,
          fleetPlanet_2,
          Unit.UNIT_TYPE_INFANTRY,
          ""
        );

        Planet.addFleetToPlanet(object, fleetPlanet_2);*/

        //Fleet
        const fleet = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CRUSER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CARRIER, "");
        System.addFleetToSystem(homeSystem, fleet);

      //TEST
      /*const fleetTest = Fleet.createFleet(playerData, factionName);
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_CARRIER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_DREADNOUGH, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_INFANTRY, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        Fleet.createUnit(playerData, fleet, Unit.UNIT_TYPE_FIGHTER, "");
        System.addFleetToSystem(homeSystem, fleetTest);
        playerData.cargo = 20;
        playerData.science = 100;
        playerData.population = 50;
        playerData.energy = 50;
        playerData.mineral = 50;*/

      //Test items of exiled
      /*const items = playerData.items;

        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_CALVARIA_SCROLL),
          playerData.items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_DELPHAS_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_ICANTA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_JELORIA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_KOBAMDA_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_MALTRION_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_QUORIDIOM_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_RYNZORATH_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_SELTAAR_SCROLL),
          items
        );
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_TAARKIR_SCROLL),
          items
        );
        Item.exhaustItem(items[items.length - 1]);
        Item.addItemToArray(
          Item.createArtifact(playerData, Item.NAME_VOIDBORN_SCROLL),
          items
        );*/
    }
    homeSystem.name = factionName;
    System.renameObjectsInSystem(homeSystem);

    //Background Object
    homeSystem.backgroundObject = System.BACK_NONE;

    //Get the connection of the system it is replacing.
    homeSystem.mapConnection = previousSystem.mapConnection;

    //Initialize Relics and Artifacts
    const items = playerData.items;
    Item.addItemToArray(
      Item.createRelic(playerData, Item.NAME_TRADE, playerData.faction.name, {
        value: 1,
      }),
      items
    );
    Item.addItemToArray(
      Item.createRelic(
        playerData,
        Item.NAME_PEACE_SPACE,
        playerData.faction.name
      ),
      items
    );
    Item.addItemToArray(
      Item.createRelic(
        playerData,
        Item.NAME_PEACE_GROUND,
        playerData.faction.name
      ),
      items
    );

    return homeSystem;
  }

  static enrichFaction(faction) {
    if (!faction.startingCargo) faction.startingCargo = 3;
  }

  static createRandomFactions(amountFaction) {
    const factions = Faction.factions.slice();
    const randomFactions = [];
    for (let i = 0; i < amountFaction; i++) {
      const randomIndex = Math.floor(Math.random() * factions.length);
      const factionCopy = JSON.parse(JSON.stringify(factions[randomIndex]));
      this.enrichFaction(factionCopy);
      randomFactions.push(factionCopy);
      factions.splice(randomIndex, 1);
    }
    return randomFactions;
  }

  static getEnergyProduction(faction) {
    if (faction.energyProduction) {
      return faction.energyProduction;
    } else {
      return Rules.ENERGY_PRODUCTION;
    }
  }

  static getImagePopulationFromFaction(factionName) {
    return ImagesUrl.URL_POPULATION;
  }

  static getImageUrlFromFaction(factionName) {
    switch (factionName) {
      case Faction.FACTION_NAME_ARBOREC:
        return ImagesUrl.URL_FACTION_ARBOREC;
      case Faction.FACTION_NAME_LETNEV:
        return ImagesUrl.URL_FACTION_LETNEV;
      case Faction.FACTION_NAME_SAAR:
        return ImagesUrl.URL_FACTION_SAAR;
      case Faction.FACTION_NAME_MUAAT:
        return ImagesUrl.URL_FACTION_MUAAT;
      case Faction.FACTION_NAME_HACAN:
        return ImagesUrl.URL_FACTION_HACAN;
      case Faction.FACTION_NAME_SOL:
        return ImagesUrl.URL_FACTION_SOL;
      case Faction.FACTION_NAME_CREUSS:
        return ImagesUrl.URL_FACTION_CREUSS;
      case Faction.FACTION_NAME_L1Z1X:
        return ImagesUrl.URL_FACTION_L1Z1X;
      case Faction.FACTION_NAME_MENTAK:
        return ImagesUrl.URL_FACTION_MENTAK;
      case Faction.FACTION_NAME_NAALU:
        return ImagesUrl.URL_FACTION_NAALU;
      case Faction.FACTION_NAME_NEKRO:
        return ImagesUrl.URL_FACTION_NEKRO;
      case Faction.FACTION_NAME_SARDAKK:
        return ImagesUrl.URL_FACTION_SARDAKK;
      case Faction.FACTION_NAME_JOL:
        return ImagesUrl.URL_FACTION_JOL;
      case Faction.FACTION_NAME_WINNU:
        return ImagesUrl.URL_FACTION_WINNU;
      case Faction.FACTION_NAME_XXCHA:
        return ImagesUrl.URL_FACTION_XXCHA;
      case Faction.FACTION_NAME_YIN:
        return ImagesUrl.URL_FACTION_YIN;
      case Faction.FACTION_NAME_YSSARIL:
        return ImagesUrl.URL_FACTION_YSSARIL;
      case Faction.FACTION_NAME_ARGENT:
        return ImagesUrl.URL_FACTION_ARGENT;
      case Faction.FACTION_NAME_EMPYREAN:
        return ImagesUrl.URL_FACTION_EMPYREAN;
      case Faction.FACTION_NAME_MAHACT:
        return ImagesUrl.URL_FACTION_MAHACT;
      case Faction.FACTION_NAME_NAAZ:
        return ImagesUrl.URL_FACTION_NAAZ;
      case Faction.FACTION_NAME_NOMAD:
        return ImagesUrl.URL_FACTION_NOMAD;
      case Faction.FACTION_NAME_TITANS:
        return ImagesUrl.URL_FACTION_TITAN;
      case Faction.FACTION_NAME_CABAL:
        return ImagesUrl.URL_FACTION_CABAL;

      default:
        throw new Error("Faction image not found");
    }
  }

  static isMinorFaction(factionName) {
    return factionName.startsWith("(m)") || factionName.startsWith("Exiled");
  }

  static getMinorFactionImageFileName(name) {
    switch (name) {
      case Faction.NAME_E_AETHERION:
        return ImagesUrl.URL_AETHERION;
      case Faction.NAME_E_ANDAR:
        return ImagesUrl.URL_ANDAR;
      case Faction.NAME_E_BOREALIS:
        return ImagesUrl.URL_BOREALIS;
      case Faction.NAME_E_CALVARIA:
        return ImagesUrl.URL_CALVARIA;
      case Faction.NAME_E_DELPHAS:
        return ImagesUrl.URL_DELPHAS;
      case Faction.NAME_E_DRAKOR:
        return ImagesUrl.URL_DRAKOR;
      case Faction.NAME_E_ERINYS:
        return ImagesUrl.URL_ERINYS;
      case Faction.NAME_E_EROSIUM:
        return ImagesUrl.URL_EROSIUM;
      case Faction.NAME_E_FELTOR:
        return ImagesUrl.URL_FELTOR;
      case Faction.NAME_E_GHELOR:
        return ImagesUrl.URL_GHELOR;
      case Faction.NAME_E_HELKARAN:
        return ImagesUrl.URL_HELKARAN;
      case Faction.NAME_E_ICANTA:
        return ImagesUrl.URL_ICANTA;
      case Faction.NAME_E_JELORIA:
        return ImagesUrl.URL_JELORIA;
      case Faction.NAME_E_KORATH:
        return ImagesUrl.URL_KORATH;
      case Faction.NAME_E_KOBAMDA:
        return ImagesUrl.URL_KOBAMDA;
      case Faction.NAME_E_LARAN:
        return ImagesUrl.URL_LARAN;
      case Faction.NAME_E_MALTRION:
        return ImagesUrl.URL_MALTRION;
      case Faction.NAME_E_MINARI:
        return ImagesUrl.URL_MINARI;
      case Faction.NAME_E_OPHARIAN:
        return ImagesUrl.URL_OPHARIAN;
      case Faction.NAME_E_PROTHIAN:
        return ImagesUrl.URL_PROTHIAN;
      case Faction.NAME_E_QUORIDIOM:
        return ImagesUrl.URL_QUORIDIOM;
      case Faction.NAME_E_RYNZORATH:
        return ImagesUrl.URL_RYNZORATH;
      case Faction.NAME_E_SELTAAR:
        return ImagesUrl.URL_SELTAAR;
      case Faction.NAME_E_TERRAN:
        return ImagesUrl.URL_TERRAN;
      case Faction.NAME_E_TAARKIR:
        return ImagesUrl.URL_TAARKIR;
      case Faction.NAME_E_UTARION:
        return ImagesUrl.URL_UTARION;
      case Faction.NAME_E_VOIDBORN:
        return ImagesUrl.URL_VOIDBORN;
      case Faction.NAME_E_WOLTHAAR:
        return ImagesUrl.URL_WOLTHAAR;
      case Faction.NAME_E_XELPHAR:
        return ImagesUrl.URL_XELPHAR;
      case Faction.NAME_E_XENOS:
        return ImagesUrl.URL_XENOS;
      case Faction.NAME_E_ZYLORIAN:
        return ImagesUrl.URL_ZYLORIAN;

      default:
        throw new Error("Faction image not found");
    }
  }
}

module.exports = Faction;
