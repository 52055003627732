//const SpaceObject = require("../MapData/SpaceObject");
//const System = require("../MapData/System");
//const Color = require("../../../Common/Config/Colors");
const StaticGameData = require("../StaticGameData");
//const PlayerData = require("../PlayerData/PlayerData");
const Popup = require("../../Other/Popup");

class UIMessage {
  static TYPE_ERROR = "error";
  static TYPE_INFO = "info";
  static TYPE_CONFIRM = "confirm";

  static MESSAGE_FORMAT_TEXT = "text";
  static MESSAGE_FORMAT_LOGBOOK = "logbook";

  static createMessage(name, type = this.TYPE_INFO) {
    //Check if type is valid
    if (
      type !== this.TYPE_ERROR &&
      type !== this.TYPE_INFO &&
      type !== this.TYPE_CONFIRM
    ) {
      throw new Error("Invalid message type");
    }

    return {
      name: name,
      type: type,
      format: this.MESSAGE_FORMAT_TEXT,
      confirmCallback: null,
      lines: [],
    };
  }

  static addLine(message, line) {
    message.lines.push(line);
  }
  static getLines(message) {
    return message.lines;
  }
  static displayErrorMessage(
    title,
    e,
    playerData = StaticGameData.getPlayerData()
  ) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_ERROR);
    this.addLine(message, e.message);
    UIMessage.addMessageToDisplay(playerData, message);

    Popup.touch();
  }

  static displayInfoMessage(
    title,
    messageP,
    playerData = StaticGameData.getPlayerData()
  ) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_INFO);
    this.addLine(message, messageP);
    UIMessage.addMessageToDisplay(playerData, message);
    Popup.touch();
  }

  static sendInfoMessageToClient(playerData, title, messageP) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_INFO);
    this.addLine(message, messageP);
    UIMessage.addMessageToDisplay(playerData, message);
  }

  static displayConfirmMessage(title, messageP, confirmCallback) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_CONFIRM);
    message.confirmCallback = confirmCallback;
    this.addLine(message, messageP);
    const playerData = StaticGameData.getPlayerData();
    UIMessage.addMessageToDisplay(playerData, message);
    Popup.touch();
  }

  static displayConfirmLogBook(logBookP, confirmCallback) {
    const message = {
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBookP,
      confirmCallback: confirmCallback,
      type: this.TYPE_CONFIRM,
    };

    console.log("UIMessage message", message);
    const playerData = StaticGameData.getPlayerData();
    UIMessage.addMessageToDisplay(playerData, message);
    Popup.touch();
  }

  static displayInfoLogBook(logBookP) {
    const message = {
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBookP,
      type: this.TYPE_INFO,
    };
    const playerData = StaticGameData.getPlayerData();
    UIMessage.addMessageToDisplay(playerData, message);
    Popup.touch();
  }

  static sendErrorMessageToClient(gameState, title, e) {
    const message = UIMessage.createMessage(title, UIMessage.TYPE_ERROR);
    this.addLine(message, e.message);
    UIMessage.addMessageToDisplay(gameState.playerData, message);
  }

  static sendErrorLogBookToClient(playerData, title, logBook) {
    const message = {
      title: title,
      format: this.MESSAGE_FORMAT_LOGBOOK,
      logBook: logBook,
      type: this.TYPE_INFO,
    };
    UIMessage.addMessageToDisplay(playerData, message);
  }

  //Managing messages in player Data
  static setMessageToDisplay(playerData, message) {
    playerData.messageToDisplay = [];
    playerData.messageToDisplay.push(message);
  }

  static resetMessageToDisplay(playerData) {
    playerData.messageToDisplay = [];
  }

  static setMessageToDisplayArray(playerData, messageArray) {
    playerData.messageToDisplay = messageArray;
  }

  static isThereAMessageToDisplay(playerData) {
    if (
      playerData.messageToDisplay === null ||
      playerData.messageToDisplay.length === 0
    ) {
      return false;
    }
    return true;
  }

  static getFirstMessageToDisplay(playerData) {
    return playerData.messageToDisplay[0];
  }

  static addMessageToDisplay(playerData, message) {
    if (!playerData.messageToDisplay) {
      playerData.messageToDisplay = [];
    }
    playerData.messageToDisplay.push(message);
  }

  static setSingleMessageToDisplay(playerData, message) {
    this.resetMessageToDisplay(playerData);
    this.addMessageToDisplay(playerData, message);
  }

  static removeMessageToDisplay(playerData) {
    //Remove first message of the array
    playerData.messageToDisplay.shift();
  }
}

module.exports = UIMessage;
