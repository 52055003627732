import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import ResolveItemAbility from "../../Data/GameData/Ability/ResolveItemAbility";
import ResolveMinorFactionAbility from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Map from "../../Data/GameData/MapData/Map";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Faction from "../../Data/GameData/PlayerData/Faction";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Item from "../../Data/GameData/Transactions/Item";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class ItemAbilityInput {
  static routeItemAbilityInput(item) {
    //For testing
    //this.calvaria(spaceObjectName, minorFaction);
    //return;
    const playerData = StaticGameData.playerData;

    const route = () => {
      //this.jeloriaScroll(playerData, item);
      //return;

      switch (item.name) {
        case Item.NAME_JELORIA_SCROLL:
          this.jeloriaScroll(playerData, item);
          break;
        case Item.NAME_KOBAMDA_SCROLL:
          this.kobamdaScroll(playerData, item);
          break;
        case Item.NAME_CALVARIA_SCROLL:
        case Item.NAME_DELPHAS_SCROLL:
        case Item.NAME_ICANTA_SCROLL:
        case Item.NAME_MALTRION_SCROLL:
        case Item.NAME_QUORIDIOM_SCROLL:
        case Item.NAME_RYNZORATH_SCROLL:
        case Item.NAME_SELTAAR_SCROLL:
        case Item.NAME_TAARKIR_SCROLL:
          this.exhaustItem(playerData, item);
          break;

        default:
          throw new Error("Item name not found for ability input");
      }
    };

    route();
  }

  static jeloriaScroll(playerData, item) {
    const afterChoosingItem = (selectedItem) => {
      if (Item.isItemExhausted(selectedItem)) {
        ResolveItemAbility.sendToServerCommon(item, {
          selectedItemId: selectedItem.id,
        });
      } else {
        UIMessage.displayInfoMessage(
          "Choose an exhausted item",
          "You must choose an exhausted item to use this ability."
        );
      }
    };
    InteractionFunctions.chooseAItem(afterChoosingItem, false, false);
  }

  static kobamdaScroll(playerData, item) {
    UIMessage.displayConfirmMessage(
      "Kobamda Scroll",
      "Do you want to use the Kobamda Scroll ?",
      () => {
        ResolveItemAbility.sendToServerCommon(item, {});
      }
    );
  }

  static exhaustItem(playerData, item) {
    UIMessage.displayConfirmMessage(
      "Exhaust Item",
      "Do you want to exhaust " + item.name + " ?",
      () => {
        ResolveItemAbility.sendToServerCommon(item, {});
      }
    );
  }
}

export default ItemAbilityInput;
