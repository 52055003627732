const Map = require("../MapData/Map");
const SpaceObject = require("../MapData/SpaceObject");
const Color = require("../../../Common/Config/Colors");
const Phase = require("../Game/Phase");
const Planet = require("../MapData/Planet");
const Unit = require("../MapData/Unit");
const Cost = require("../Utils/Cost");
const LogBook = require("../Connection/LogBook");
const System = require("../MapData/System");
const LogAttachment = require("../Connection/LogAttachment");
const CustomMath = require("../../../Common/Math/CustomMath");
const LogMessage = require("../Connection/LogMessage");
const UIMessage = require("../Connection/UIMessage");
const Rules = require("../Game/Rules");
const ResolveData = require("../EndOfRound/ResolveData");
const Popup = require("../../Other/Popup");

class CombatRecording {
  static TYPE_GROUND = "ground";
  static TYPE_SPACE = "space";

  static createRecData(planet = null) {
    const data = {
      planetName: planet ? planet.name : null,
      combatType: planet ? this.TYPE_GROUND : this.TYPE_SPACE,
      recSections: [],
      sectionCursor: 0,
      stepCursor: 0,
    };

    return data;
  }

  static createSection(recData) {
    recData.recSections.push([]);
  }

  static createStep(recData, logBook, detailedLogBook, system) {
    const data = {
      logBook: logBook,
      detailedLogBook: detailedLogBook,
      system: JSON.parse(JSON.stringify(system)),
    };

    if (recData.recSections.length === 0) {
      this.createSection(recData);
    }

    recData.recSections[recData.recSections.length - 1].push(data);
  }

  //Set
  static initialize(recData) {
    recData.stepCursor = 0;
    recData.sectionCursor = 0;
  }

  //Get
  static isNextStep = (recData) => recData.cursor < recData.recSteps.length - 1;
  static isPreviousStep = (recData) => recData.cursor > 0;

  static getNextStep = (recData) => {
    recData.stepCursor++;
    if (
      recData.stepCursor >= recData.recSections[recData.sectionCursor].length
    ) {
      recData.stepCursor =
        recData.recSections[recData.sectionCursor].length - 1;
      return this.getNextSection(recData);
    }
    return recData.recSections[recData.sectionCursor][recData.stepCursor];
  };

  static getPreviousStep = (recData) => {
    console.log("recData in", recData);
    recData.stepCursor--;
    if (recData.stepCursor < 0) {
      if (recData.sectionCursor > 0) {
        recData.sectionCursor--;
        recData.stepCursor =
          recData.recSections[recData.sectionCursor].length - 1;
      } else {
        recData.stepCursor = 0;
      }
    }
    console.log("recData out", recData);
    this.getCurrentStep(recData);
  };

  static getNextSection = (recData) => {
    recData.sectionCursor++;
    if (recData.sectionCursor >= recData.recSections.length) {
      recData.sectionCursor = recData.recSections.length - 1;
      recData.stepCursor =
        recData.recSections[recData.sectionCursor].length - 1;
    } else {
      recData.stepCursor = 0;
    }
    return recData.recSections[recData.sectionCursor][recData.stepCursor];
  };

  static getPreviousSection = (recData) => {
    recData.sectionCursor--;
    if (recData.sectionCursor < 0) {
      recData.sectionCursor = 0;
    }
    recData.stepCursor = 0;
    return recData.recSections[recData.sectionCursor][recData.stepCursor];
  };

  static getCurrentStep = (recData) => {
    return recData.recSections[recData.sectionCursor][recData.stepCursor];
  };

  static getCombatType = (recData) => recData.combatType;

  static getCurrentSystem = (recData) =>
    recData.recSections[recData.sectionCursor][recData.stepCursor].system;

  static getPlanetName = (recData) => recData.planetName;

  static getLogBook = (recData) => {
    return recData.recSections[recData.sectionCursor][recData.stepCursor]
      .logBook;
  };

  static getDetailedLogBook = (recData) =>
    recData.recSections[recData.sectionCursor][recData.stepCursor]
      .detailedLogBook;

  static getStepSystem = (step) => step.system;

  static isEmpty = (recData) => {
    if (recData.recSections.length === 0) return true;
    if (recData.recSections.length === 1 && recData.recSections[0].length === 0)
      return true;

    return false;
  };
}

module.exports = CombatRecording;
