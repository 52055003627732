import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";

function GameListItem({ game, setRouteMainHandler }) {
  //console.log(game);

  if (!StaticGameData.username) {
    return null;
  }

  const joinGameHandler = (selectedGame) => {
    console.log("Join game: ", selectedGame);
    setRouteMainHandler({
      activeScreen: "GameView",
      game: selectedGame,
    });
  };

  let colorText = "white";

  const selfPlayer = Game.getPlayerFromUsername(game, StaticGameData.username);
  if (selfPlayer.isWaitingForNewRound) {
    colorText = "grey";
  }

  return (
    <div onClick={() => joinGameHandler(game)} style={{ color: colorText }}>
      Game ID: {game.gameId}, Number of Players: {game.players.length}/
      {game.maxNumberPlayers}, Creation Date: {game.creationDate}
    </div>
  );
}

export default GameListItem;
