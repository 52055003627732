const Color = require("../../../Common/Config/Colors");
const { isExhausted } = require("../Ability/AbilityCommon");
const ActivePassiveAbility = require("../Ability/ActivePassiveAbility");
const Rules = require("../Game/Rules");
const Cost = require("../Utils/Cost");

class Item {
  static TYPE_ARTIFACT = "Artifact";
  static TYPE_RELIC = "Relic";

  static NAME_TRADE = "Trade";
  static NAME_PEACE_SPACE = "Peace in Space";
  static NAME_PEACE_GROUND = "Peace on Ground";

  static NAME_CALVARIA_SCROLL = "Calvaria scroll";
  static NAME_DELPHAS_SCROLL = "Delphas scroll";
  static NAME_ICANTA_SCROLL = "Icanta scroll";
  static NAME_JELORIA_SCROLL = "Jeloria scroll";
  static NAME_KOBAMDA_SCROLL = "Kobamda scroll";
  static NAME_MALTRION_SCROLL = "Maltrion scroll";
  static NAME_QUORIDIOM_SCROLL = "Quoridiom scroll";
  static NAME_RYNZORATH_SCROLL = "Rynzorath scroll";
  static NAME_SELTAAR_SCROLL = "Seltaar scroll";
  static NAME_TAARKIR_SCROLL = "Taarkir scroll";
  static NAME_VOIDBORN_SCROLL = "Voidborn scroll";

  static generateItemCreationId(playerData) {
    const playerInGameId = playerData.playerInGameId;
    playerData.unitCreationId = playerData.unitCreationId + 1;
    return playerInGameId + "-" + playerData.unitCreationId;
  }

  static createRelic(playerData, name, faction, data = {}) {
    return this.create(playerData, name, { faction: faction, ...data });
  }

  static createArtifact(playerData, name, data = {}) {
    return this.create(playerData, name, data);
  }

  static create(playerData, name, data = {}) {
    return {
      id: playerData ? this.generateItemCreationId(playerData) : null,
      name: name,
      data: data,
      isExhausted: false,
    };
  }

  static getRelicsFromItems(items) {
    const relics = [];
    items.forEach((item) => {
      const itemDesc = this.getDescription(item);
      if (itemDesc.type === Item.TYPE_RELIC) {
        relics.push(item);
      }
    });
    return relics;
  }

  static getArtifactsFromItems(items) {
    const artifacts = [];
    items.forEach((item) => {
      const itemDesc = this.getDescription(item);
      if (itemDesc.type === Item.TYPE_ARTIFACT) {
        artifacts.push(item);
      }
    });
    return artifacts;
  }

  static canBeGiven(itemDesc, faction) {
    if (itemDesc.type === this.TYPE_RELIC) {
      if (faction === itemDesc.data.faction) {
        return true;
      }
    }
    return false;
  }

  static getRelicsFromDescList(itemDescList) {
    return itemDescList.filter((item) => item.type === Item.TYPE_RELIC);
  }

  static getArtifactsFromDescList(itemDescList) {
    return itemDescList.filter((item) => item.type === Item.TYPE_ARTIFACT);
  }

  static getItemDescList(items) {
    return items.map((item) => this.getDescription(item));
  }

  static isItemInList(item, itemList) {
    return itemList.some((listItem) => listItem.id === item.id);
  }

  static addItemToArray(item, array) {
    array.push(item);
  }

  static removeItemFromArray(item, array) {
    const index = array.findIndex((arrayItem) => arrayItem.id === item.id);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  static getItemFromId(items, id) {
    return items.find((item) => item.id === id);
  }

  static isItemExhausted(item) {
    return item.isExhausted;
  }

  static exhaustItem(item) {
    item.isExhausted = true;
  }

  static readyItem(item) {
    item.isExhausted = false;
  }

  static readyItemsInArray(items) {
    items.forEach((item) => {
      this.readyItem(item);
    });
  }

  static getDescription(item) {
    if (item.name === Item.NAME_TRADE) {
      return {
        name: Item.NAME_TRADE,
        type: Item.TYPE_RELIC,
        data: item.data,
        active: false,
        faction: item.data.faction,
        text: "Trade " + item.data.value,
        effect:
          "If you are not " +
          item.data.faction +
          " player : at the start of the next round, you and " +
          item.data.faction +
          " player gain " +
          item.data.value +
          " credits. Then, this relic is automatically sent back to " +
          item.data.faction +
          " player.",
        sendBackBeginningOfRound: true,
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "If you are not the " +
              item.data.faction +
              " player : at the start of the next round, you and " +
              item.data.faction +
              " player gain " +
              item.data.value +
              " credits. Then, this relic is automatically sent back to " +
              item.data.faction +
              " player.",
            false
          ),
        ],
      };
    }
    if (item.name === Item.NAME_PEACE_SPACE) {
      return {
        name: Item.NAME_PEACE_SPACE,
        type: Item.TYPE_RELIC,
        data: item.data,
        active: false,
        faction: item.data.faction,
        text: "Space Peace ",
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "If you are not " +
              item.data.faction +
              " player : " +
              item.data.faction +
              " player has to pay extra " +
              Rules.COST_ATTACK_SPACE_WHEN_PEACE +
              " energy to move ships into a space area you control. If he does, this relic will be sent back to " +
              item.data.faction +
              " player at the start of the next round.",
            false
          ),
        ],
      };
    }
    if (item.name === Item.NAME_PEACE_GROUND) {
      return {
        name: Item.NAME_PEACE_GROUND,
        type: Item.TYPE_RELIC,
        data: item.data,
        active: false,
        faction: item.data.faction,
        text: "Ground Peace ",
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "If you are not " +
              item.data.faction +
              " player : the " +
              item.data.faction +
              " player has to pay extra " +
              Rules.COST_ATTACK_GROUND_WHEN_PEACE +
              " population to invade a planet you control. If he does, this relic will be sent back to the " +
              item.data.faction +
              " player at the start of the next round.",
            false
          ),
        ],
      };
    }

    //Scrolls of Exiled
    if (item.name === Item.NAME_CALVARIA_SCROLL) {
      return {
        name: Item.NAME_CALVARIA_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, your units damage values (min and max) are increase by 0.2.",
            false
          ),
        ],
      };
    }
    if (item.name === Item.NAME_DELPHAS_SCROLL) {
      return {
        name: Item.NAME_DELPHAS_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, damage produced by Orbital Canons assigned to your units are decreased by 4.",
            false
          ),
        ],
      };
    }
    if (item.name === Item.NAME_ICANTA_SCROLL) {
      return {
        name: Item.NAME_ICANTA_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, your planetary canons have +3 to their Space Canon Defense and Planetary Canon Defense abilities values (min and max).",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_JELORIA_SCROLL) {
      return {
        name: Item.NAME_JELORIA_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "Ready one artifact you own.",
            true
          ),
        ],
      };
    }

    if (item.name === Item.NAME_KOBAMDA_SCROLL) {
      return {
        name: Item.NAME_KOBAMDA_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "Gain 1 vp per ice and oceanic planet you control.",
            true
          ),
        ],
      };
    }

    if (item.name === Item.NAME_MALTRION_SCROLL) {
      return {
        name: Item.NAME_MALTRION_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, during the first round of any combat, cancel 4 hits assigned to your units.",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_QUORIDIOM_SCROLL) {
      return {
        name: Item.NAME_QUORIDIOM_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, when you take control of a planet, before the ennemy structures are destroyed, you replace them with your own structures.",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_RYNZORATH_SCROLL) {
      return {
        name: Item.NAME_RYNZORATH_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, all of your planets can build 3 more units.",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_SELTAAR_SCROLL) {
      return {
        name: Item.NAME_SELTAAR_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, your carriers and dreadnoughts have their capacity increased by 2.",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_TAARKIR_SCROLL) {
      return {
        name: Item.NAME_TAARKIR_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(null, "Exhaust this artefact.", true),
          ActivePassiveAbility.createDesc(
            null,
            "If this artifact is exhausted, after your units land on an ennemy planet, destroy one random ground force of your ennemy on this planet.",
            false
          ),
        ],
      };
    }

    if (item.name === Item.NAME_VOIDBORN_SCROLL) {
      return {
        name: Item.NAME_VOIDBORN_SCROLL,
        type: Item.TYPE_ARTIFACT,
        data: item.data,
        active: false,
        text: "",
        abilities: [
          ActivePassiveAbility.createDesc(
            null,
            "Hits produced by BOMBARDMENT that would be assigned to your units are reduced by 2.",
            false
          ),
        ],
      };
    }

    return null;

    ////Icanta scroll When exausted, your planetary canons have +0.8 to their Space Canon Defense and Planetary Canon Defense abilities.
    //Jeloria scroll", //Exhaust this artifact to ready one artifact you own.
    //Kobamda scroll", //Gain 1 vp per ice and oceanic planet you control.
    // Maltrion scroll.", //When exhausted, during the first round of any combat, cancel 4 hits assigned to your units.
    //exhausted Quoridiom scroll", //When exausted, when you take control of a planet, before the ennemy structures are destroyed, you replace them with your own structures.
    //exhausted Rynzorath scroll", //When exausted, all of your planets can build 3 more units.
    //Seltaar scroll.", //Your carriers and dreadnoughts have their capacity increased by 1.
  }

  static getItemsFromName(name, items) {
    return items.filter((item) => item.name === name);
  }

  static includeRelicFromNameFromFaction(name, faction, items) {
    return items.some(
      (item) => item.name === name && item.data.faction === faction
    );
  }

  static getRelicsFromNameFromFaction(name, faction, items) {
    return items.filter(
      (item) => item.name === name && item.data.faction === faction
    );
  }

  static playerHasItem(playerData, name) {
    return playerData.items.some((item) => item.name === name);
  }

  static playerHasExhaustedItem(playerData, name) {
    return playerData.items.some(
      (item) => item.name === name && item.isExhausted
    );
  }

  static hasAnActiveAbility(itemDesc) {
    return itemDesc.abilities.some((ability) => ability.active);
  }
}

module.exports = Item;
