const MinorFactionList = require("./MinorFactionList");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const { Description } = require("@headlessui/react");
const SecondaryObject = require("../MapData/SecondaryObject.js");
const Rules = require("../Game/Rules.js");
const Faction = require("../PlayerData/Faction.js");
const Fleet = require("../MapData/Fleet.js");
const Unit = require("../MapData/Unit.js");
const MinorFactionAbility = require("./MinorFactionAbility.js");

class MinorFaction {
  static create(name, id, populationCostMin, populationCostMax) {
    const data = {
      name: name,
      populationCostMin: populationCostMin,
      populationCostMax: populationCostMax,
      populationCost: CustomMath.generateRandomNumber(
        populationCostMin,
        Math.ceil((populationCostMax + populationCostMin) / 4)
      ),
      missionDone: false,
      id: id,
    };

    return data;
  }

  static adminAfterRound(minorFaction) {
    minorFaction.populationCost = Math.min(
      minorFaction.populationCostMax,
      Math.max(minorFaction.populationCostMin, minorFaction.populationCost - 2)
    );
  }

  static resetPopulationCost(minorFaction) {
    minorFaction.populationCost = minorFaction.populationCostMax;
  }

  static generateRandomMinorFactionArray(size) {
    //Prepare faction desc array
    const factionDescListSource = this.getFactionDescriptionList();
    const amountArrayNeeded = Math.ceil(size / factionDescListSource.length);

    let factionDescList = [];
    for (let i = 0; i < amountArrayNeeded; i++) {
      factionDescList = factionDescList.concat(
        JSON.parse(JSON.stringify(factionDescListSource))
      );
    }

    CustomMath.shuffleArray(factionDescList);

    //prepare return array
    const factionArray = [];
    for (let i = 0; i < size; i++) {
      const factionDesc = factionDescList.pop();
      const faction = MinorFaction.create(
        factionDesc.name,
        i, // for ID
        factionDesc.populationCostMin,
        factionDesc.populationCostMax
      );
      factionArray.push(faction);
    }

    return factionArray;
  }

  static getFactionDescriptionList = () => {
    const minorFactionAbilities = MinorFactionAbility.getAbilities();

    return [
      /* {
        name: Faction.MINOR_FACTION_NAME_KOHOR,
        description:
          "The Kohor travels the galaxy, trading with all factions. They don't always sell what you need, but they always have what you want. The council is investigating on them, they might sell forbidden goods.",
        populationCostMin: 4,
        populationCostMax: 10,
        abilityName: "Get Corrupted",
        abilityDescription: "Gain 6 credits.",
        missionName: "Provide Escort Ships",
        missionDescription:
          "Select 2 destroyers and 2 carriers in this system to give to the Kohor.",
        missionVP: 5,
        areUnitsInSystem: false,
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.MINOR_FACTION_NAME_BOROUl,
        description:
          "The Borouls are a faction of pirates, they are feared by all factions. They are known to attack commercial ships and steal their cargo.",
        populationCostMin: 6,
        populationCostMax: 12,
        abilityName: "Sponsor an attack",
        abilityDescription:
          "Choose a system at maximum 2 distances from this system. Place 3 Boroul crusers in this system.",
        missionName: "Secure the system",
        missionDescription:
          "Have control of the space area in this system, with no Boroul units in it.",
        missionVP: 7,
        areUnitsInSystem: true, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
          Unit.UNIT_TYPE_CRUSER,
        ],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: Faction.NAME_E_AETHERION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_AETHERION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_ANDAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_ANDAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_BOREALIS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_BOREALIS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_CALVARIA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_CALVARIA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_DELPHAS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_DELPHAS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      /*{
        name: Faction.NAME_E_DRAKOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_DRAKOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: Faction.NAME_E_ERINYS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_ERINYS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      /*{
        name: Faction.NAME_E_EROSIUM,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_EROSIUM],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },*/
      {
        name: Faction.NAME_E_FELTOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_FELTOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_GHELOR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_GHELOR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_HELKARAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_HELKARAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_ICANTA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_ICANTA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_JELORIA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_JELORIA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_KORATH,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_KORATH],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_KOBAMDA,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_KOBAMDA],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_LARAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_LARAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_MALTRION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_MALTRION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_MINARI,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_MINARI],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_OPHARIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_OPHARIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_PROTHIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_PROTHIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_QUORIDIOM,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_QUORIDIOM],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_RYNZORATH,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_RYNZORATH],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_SELTAAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_SELTAAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_TERRAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_TERRAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_TAARKIR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_TAARKIR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_UTARION,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_UTARION],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_VOIDBORN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_VOIDBORN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_WOLTHAAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_WOLTHAAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_XELPHAR,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_XELPHAR],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_XENOS,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_XENOS],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
      {
        name: Faction.NAME_E_ZYLORIAN,
        description: "to be done",
        populationCostMin: 2,
        populationCostMax: 16,
        ability: minorFactionAbilities[Faction.NAME_E_ZYLORIAN],
        missionName: "to be done",
        missionDescription: "to be done",
        missionVP: 5,
        areUnitsInSystem: false, //For testing put on false
        areUnitsOnPlanet: false,
        unitsInSystemList: [],
        unitsOnPlanetList: [],
        doesNeedAPlanet: false,
        doesNeedNoPlanet: false,
      },
    ];
  };

  static getFactionDescDataFromName(name) {
    const factionDescList = this.getFactionDescriptionList();
    for (let i = 0; i < factionDescList.length; i++) {
      if (factionDescList[i].name === name) {
        return factionDescList[i];
      }
    }
  }

  static getName(minorFaction) {
    return minorFaction.name;
  }

  static getNameWithoutPrefixM(minorFaction) {
    return;
    return minorFaction.name.substring(4);
  }
}

module.exports = MinorFaction;
