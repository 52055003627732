import React, { useState } from "react";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Logo from "../Utils/Logo";
import ImagesUrl from "../../Data/Other/ImagesUrl";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Color, { COLOR_CREDIT } from "../../Common/Config/Colors";
import BaseButton from "../Utils/BaseButton";
import MinorFactionActionData from "../../Data/GameData/ActionData/MinorFactionActionData";
import ResolveMinorFactionAbility, {
  hasResolvedMinorFactionAbility,
} from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import MinorFactionAbilityInput from "../Ability/MinorFactionAbilityInput";
import MinorFactionMissionInput from "../Ability/MinorFactionMissionInput";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import Rules from "../../Data/GameData/Game/Rules";
import Faction from "../../Data/GameData/PlayerData/Faction";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import AbilityView from "../Ability/AbilityView";

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const IMAGE_SIZE_SQUARE = 20;
const IMAGE_SIZE_ICON_SQUARE = "10px";
const IMAGE_SIZE_OVAL_HEIGHT = 10;
const IMAGE_SIZE_OVAL_WIDTH = 30;
const IMAGE_SIZE_PORTRAIT = 28;

const FONT_SIZE = "8px";

const MinorFactionObjectView = ({
  mapView = false,
  size = IMAGE_SIZE_SQUARE,
  spaceObject = null,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const minorFaction = SecondaryObject.getMinorFaction(spaceObject);

  const DisplayFactionImage = () => {
    if (!minorFaction || !minorFaction.name) return;

    const imageStyle = {
      height: size + "px",
      width: size + "px",
      //backgroundColor: "green",

      //verticalAlign: "bottom",
      //objectFit: "cover", // Ensures the image covers the area while preserving aspect ratio
    };

    return (
      <div
        style={{
          //position: "relative",
          //width: size + "px",
          //height: size + "px",
          //border: Math.max(size / 100, 1) + "px solid gold",
          //boxShadow: "0 0 0 " + size / 100 + "px gold", // Simulated thin border
          //borderRadius: Math.max(size / 40, 1) + "px",
          //backgroundColor: "rgb(200,200,200,0.5)",
          height: size + "px",
          width: size + "px",
          position: "relative",

          //backgroundColor: "yellow",
        }}
      >
        <img
          style={imageStyle}
          src={Faction.getMinorFactionImageFileName(minorFaction.name)}
          alt={"LOGO_" + minorFaction.name}
        />
      </div>
    );
  };

  const DisplaySimpleBanniere = () => {
    return (
      <span
        style={{
          fontSize: size / 2 + "px",
          //backgroundColor: "purple",
          //minHeight: "660px",
        }}
      >
        {false && <Logo logoName="action"></Logo>}
        {mapView && (
          <span
            style={
              {
                //color: Color.COLOR_GREEN_THEME_MEDIUM
              }
            }
          >
            {minorFaction.populationCost}
          </span>
        )}
        {false && (
          <span>
            <Logo logoName="population"></Logo>
          </span>
        )}
      </span>
    );
  };

  const DisplayCard = () => {
    return (
      <div
        className="div-ver-center"
        style={{
          padding: "2px",
          backgroundColor: Color.COLOR_LIGHT_SHADOW,
          borderRadius: size / 8,
          width: size + "px",
          //backgroundColor: "red",
          //padding: size / 8 + "px",
        }}
      >
        <DisplayFactionImage></DisplayFactionImage>
        {false && (
          <div
            style={{
              marginBottom: size / 40 + "px",
              backgroundColor: "purple",
            }}
          ></div>
        )}
        <DisplaySimpleBanniere></DisplaySimpleBanniere>
      </div>
    );
  };

  const DisplayMapSquare = ({ children }) => {
    return (
      <div
        className="div-ver-center"
        style={{
          /*width: IMAGE_SIZE_SQUARE,
          height: IMAGE_SIZE_SQUARE,
          backgroundColor: "rgba(10,10,30,1)",

          borderRadius: "5px",*/
          //border: "1px solid white",
          width: size + "px",
          position: "relative",
        }}
      >
        {children}
      </div>
    );
  };

  const DisplayBanniere = () => {
    if (!minorFaction || !minorFaction.name) return;

    //const backgroundColor = Color.COLOR_PLAYER_PINK;
    const backgroundColor = "rgb(0,0,0,0.3)";

    const elementStyle = {
      //backgroundColor: "yellow",
      //width: IMAGE_SIZE_PORTRAIT,
      //height: FONT_SIZE,
      fontSize: Math.round(size / 3.5) + "px",
      textAlign: "center",
      color: "gold",
    };

    const ovalStyle = {
      //width: `${IMAGE_SIZE_SQUARE}px`,
      //height: `${IMAGE_SIZE_SQUARE / 2}px`,
      //borderRadius: `0 0 ${IMAGE_SIZE_SQUARE}px 0`,
      //borderRadius: `0 0 ${(IMAGE_SIZE_SQUARE * 1.5) / 2}px 0`,
      backgroundColor: backgroundColor,
      width: `${size}px`,
      height: `${size / 3}px`,
      borderRadius: " 0 0% 50% 50%/ 0% 0% 100% 100%",
      //boxShadow: "0 0 0 " + size / 100 + "px gold", // Simulated thin border

      //borderColor: "rgb(255,255,255,0.05)",
      //borderStyle: "solid",
      //borderWidth: "1px",
      /*boxShadow: `
      0 0 0 1px rgba(0, 0, 0, 0.3)
    `,*/
      //border: "1px solid grey",
      //boxShadow: `0 0 10px 1px ${backgroundColor}`, // Blurred border effect
      //border: Math.max(size / 100, 1) + "px solid gold",
    };

    const barStyle = {
      backgroundColor: backgroundColor,
      width: `${size}px`,
      height: `${1}px`,
      borderRadius: "1px 1px 0 0",
      //boxShadow: `0 0 10px 1px ${backgroundColor}`, // Blurred border effect
    };

    const circleStyle = {
      //width: `${IMAGE_SIZE_SQUARE}px`,
      //height: `${IMAGE_SIZE_SQUARE / 2}px`,
      //borderRadius: `0 0 ${IMAGE_SIZE_SQUARE}px 0`,
      //borderRadius: `0 0 ${(IMAGE_SIZE_SQUARE * 1.5) / 2}px 0`,
      backgroundColor: backgroundColor,
      width: `${IMAGE_SIZE_OVAL_HEIGHT}px`,
      height: `${IMAGE_SIZE_OVAL_HEIGHT}px`,
      borderRadius: "50%",
      //border: "1px solid grey",
      //boxShadow: `0 0 10px 1px ${backgroundColor}`, // Blurred border effect
    };

    return (
      <div className="div-ver-center">
        <div className="div-fill" style={ovalStyle}>
          <span style={elementStyle}>
            {false && <Logo logoName="action"></Logo>}
            {minorFaction.populationCost}
            <span>
              {" "}
              <Logo logoName="population"></Logo>
            </span>
          </span>
        </div>
      </div>
    );
  };

  const DisplayIconSquare = ({ children }) => {
    return (
      <div
        className="div-ver-center"
        style={{
          width: IMAGE_SIZE_PORTRAIT + "px",
          height: FONT_SIZE,
          backgroundColor: "black",
          borderRadius: "2px",
        }}
      >
        {children}
      </div>
    );
  };

  const DisplayIconCircle = ({ children }) => {
    return (
      <div
        className="div-ver-center"
        style={{
          width: IMAGE_SIZE_ICON_SQUARE,
          height: IMAGE_SIZE_ICON_SQUARE,
          backgroundColor: "black",
          borderRadius: "50%",
        }}
      >
        {children}
      </div>
    );
  };

  const DisplayIcons = () => {
    return (
      <div
        className="div-hor-center"
        style={{
          position: "absolute",
          top: "100%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <DisplayIconCircle>1</DisplayIconCircle>
        <div style={{ width: "5px" }}></div>
        <DisplayIconSquare>2</DisplayIconSquare>
      </div>
    );
  };

  const DisplayAbility = ({ abilityDescData }) => {
    const abilityName = abilityDescData.ability.ability.name;
    const abilityDescription = abilityDescData.ability.ability.text;
    const hasResolveMinorFactionAbility =
      ResolveMinorFactionAbility.hasResolvedMinorFactionAbility(playerData);

    const buttonType = hasResolveMinorFactionAbility ? "greyOut" : "white";
    const logoName = "action";

    console.log("DEBUG  abilityDescData : ", abilityDescData);

    return (
      <div style={{ marginTop: "1em" }}>
        {playerData.hasResolvedMinorFactionAbility && (
          <div style={{ color: "red", marginBottom: "0.5em" }}>
            You already used a minor faction ability this round.
          </div>
        )}
        <BaseButton
          type={buttonType}
          onClick={() => {
            MinorFactionAbilityInput.routeMinorFactionAbilityInput(
              spaceObject.name,
              minorFaction
            );
          }}
        >
          <div style={{ textDecoration: "underline" }}>
            {false && <Logo logoName={logoName}></Logo>} Interraction :{" "}
            {abilityName}
          </div>
          <div>
            Cost : {minorFaction.populationCost}{" "}
            <Logo logoName="population"></Logo>
          </div>

          <AbilityView
            abilityDesc={abilityDescData.ability.ability}
          ></AbilityView>
        </BaseButton>
        <div style={{ marginBottom: "1em" }}>
          Each round, the <Logo logoName="population"></Logo> cost for resolving
          this action decrease by 2 (min {minorFaction.populationCostMin}, max{" "}
          {minorFaction.populationCostMax}).
        </div>
      </div>
    );
  };

  const DisplayMenuView = () => {
    const abilityDescData = MinorFaction.getFactionDescDataFromName(
      minorFaction.name
    );

    return (
      <div className="div-ver-center" style={{ textAlign: "center" }}>
        <div>{minorFaction.name}</div>
        {false && (
          <DisplayMapSquare>
            <DisplayFactionImage></DisplayFactionImage>
            {true && <DisplayBanniere></DisplayBanniere>}
            {false && <DisplayIcons></DisplayIcons>}
          </DisplayMapSquare>
        )}
        <DisplayCard></DisplayCard>
        <DisplayAbility abilityDescData={abilityDescData}></DisplayAbility>
      </div>
    );
  };

  return (
    <div>
      {mapView && <DisplayCard></DisplayCard>}
      {!mapView && <DisplayMenuView></DisplayMenuView>}
    </div>
  );
};

export default MinorFactionObjectView;
