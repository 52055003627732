import React, { useState, useEffect, useMemo } from "react";
import useViewportSize from "./CustomHook/UseViewportSize";
import "../CSS/Menu.css";
import "../CSS/MapGrid.css";
import ArrowButton from "./Utils/ArrowButton";
import SystemView from "./Map/SystemView";
import System from "../Data/GameData/MapData/System";
import StaticGameData from "../Data/GameData/StaticGameData";
import TaskBarView from "./TaskBarView";
import NavigationView from "./NavigationView";
import GameGlobalLayout from "./GameGlobalLayout";
import FactionLogo from "./Utils/FactionLogo";
import Logo from "./Utils/Logo";
import ResourceBarView from "./Utils/ResourceBarView";
import BaseButton from "./Utils/BaseButton";
import PlanetViewActivation from "./ManageSystem/ActivationView/Objects/PlanetViewActivation";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import LogBook from "../Data/GameData/Connection/LogBook";
import SystemViewActivation from "./ManageSystem/ActivationView/Objects/SystemViewActivation";
import PlanetView from "./Object/PlanetView";
import ModifyFleetLimitAction from "../Data/GameData/ActionData/ModifyFleetLimitAction";
import InteractionFunctions from "./UIInteraction/InteractionFunctions";
import ItemListView from "./Transaction/ItemListView.jsx";
import InventoryView from "./Transaction/InventoryView.jsx";

const CreateMap = require("../Data/GameData/MapData/CreateMap.js");
const Request = require("../Common/Requests/Request.js");
const Map = require("../Data/GameData/MapData/Map.js");
const Popup = require("../Data/Other/Popup.js");
const LogAttachment = require("../Data/GameData/Connection/LogAttachment.js");

const GameFactionsView = ({}) => {
  // Using the custom hook to manage viewport size
  const viewportSize = useViewportSize();
  //console.log("Viewport size: ", viewportSize);
  //console.log("MapView playerData: ", playerData);

  const playerData = StaticGameData.getPlayerData();

  const globalState = Popup.getGlobalState("GameFactionsView");

  const goToPlanets = (selectedFaction) => {
    Popup.addGlobalState("GameFactionsView", {
      view: "planets",
      selectedFaction: selectedFaction.faction.name,
    });
    Popup.touch();
  };

  const returnToGlobal = () => {
    Popup.deleteGlobalState("GameFactionsView");
    Popup.touch();
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Map",
        callback: () => {
          Popup.deleteGlobalState("GameFactionsView");
          Popup.unStackToMap();
        },
      },
    ],
  };

  const DisplayFactionPlanets = ({ navigationData }) => {
    navigationData.buttonsData.push({
      text: "Factions",
      callback: () => returnToGlobal(),
    });

    const globalState = Popup.getGlobalState("GameFactionsView");
    console.log("DisplayFactionPlanets globalState", globalState);
    const selectedPlayerData = PlayerData.getPlayerDataFromFaction(
      playerData,
      globalState.selectedFaction
    );

    const systems = Map.getSystemsWhereFactionHasSomething(
      playerData.map,
      selectedPlayerData.faction.name
    );

    return (
      <div
        style={{
          textAlign: "center",
          //justifyContent: "left",
          //alignItems: "left",
        }}
      >
        {systems.map((system, indexSystem) => {
          const planets = System.getPlanets(system);
          return (
            <div key={indexSystem}>
              <div style={{ marginBottom: "2em" }}>
                <SystemViewActivation system={system}></SystemViewActivation>
              </div>
              {planets.map((planet, index) => {
                return (
                  <div key={index} style={{ textAlign: "left" }}>
                    <PlanetView
                      planet={planet}
                      showProductionHalo={true}
                    ></PlanetView>
                  </div>
                );
              })}
              <hr></hr>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayFaction = ({ playerDataP }) => {
    const fullActivityLog = LogAttachment.getFullActivityLog(playerDataP);
    if (!playerDataP.faction) return null;
    return (
      <div>
        <div>
          <FactionLogo factionName={playerDataP.faction.name}></FactionLogo>{" "}
          <span>{playerDataP.faction.name}</span>
          <span style={{ color: "gold" }}>
            {" "}
            {PlayerData.getVP(playerDataP)} VP
          </span>
        </div>
        <div>
          <DisplayResources playerData={playerDataP}></DisplayResources>
        </div>
        <div className="div-fill">
          <BaseButton
            onClick={() => {
              Popup.addLayer({
                name: "FullScreenLogView",
                logBook: PlayerData.getActivityLog(playerDataP),
              });
            }}
          >
            Logs
          </BaseButton>

          <BaseButton
            onClick={() => {
              goToPlanets(playerDataP);
            }}
          >
            Systems
          </BaseButton>

          <BaseButton
            onClick={() => {
              Popup.addLayer({
                name: "TechTreeView",
                playerInGameId: playerDataP.playerInGameId,
              });
            }}
          >
            Tech
          </BaseButton>
          <BaseButton
            onClick={() => {
              Popup.activateBlackPopup(
                <InventoryView
                  playerId={PlayerData.getPlayerId(playerDataP)}
                ></InventoryView>
              );
            }}
          >
            Inventory
          </BaseButton>
        </div>
        <hr></hr>
      </div>
    );
  };

  const DisplayResources = ({ playerData }) => {
    return (
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
          <div>
            <Logo logoName="credit"></Logo>
            <span> : {playerData.credit} (Credits) </span>
          </div>
          <div>
            <Logo logoName="mineral"></Logo>
            <span> : {playerData.mineral} (Science) </span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span> : {playerData.science} (Science) </span>
          </div>
          <div>
            <Logo logoName="population"></Logo>
            <span> : {playerData.population} (Influence) </span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span> : {playerData.energy} (Energy) </span>
          </div>
          <div>
            <Logo logoName="cargo"></Logo>
            <span> : {playerData.cargo} (Fleet Limit) </span>
          </div>
          {playerData.colonists > 0 && (
            <div>
              <span>Colonists : {playerData.colonists}</span>
            </div>
          )}
        </div>
        <div>
          Artifacts :{" "}
          <ItemListView
            itemList={PlayerData.getItems(playerData)}
          ></ItemListView>
        </div>
      </div>
    );
  };

  const DisplayFactions = () => {
    return (
      <div>
        <DisplayFaction playerDataP={playerData}></DisplayFaction>
        {playerData.otherPlayers.map((otherPlayerData, index) => {
          return (
            <div key={index}>
              <DisplayFaction playerDataP={otherPlayerData}></DisplayFaction>{" "}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="div-fill">
      <GameGlobalLayout>
        <div className="text-box">
          {!globalState && <DisplayFactions></DisplayFactions>}
          {globalState && globalState.view === "planets" && (
            <DisplayFactionPlanets
              navigationData={navigationData}
            ></DisplayFactionPlanets>
          )}
        </div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
    </div>
  );
};

export default GameFactionsView;
