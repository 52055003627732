const CustomMath = require("../../../Common/Math/CustomMath");
const ImagesUrl = require("../../Other/ImagesUrl");
const Objectif = require("./Objectif_old");

class Objectives {
  //Selected Objectives
  static getSelectedObjectives = () => {
    return [
      this.NAME_SPEND_SCIENCE_1,
      this.NAME_SPEND_MINERAL_1,
      this.NAME_SPEND_ENERGY_1,
      this.NAME_SPEND_CREDIT_1,
      this.NAME_SPEND_POPULATION_1,
      //5
      this.NAME_PLANET_ICE_WATER_1,
      this.NAME_PLANET_EARTH_DESERT_1,
      this.NAME_PLANET_GAZ_VOLCANIC_1,
      //8
      this.NAME_EMPTY_SPACE_1,
      this.NAME_WHORMHOLE_1,
      this.NAME_MINOR_FACTION_1,
      //11
      this.NAME_SPACE_MIDDLE_1,
      this.NAME_PLANET_MIDDLE_1,
      this.NAME_SPACE_FAR_1,
      this.NAME_PLANET_FAR_1,
      //15
      this.NAME_SPACE_HS_1,
      this.NAME_PLANET_HS_1,
      //17
      this.NAME_TECH_RED_1,
      this.NAME_TECH_BLUE_1,
      this.NAME_TECH_GREEN_1,
      this.NAME_TECH_YELLOW_1,
      this.NAME_TECH_DIVERSITY_1,
      //22
      this.NAME_STRUCTURES_CANON_1,
      this.NAME_STRUCTURES_FACTORY_1,
      this.NAME_STRUCTURES_AO_1,
      this.NAME_STRUCTURES_PLANETARY_SHIELS_1,
      //26
      this.NAME_ARTIFACT_1,
      this.NAME_RELIC_1,
      //28
      this.NAME_DESTROYER_1,
      this.NAME_CRUISER_1,
      this.NAME_DREADNOUGHT_1,
      this.NAME_CARRIER_1,
      this.NAME_MECH_1,
      //33
    ];
  };

  //Spend objectives
  static NAME_SPEND_SCIENCE_1 = "Spend Science 1";
  static NAME_SPEND_MINERAL_1 = "Spend Mineral 1";
  static NAME_SPEND_ENERGY_1 = "Spend Energy 1";
  static NAME_SPEND_CREDIT_1 = "Spend Credit 1";
  static NAME_SPEND_POPULATION_1 = "Spend Population 1";

  //Control planets objectives
  static NAME_PLANET_ICE_WATER_1 = "Control Ice Water 1";
  static NAME_PLANET_EARTH_DESERT_1 = "Control Earth Desert 1";
  static NAME_PLANET_GAZ_VOLCANIC_1 = "Control Gaz Volcanic 1";
  static NAME_PLANET_LESS_2_MINERAL_1 = "Planet Less 2 Mineral";
  static NAME_PLANET_LESS_2_ENERGY_1 = "Planet Less 2 Energy";
  static NAME_PLANET_LESS_2_SCIENCE_1 = "Planet Less 2 Science";
  static NAME_PLANET_LESS_2_POPULATION_1 = "Planet Less 2 Population";

  //Space Area control objectives
  static NAME_EMPTY_SPACE_1 = "Empty Space 1";
  static NAME_WHORMHOLE_1 = "Whormhole 1";
  static NAME_MINOR_FACTION_1 = "Minor Faction 1";

  //Control in the middle
  static NAME_SPACE_MIDDLE_1 = "Space Middle 1";
  static NAME_PLANET_MIDDLE_1 = "Planet Middle 1";

  //Control far away
  static NAME_SPACE_FAR_1 = "Space Far 1";
  static NAME_PLANET_FAR_1 = "Planet Far 1";

  //Control in HS
  static NAME_SPACE_HS_1 = "Control HS 1";
  static NAME_PLANET_HS_1 = "Control HS 1";

  //Techs
  static NAME_TECH_RED_1 = "Tech Red 1";
  static NAME_TECH_BLUE_1 = "Tech Blue 1";
  static NAME_TECH_GREEN_1 = "Tech Green 1";
  static NAME_TECH_YELLOW_1 = "Tech Yellow 1";
  static NAME_TECH_DIVERSITY_1 = "Tech Diversity 1";

  //Structures
  static NAME_STRUCTURES_CANON_1 = "Structures Canon 1";
  static NAME_STRUCTURES_FACTORY_1 = "Structures Factory 1";
  static NAME_STRUCTURES_AO_1 = "Structures AO 1";
  static NAME_STRUCTURES_PLANETARY_SHIELS_1 = "Structures Planetary Shields 1";

  //Planet Bonus
  static NAME_PLANET_BONUS_MINERAL_1 = "Planet Bonus Mineral 1";
  static NAME_PLANET_BONUS_CREDIT_1 = "Planet Bonus Credit 1";
  static NAME_PLANET_BONUS_SCIENCE_1 = "Planet Bonus Science 1";
  static NAME_PLANET_BONUS_ENERGY_1 = "Planet Bonus Energy 1";
  static NAME_PLANET_BONUS_POPULATION_1 = "Planet Bonus Population 1";

  //Relics and artifacts
  static NAME_ARTIFACT_1 = "Artifact 1";
  static NAME_RELIC_1 = "Relic 1";

  //Units
  static NAME_DESTROYER_1 = "Destroyer 1";
  static NAME_CRUISER_1 = "Cruiser 1";
  static NAME_DREADNOUGHT_1 = "Dreadnought 1";
  static NAME_CARRIER_1 = "Carrier 1";
  static NAME_MECH_1 = "Mech 1";

  static create(name, missionDone = false) {
    return {
      name: name,
      missionDone: missionDone,
    };
  }

  static createDesc(objective) {
    const desc = this.getObjectivesDesc()[objective.name];
    desc.missionDone = objective.missionDone;

    return desc;
  }

  static getObjectivesDesc = () => {
    const objectives = {};

    objectives[Objectives.NAME_SPEND_SCIENCE_1] = {
      name: Objectives.NAME_SPEND_SCIENCE_1,
      vp: 4,
      description: "Spend 8 sciences.",
    };

    objectives[Objectives.NAME_SPEND_MINERAL_1] = {
      name: Objectives.NAME_SPEND_MINERAL_1,
      vp: 4,
      description: "Spend 8 minerals.",
    };

    objectives[Objectives.NAME_SPEND_ENERGY_1] = {
      name: Objectives.NAME_SPEND_ENERGY_1,
      vp: 4,
      description: "Spend 6 energies.",
    };

    objectives[Objectives.NAME_SPEND_CREDIT_1] = {
      name: Objectives.NAME_SPEND_CREDIT_1,
      vp: 4,
      description: "Spend 8 credits.",
    };

    objectives[Objectives.NAME_SPEND_POPULATION_1] = {
      name: Objectives.NAME_SPEND_POPULATION_1,
      vp: 4,
      description: "Spend 8 population.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_ENERGY_1] = {
      name: Objectives.NAME_PLANET_LESS_2_ENERGY_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 energy each.",
    };

    objectives[Objectives.NAME_PLANET_ICE_WATER_1] = {
      name: Objectives.NAME_PLANET_ICE_WATER_1,
      vp: 5,
      description: "Control 4 planets with the type ice or oceanic.",
    };

    objectives[Objectives.NAME_PLANET_EARTH_DESERT_1] = {
      name: Objectives.NAME_PLANET_EARTH_DESERT_1,
      vp: 5,
      description: "Control 4 planets with the type earth or desert.",
    };

    objectives[Objectives.NAME_PLANET_GAZ_VOLCANIC_1] = {
      name: Objectives.NAME_PLANET_GAZ_VOLCANIC_1,
      vp: 5,
      description: "Control 4 planets with the type gaz or volcanic.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_MINERAL_1] = {
      name: Objectives.NAME_PLANET_LESS_2_MINERAL_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 minerals each.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_SCIENCE_1] = {
      name: Objectives.NAME_PLANET_LESS_2_SCIENCE_1,
      vp: 4,
      description: "Control 3 planets which produce less than 2 sciences each.",
    };

    objectives[Objectives.NAME_PLANET_LESS_2_POPULATION_1] = {
      name: Objectives.NAME_PLANET_LESS_2_POPULATION_1,
      vp: 4,
      description:
        "Control 3 planets which produce less than 2 population each.",
    };

    objectives[Objectives.NAME_EMPTY_SPACE_1] = {
      name: Objectives.NAME_EMPTY_SPACE_1,
      vp: 4,
      description: "Control the space area of 2 systems with no planets.",
    };

    objectives[Objectives.NAME_WHORMHOLE_1] = {
      name: Objectives.NAME_WHORMHOLE_1,
      vp: 4,
      description: "Control the space area of 2 systems with a whormhole.",
    };

    objectives[Objectives.NAME_MINOR_FACTION_1] = {
      name: Objectives.NAME_MINOR_FACTION_1,
      vp: 4,
      description:
        "Control the space area of systems which combined an amount of 12 minor factions or more.",
    };

    objectives[Objectives.NAME_SPACE_MIDDLE_1] = {
      name: Objectives.NAME_SPACE_MIDDLE_1,
      vp: 4,
      description:
        "Control the space area of 5 systems which have a distant from your home system of at least 2.",
    };

    objectives[Objectives.NAME_PLANET_MIDDLE_1] = {
      name: Objectives.NAME_PLANET_MIDDLE_1,
      vp: 4,
      description:
        "Control 4 planets in systems which have a distant from your home system of at least 2.",
    };

    objectives[Objectives.NAME_SPACE_FAR_1] = {
      name: Objectives.NAME_SPACE_FAR_1,
      vp: 4,
      description:
        "Control the space area of 3 systems which have a distant from your home system of at least 3.",
    };

    objectives[Objectives.NAME_PLANET_FAR_1] = {
      name: Objectives.NAME_PLANET_FAR_1,
      vp: 4,
      description:
        "Control 2 planets in systems which have a distant from your home system of at least 3.",
    };

    objectives[Objectives.NAME_SPACE_HS_1] = {
      name: Objectives.NAME_SPACE_HS_1,
      vp: 5,
      description: "Control the space area of 1 other player's home system.  ",
    };

    objectives[Objectives.NAME_PLANET_HS_1] = {
      name: Objectives.NAME_PLANET_HS_1,
      vp: 7,
      description: "Control 1 planet in another player's home system.",
    };
    /*objectives[Objectives.NAME_PLANET_BONUS_MINERAL_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_MINERAL_1,
      vp: 4,
      description:  "Control 2 planets with a mineral bonus."
    };
    objectives[Objectives.NAME_PLANET_BONUS_CREDIT_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_CREDIT_1,
      vp: 4,
      description: "Control 2 planets with a credit bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_SCIENCE_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_SCIENCE_1,
      vp: 4,
      description:       "Control 2 planets with a science bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_ENERGY_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_ENERGY_1,
      vp: 4,
      description: "Control 2 planets with an energy bonus."
    };

    objectives[Objectives.NAME_PLANET_BONUS_POPULATION_1] =
    {
      name: Objectives.NAME_PLANET_BONUS_POPULATION_1,
      vp: 4,
      description: "Control 2 planets with a population bonus."
    };*/

    objectives[Objectives.NAME_TECH_RED_1] = {
      name: Objectives.NAME_TECH_RED_1,
      vp: 4,
      description: "Have 4 red techs.",
    };
    objectives[Objectives.NAME_TECH_BLUE_1] = {
      name: Objectives.NAME_TECH_BLUE_1,
      vp: 4,
      description: "Have 4 blue techs.",
    };
    objectives[Objectives.NAME_TECH_GREEN_1] = {
      name: Objectives.NAME_TECH_GREEN_1,
      vp: 4,
      description: "Have 4 green techs.",
    };
    objectives[Objectives.NAME_TECH_YELLOW_1] = {
      name: Objectives.NAME_TECH_YELLOW_1,
      vp: 4,
      description: "Have 4 yellow techs.",
    };
    objectives[Objectives.NAME_TECH_DIVERSITY_1] = {
      name: Objectives.NAME_TECH_DIVERSITY_1,
      vp: 4,
      description: "Have 2 techs of each color.",
    };
    objectives[Objectives.NAME_STRUCTURES_CANON_1] = {
      name: Objectives.NAME_STRUCTURES_CANON_1,
      vp: 4,
      description: "Have at least 8 planetary canons.",
    };
    objectives[Objectives.NAME_STRUCTURES_FACTORY_1] = {
      name: Objectives.NAME_STRUCTURES_FACTORY_1,
      vp: 4,
      description: "Have 4 at least 4 factories.",
    };
    objectives[Objectives.NAME_STRUCTURES_AO_1] = {
      name: Objectives.NAME_STRUCTURES_AO_1,
      vp: 4,
      description: "Have at least 3 assembly orbitals.",
    };
    objectives[Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1] = {
      name: Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1,
      vp: 4,
      description: "Have at least 5 planetary shields.",
    };

    objectives[Objectives.NAME_ARTIFACT_1] = {
      name: Objectives.NAME_ARTIFACT_1,
      vp: 4,
      description: "Have 6 artifacts.",
    };
    objectives[Objectives.NAME_RELIC_1] = {
      name: Objectives.NAME_RELIC_1,
      vp: 4,
      description: "Have 4 relics of other factions.",
    };
    objectives[Objectives.NAME_DESTROYER_1] = {
      name: Objectives.NAME_DESTROYER_1,
      vp: 4,
      description: "Have at least one destroyer in 8 different systems.",
    };
    objectives[Objectives.NAME_CRUISER_1] = {
      name: Objectives.NAME_CRUISER_1,
      vp: 4,
      description: "Have at least one cruiser in 6 different systems.",
    };
    objectives[Objectives.NAME_DREADNOUGHT_1] = {
      name: Objectives.NAME_DREADNOUGHT_1,
      vp: 4,
      description: "Have at least one dreadnought in 6 different systems.",
    };
    objectives[Objectives.NAME_CARRIER_1] = {
      name: Objectives.NAME_CARRIER_1,
      vp: 4,
      description: "Have at least 6 carriers.",
    };
    objectives[Objectives.NAME_MECH_1] = {
      name: Objectives.NAME_MECH_1,
      vp: 4,
      description: "Have at least one mech on 5 different planets",
    };
    return objectives;
  };

  static getImageUrl = (name) => {
    switch (name) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_SCIENCE_1;
      case Objectives.NAME_SPEND_MINERAL_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_MINERAL_1;
      case Objectives.NAME_SPEND_ENERGY_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_ENERGY_1;
      case Objectives.NAME_SPEND_CREDIT_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_CREDIT_1;
      case Objectives.NAME_SPEND_POPULATION_1:
        return ImagesUrl.URL_OBJECTIVE_SPEND_POPULATION_1;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_ICE_WATER_1;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_EARTH_DESERT_1;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_GAZ_VOLCANIC_1;
      case Objectives.NAME_EMPTY_SPACE_1:
        return ImagesUrl.URL_OBJECTIVE_EMPTY_SPACE_1;
      case Objectives.NAME_WHORMHOLE_1:
        return ImagesUrl.URL_OBJECTIVE_WHORMHOLE_1;
      case Objectives.NAME_MINOR_FACTION_1:
        return ImagesUrl.URL_OBJECTIVE_MINOR_FACTION_1;
      case Objectives.NAME_SPACE_MIDDLE_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_MIDDLE_1;
      case Objectives.NAME_PLANET_MIDDLE_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_MIDDLE_1;
      case Objectives.NAME_SPACE_FAR_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_FAR_1;
      case Objectives.NAME_PLANET_FAR_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_FAR_1;
      case Objectives.NAME_SPACE_HS_1:
        return ImagesUrl.URL_OBJECTIVE_SPACE_HS_1;
      case Objectives.NAME_PLANET_HS_1:
        return ImagesUrl.URL_OBJECTIVE_PLANET_HS_1;
      case Objectives.NAME_TECH_RED_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_RED_1;
      case Objectives.NAME_TECH_BLUE_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_BLUE_1;
      case Objectives.NAME_TECH_GREEN_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_GREEN_1;
      case Objectives.NAME_TECH_YELLOW_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_YELLOW_1;
      case Objectives.NAME_TECH_DIVERSITY_1:
        return ImagesUrl.URL_OBJECTIVE_TECH_DIVERSITY_1;
      case Objectives.NAME_STRUCTURES_CANON_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_CANON_1;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_FACTORY_1;
      case Objectives.NAME_STRUCTURES_AO_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_AO_1;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        return ImagesUrl.URL_OBJECTIVE_STRUCTURES_PLANETARY_SHIELS_1;
      case Objectives.NAME_ARTIFACT_1:
        return ImagesUrl.URL_OBJECTIVE_ARTIFACT_1;
      case Objectives.NAME_RELIC_1:
        return ImagesUrl.URL_OBJECTIVE_RELIC_1;
      case Objectives.NAME_DESTROYER_1:
        return ImagesUrl.URL_OBJECTIVE_DESTROYER_1;
      case Objectives.NAME_CRUISER_1:
        return ImagesUrl.URL_OBJECTIVE_CRUISER_1;
      case Objectives.NAME_DREADNOUGHT_1:
        return ImagesUrl.URL_OBJECTIVE_DREADNOUGHT_1;
      case Objectives.NAME_CARRIER_1:
        return ImagesUrl.URL_OBJECTIVE_CARRIER_1;
      case Objectives.NAME_MECH_1:
        return ImagesUrl.URL_OBJECTIVE_MECH_1;
      default:
        throw new Error("No image found for objective: " + name);
    }
  };

  static generateRandomObjectivesArray(size) {
    //Prepare faction desc array
    const objectivesList = Object.values(this.getSelectedObjectives());
    const amountArrayNeeded = Math.ceil(size / objectivesList.length);

    let objectiveDeck = [];
    for (let i = 0; i < amountArrayNeeded; i++) {
      objectiveDeck = objectiveDeck.concat(
        JSON.parse(JSON.stringify(objectivesList))
      );
    }

    CustomMath.shuffleArray(objectiveDeck);

    return objectiveDeck;
  }
}

module.exports = Objectives;
