class ImagesUrl {
  static IMAGE_PATH_BASE = "/Images/";

  static URL_MINERAL = "/Images/Resources/mineral.png";
  static URL_CREDIT = "/Images/Resources/credit.png";
  static URL_ENERGY = "/Images/Resources/energy.png";
  static URL_SCIENCE = "/Images/Resources/science.png";
  static URL_POPULATION = "/Images/Resources/pop.png";
  static URL_SIZE = "/Images/Resources/size.png";
  static URL_INFLUENCE = "/Images/Resources/influence.png";
  static URL_CARGO = "/Images/Resources/cargo.png";
  static URL_SPACE_COMBAT = "/Images/OtherIcons/space_combat.png";
  static URL_GROUND_COMBAT = "/Images/OtherIcons/ground_combat.png";
  static URL_PLANET_INFO = "/Images/OtherIcons/planet_info.png";
  static URL_CONTEXT_OPEN = "/Images/OtherIcons/context_open.png";
  static URL_CONTEXT_CLOSE = "/Images/OtherIcons/context_close.png";
  static URL_BUILD = "/Images/OtherIcons/build.png";
  static URL_MISSION = "/Images/OtherIcons/mission_icon.png";
  static URL_MISSION_ACCOMPLISHED =
    "/Images/OtherIcons/mission_accomplished_icon.png";
  static URL_ACTION = "/Images/OtherIcons/action_icon.png";
  static URL_VP = "/Images/OtherIcons/vp_icon.png";

  static URL_FACTION_ARBOREC = "/Images/Faction/logo_arborec.png";
  static URL_FACTION_LETNEV = "/Images/Faction/logo_letnev.png";
  static URL_FACTION_SAAR = "/Images/Faction/logo_saar.png";
  static URL_FACTION_MUAAT = "/Images/Faction/logo_muaat.png";
  static URL_FACTION_HACAN = "/Images/Faction/logo_hacan.png";
  static URL_FACTION_SOL = "/Images/Faction/logo_sol.png";
  static URL_FACTION_CREUSS = "/Images/Faction/logo_creuss.png";
  static URL_FACTION_L1Z1X = "/Images/Faction/logo_l1.png";
  static URL_FACTION_MENTAK = "/Images/Faction/logo_mentak.png";
  static URL_FACTION_NAALU = "/Images/Faction/logo_naalu.png";
  static URL_FACTION_NEKRO = "/Images/Faction/logo_nekro.png";
  static URL_FACTION_SARDAKK = "/Images/Faction/logo_sardack.png";
  static URL_FACTION_JOL = "/Images/Faction/logo_jol.png";
  static URL_FACTION_WINNU = "/Images/Faction/logo_winnu.png";
  static URL_FACTION_XXCHA = "/Images/Faction/logo_xxcha.png";
  static URL_FACTION_YIN = "/Images/Faction/logo_yin.png";
  static URL_FACTION_YSSARIL = "/Images/Faction/logo_yssaril.png";
  static URL_FACTION_ARGENT = "/Images/Faction/logo_argent.png";
  static URL_FACTION_EMPYREAN = "/Images/Faction/logo_empyrean.png";
  static URL_FACTION_MAHACT = "/Images/Faction/logo_mahact.png";
  static URL_FACTION_NAAZ = "/Images/Faction/logo_naaz.png";
  static URL_FACTION_NOMAD = "/Images/Faction/logo_nomad.png";
  static URL_FACTION_TITAN = "/Images/Faction/logo_titan.png";
  static URL_FACTION_CABAL = "/Images/Faction/logo_cabal.png";

  //Exiled

  static URL_ANDAR = "/Images/MinorFaction/an.png";
  static URL_AETHERION = "/Images/MinorFaction/ae.png";
  static URL_BOREALIS = "/Images/MinorFaction/bo.png";
  static URL_CALVARIA = "/Images/MinorFaction/ca.png";
  static URL_DELPHAS = "/Images/MinorFaction/de.png";
  static URL_DRAKOR = "/Images/MinorFaction/dr.png";
  static URL_ERINYS = "/Images/MinorFaction/ei.png";
  static URL_EROSIUM = "/Images/MinorFaction/eo.png";
  static URL_FELTOR = "/Images/MinorFaction/fe.png";
  static URL_GHELOR = "/Images/MinorFaction/gh.png";
  static URL_HELKARAN = "/Images/MinorFaction/he.png";
  static URL_ICANTA = "/Images/MinorFaction/ic.png";
  static URL_JELORIA = "/Images/MinorFaction/je.png";
  static URL_KORATH = "/Images/MinorFaction/ko.png";
  static URL_KOBAMDA = "/Images/MinorFaction/kb.png";
  static URL_LARAN = "/Images/MinorFaction/la.png";
  static URL_MALTRION = "/Images/MinorFaction/ma.png";
  static URL_MINARI = "/Images/MinorFaction/mi.png";
  static URL_OPHARIAN = "/Images/MinorFaction/op.png";
  static URL_PROTHIAN = "/Images/MinorFaction/pr.png";
  static URL_QUORIDIOM = "/Images/MinorFaction/qu.png";
  static URL_RYNZORATH = "/Images/MinorFaction/ry.png";
  static URL_SELTAAR = "/Images/MinorFaction/se.png";
  static URL_TERRAN = "/Images/MinorFaction/te.png";
  static URL_TAARKIR = "/Images/MinorFaction/ta.png";
  static URL_UTARION = "/Images/MinorFaction/ut.png";
  static URL_VOIDBORN = "/Images/MinorFaction/vo.png";
  static URL_WOLTHAAR = "/Images/MinorFaction/wo.png";
  static URL_XELPHAR = "/Images/MinorFaction/xe.png";
  static URL_XENOS = "/Images/MinorFaction/xn.png";
  static URL_ZYLORIAN = "/Images/MinorFaction/zy.png";

  //Objectives
  static URL_OBJECTIVE_SPEND_SCIENCE_1 = "/Images/Objectives/obj_science.png";
  static URL_OBJECTIVE_SPEND_MINERAL_1 = "/Images/Objectives/obj_mineral.png";
  static URL_OBJECTIVE_SPEND_ENERGY_1 = "/Images/Objectives/obj_energy.png";
  static URL_OBJECTIVE_SPEND_CREDIT_1 = "/Images/Objectives/obj_credit.png";
  static URL_OBJECTIVE_SPEND_POPULATION_1 =
    "/Images/Objectives/obj_population.png";
  static URL_OBJECTIVE_PLANET_ICE_WATER_1 =
    "/Images/Objectives/obj_planet_oc_ice.png";
  static URL_OBJECTIVE_PLANET_EARTH_DESERT_1 =
    "/Images/Objectives/obj_planet_ter_des.png";
  static URL_OBJECTIVE_PLANET_GAZ_VOLCANIC_1 =
    "/Images/Objectives/obj_planet_gaz_vol.png";
  static URL_OBJECTIVE_EMPTY_SPACE_1 =
    "/Images/Objectives/obj_system_empty.png";
  static URL_OBJECTIVE_WHORMHOLE_1 = "/Images/Objectives/obj_wh.png";
  static URL_OBJECTIVE_MINOR_FACTION_1 = "/Images/Objectives/obj_exiled.png";
  static URL_OBJECTIVE_SPACE_MIDDLE_1 =
    "/Images/Objectives/obj_system_dist_2.png";
  static URL_OBJECTIVE_PLANET_MIDDLE_1 =
    "/Images/Objectives/obj_planet_dist_2.png";
  static URL_OBJECTIVE_SPACE_FAR_1 = "/Images/Objectives/obj_system_dist_3.png";
  static URL_OBJECTIVE_PLANET_FAR_1 =
    "/Images/Objectives/obj_planet_dist_3.png";
  static URL_OBJECTIVE_SPACE_HS_1 = "/Images/Objectives/obj_system_hs.png";
  static URL_OBJECTIVE_PLANET_HS_1 = "/Images/Objectives/obj_planet_hs.png";
  static URL_OBJECTIVE_TECH_RED_1 = "/Images/Objectives/obj_tech_red.png";
  static URL_OBJECTIVE_TECH_BLUE_1 = "/Images/Objectives/obj_tech_blue.png";
  static URL_OBJECTIVE_TECH_GREEN_1 = "/Images/Objectives/obj_tech_green.png";
  static URL_OBJECTIVE_TECH_YELLOW_1 = "/Images/Objectives/obj_tech_yellow.png";
  static URL_OBJECTIVE_TECH_DIVERSITY_1 =
    "/Images/Objectives/obj_tech_divers.png";
  static URL_OBJECTIVE_STRUCTURES_CANON_1 =
    "/Images/Objectives/obj_plan_canon.png";
  static URL_OBJECTIVE_STRUCTURES_FACTORY_1 =
    "/Images/Objectives/obj_factory.png";
  static URL_OBJECTIVE_STRUCTURES_AO_1 = "/Images/Objectives/obj_ao.png";
  static URL_OBJECTIVE_STRUCTURES_PLANETARY_SHIELS_1 =
    "/Images/Objectives/obj_plan_shield.png";
  static URL_OBJECTIVE_ARTIFACT_1 = "/Images/Objectives/obj_artifact.png";
  static URL_OBJECTIVE_RELIC_1 = "/Images/Objectives/obj_relic.png";
  static URL_OBJECTIVE_DESTROYER_1 = "/Images/Objectives/obj_destroyer.png";
  static URL_OBJECTIVE_CRUISER_1 = "/Images/Objectives/obj_cruser.png";
  static URL_OBJECTIVE_DREADNOUGHT_1 = "/Images/Objectives/obj_dread.png";
  static URL_OBJECTIVE_CARRIER_1 = "/Images/Objectives/obj_carrier.png";
  static URL_OBJECTIVE_MECH_1 = "/Images/Objectives/obj_mech.png";

  //Other icons
  static URL_CHAT_ICON = "/Images/OtherIcons/chat_icon.png";
  static URL_TRANSACTION_ICON = "/Images/OtherIcons/transaction_icon.png";
  static URL_MENU_ICON = "/Images/OtherIcons/menu_icon.png";
  static URL_INFO_ICON = "/Images/OtherIcons/info_icon.png";

  static URL_FACTION_POPULATION_ARBOREC = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_LETNEV = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_SAAR = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_MUAAT = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_HACAN = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_SOL = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_CREUSS = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_L1Z1X = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_MENTAK = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_NAALU = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_NEKRO = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_SARDAKK = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_JOL = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_WINNU = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_XXCHA = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_YIN = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_YSSARIL = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_ARGENT = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_EMPYREAN = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_MAHACT = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_NAAZ = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_NOMAD = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_TITAN = ImagesUrl.URL_POPULATION;
  static URL_FACTION_POPULATION_CABAL = ImagesUrl.URL_POPULATION;

  static getLogoUrl(logoName) {}

  //unit
  static URL_UNIT_BASE = "/Images/Units/";

  //Tech
  static URL_IMAGE_TECH_BLUE = "/Images/Resources/tech_blue.png";
  static URL_IMAGE_TECH_RED = "/Images/Resources/tech_red.png";
  static URL_IMAGE_TECH_YELLOW = "/Images/Resources/tech_yellow.png";
  static URL_IMAGE_TECH_GREEN = "/Images/Resources/tech_green.png";

  //Map
  static URL_MAP_BACKGROUND = "/Images/Map/fond/map_fond.png";
  static URL_PORTAL = "/Images/Map/warp_portal.png";

  static BACK_MINERAL_FIELD = "/Images/Map/system_background/mineral_field.png";
  static BACK_ASTEROID_FIELD =
    "/Images/Map/system_background/asteroid_field.png";
  static BACK_UTORIUM_DUST = "/Images/Map/system_background/utorium_dust.png";
  static BACK_STORM = "/Images/Map/system_background/storm.png";
  static BACK_CRYSTAL_FIELD = "/Images/Map/system_background/crystal_field.png";
  static BACK_FLUX_ANOMALY = "/Images/Map/system_background/flux_anomaly.png";
  static BACK_NEBULAE = "/Images/Map/system_background/nebulae.png";

  static SECONDARY_OBJECT_WORMHOLE_B = "/Images/Map/wormhole/wh_b.png";
  static SECONDARY_OBJECT_WORMHOLE_O = "/Images/Map/wormhole/wh_o.png";
  static SECONDARY_OBJECT_WORMHOLE_P = "/Images/Map/wormhole/wh_p.png";
  static SECONDARY_OBJECT_WORMHOLE_R = "/Images/Map/wormhole/wh_r.png";
  static SECONDARY_OBJECT_WORMHOLE_Y = "/Images/Map/wormhole/wh_y.png";
  static SECONDARY_OBJECT_WORMHOLE_U = "/Images/Map/wormhole/wh_u.png";
  static SECONDARY_OBJECT_WORMHOLE_T = "/Images/Map/wormhole/wh_t.png";
  static SECONDARY_OBJECT_WORMHOLE_G = "/Images/Map/wormhole/wh_g.png";

  static MAP_CONNECTION_IMAGE_BASE = "/Images/Map/wormhole/whb_";
  static MAP_CONNECTION_NUMBER_BASE = "/Images/Map/wormhole/whbn_";
  static MAP_CONNECTION_IMAGE_1 = "/Images/Map/wormhole/whb_1";
  static MAP_CONNECTION_IMAGE_2 = "/Images/Map/wormhole/whb_2";
  static MAP_CONNECTION_IMAGE_3 = "/Images/Map/wormhole/whb_3";
  static MAP_CONNECTION_IMAGE_4 = "/Images/Map/wormhole/whb_4";
  static MAP_CONNECTION_IMAGE_5 = "/Images/Map/wormhole/whb_5";
  static MAP_CONNECTION_IMAGE_6 = "/Images/Map/wormhole/whb_6";
  static MAP_CONNECTION_IMAGE_7 = "/Images/Map/wormhole/whb_7";
  static MAP_CONNECTION_IMAGE_8 = "/Images/Map/wormhole/whb_8";
  static MAP_CONNECTION_NUMBER_1 = "/Images/Map/wormhole/whbn_1.png";
  static MAP_CONNECTION_NUMBER_2 = "/Images/Map/wormhole/whbn_2.png";
  static MAP_CONNECTION_NUMBER_3 = "/Images/Map/wormhole/whbn_3.png";
  static MAP_CONNECTION_NUMBER_4 = "/Images/Map/wormhole/whbn_4.png";
  static MAP_CONNECTION_NUMBER_5 = "/Images/Map/wormhole/whbn_5.png";
  static MAP_CONNECTION_NUMBER_6 = "/Images/Map/wormhole/whbn_6.png";
  static MAP_CONNECTION_NUMBER_7 = "/Images/Map/wormhole/whbn_7.png";
  static MAP_CONNECTION_NUMBER_8 = "/Images/Map/wormhole/whbn_8.png";
}

module.exports = ImagesUrl;
