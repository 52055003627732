import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ProposeTransactionView from "./ProposeTransactionView";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose";
import Item from "../../Data/GameData/Transactions/Item";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const ItemView = ({ item, exhaustColorChange = true }) => {
  const itemDesc = Item.getDescription(item);
  const type = itemDesc.type;
  const style = {};
  if (Item.isItemExhausted(item) && exhaustColorChange) {
    style.color = "grey";
  }

  const DisplayRelic = () => {
    return (
      <span>
        <FactionLogo factionName={itemDesc.faction}></FactionLogo>
        {itemDesc.text}
      </span>
    );
  };

  const DisplayArtifact = () => {
    return <span>{itemDesc.name}</span>;
  };

  return (
    <span style={style}>
      {type === Item.TYPE_RELIC && <DisplayRelic></DisplayRelic>}
      {type === Item.TYPE_ARTIFACT && <DisplayArtifact></DisplayArtifact>}
    </span>
  );
};

export default ItemView;
