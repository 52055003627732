import React, { useState } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";

export const MainMenu = ({ setRouteMainHandler }) => {
  const [activeTab, setActiveTab] = useState("listOfGames");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    //setRouteMain({ activeScreen: "MainMenu" });
  };

  return (
    <div>
      <h1>Main Menu</h1>
      <div style={{ margin: "5px" }}>User Name : {StaticGameData.username}</div>
      <div>
        {/* Tab buttons */}
        <button onClick={() => handleTabChange("listOfGames")}>
          List of Games
        </button>
        <button onClick={() => handleTabChange("userProfile")}>
          User Profile Config
        </button>
        <button onClick={() => handleTabChange("options")}>Options</button>
        <button onClick={() => handleTabChange("chat")}>Chat</button>
        <button onClick={() => handleTabChange("friends")}>Friends</button>
      </div>
      <div>
        {/* Tab content */}
        {activeTab === "listOfGames" && (
          <GameListView
            setRouteMainHandler={setRouteMainHandler}
          ></GameListView>
        )}
        {activeTab === "userProfile" && <div>User Profile Config Content</div>}
        {activeTab === "options" && <div>Options Content</div>}
        {activeTab === "chat" && <div>Chat Content</div>}
        {activeTab === "friends" && (
          <div>
            <TestSimpleComponent></TestSimpleComponent>
          </div>
        )}
      </div>
    </div>
  );
};
