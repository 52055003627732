class Game {
  static createGame(gameId, maxNumberPlayers) {
    return {
      gameId: gameId,
      maxNumberPlayers: maxNumberPlayers,
      players: [], //players
      isTutorial: false,
    };
  }

  //Players
  static addPlayer(game, username) {
    game.players.push({
      username: username,
      color: null,
      isWaitingForNewRound: false,
      faction: null,
      factionPickList: null,
      playerInGameId: game.players.length + 1,
    });
  }

  //Factions
  static setPlayerFactionPickList(game, username, factionPickList) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].factionPickList = factionPickList;
  }

  static setPlayerFaction(game, factionName, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].faction = factionName;
  }

  static getPlayerFaction(game, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return game.players[playerIndex].faction;
  }

  static getPlayerFromUsername(game, username) {
    return game.players.find((player) => player.username === username);
  }

  //username
  static getPlayersUsernames(game) {
    return game.players.map((player) => player.username);
  }

  static getUsedColors(game) {
    return game.players.map((player) => player.color);
  }

  static setIsWaitingForNewRoundForPlayer(game, username, isWaiting) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].isWaitingForNewRound = isWaiting;
  }

  static getIsWaitingForNewRoundForPlayer(game, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return game.players[playerIndex].isWaitingForNewRound;
  }

  static areAllPlayersWaitingForNewRound(game) {
    let allPlayersWaiting = true;

    for (let i = 0; i < game.players.length; i++) {
      if (
        !game.players[i].isWaitingForNewRound &&
        game.players[i].username !== "tutorial"
      ) {
        allPlayersWaiting = false;
        break;
      }
    }
    return allPlayersWaiting;
  }

  static setAllPlayersWaitingForNewRound(game, isWaiting) {
    game.players.forEach((player) => (player.isWaitingForNewRound = isWaiting));
  }

  //Filter
  static filterGameHiddenData(game, username) {
    game.players.forEach((player) => {
      if (player.username !== username) {
        delete player.username;
      }
    });
  }
}

module.exports = Game;
