import React, { useState } from "react";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Logo from "../Utils/Logo";
import ImagesUrl from "../../Data/Other/ImagesUrl";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Color from "../../Common/Config/Colors";
import BaseButton from "../Utils/BaseButton";
import MinorFactionActionData from "../../Data/GameData/ActionData/MinorFactionActionData";
import ResolveMinorFactionAbility, {
  hasResolvedMinorFactionAbility,
} from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import MinorFactionAbilityInput from "../Ability/MinorFactionAbilityInput";
import MinorFactionMissionInput from "../Ability/MinorFactionMissionInput";
import SpaceObject from "../../Data/GameData/MapData/SpaceObject";
import Rules from "../../Data/GameData/Game/Rules";
import Faction from "../../Data/GameData/PlayerData/Faction";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ResolveMinorFactionMission from "../../Data/GameData/Ability/ResolveMinorFactionMission";

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const IMAGE_SIZE_SQUARE = 20;
const IMAGE_SIZE_ICON_SQUARE = "10px";
const IMAGE_SIZE_OVAL_HEIGHT = 10;
const IMAGE_SIZE_OVAL_WIDTH = 30;
const IMAGE_SIZE_PORTRAIT = 28;

const FONT_SIZE = "8px";

const ObjectiveView = ({
  spaceObject,
  mapView = false,
  size = IMAGE_SIZE_SQUARE,
}) => {
  const objectiveData = SecondaryObject.getObjectiveData(spaceObject);
  const objectiveDesc = Objectives.createDesc(objectiveData);

  const DisplayObjectiveImage = () => {
    if (!objectiveData || !objectiveData.name) return;

    const imageStyle = {
      height: size + "px",
      width: size + "px",
      //backgroundColor: "green",

      //verticalAlign: "bottom",
      //objectFit: "cover", // Ensures the image covers the area while preserving aspect ratio
    };

    return (
      <div
        style={{
          //position: "relative",
          //width: size + "px",
          //height: size + "px",
          //border: Math.max(size / 100, 1) + "px solid gold",
          //boxShadow: "0 0 0 " + size / 100 + "px gold", // Simulated thin border
          //borderRadius: Math.max(size / 40, 1) + "px",
          //backgroundColor: "rgb(200,200,200,0.5)",
          height: size + "px",
          width: size + "px",
          position: "relative",
          //backgroundColor: "yellow",
        }}
      >
        <img
          style={imageStyle}
          src={Objectives.getImageUrl(objectiveData.name)}
          alt={"LOGO_" + objectiveData.name}
        />
        {false && <DisplayMissionIcon></DisplayMissionIcon>}
      </div>
    );
  };

  const DisplaySimpleBanniere = () => {
    const missionDone = objectiveDesc.misionDone;
    return (
      <span
        style={{
          fontSize: size / 2 + "px",
          //backgroundColor: "purple",
        }}
      >
        {false && <Logo logoName="action"></Logo>}
        {mapView && (
          <span style={{ color: missionDone ? "grey" : "white" }}>
            {objectiveDesc.vp}
          </span>
        )}
        {false && (
          <span>
            <Logo logoName="population"></Logo>
          </span>
        )}
      </span>
    );
  };

  const DisplayCard = () => {
    return (
      <div
        className="div-ver-center"
        style={{
          padding: "2px",
          backgroundColor: Color.COLOR_LIGHT_SHADOW,
          borderRadius: size / 8,
          width: size + "px",
          //padding: size / 8 + "px",
        }}
      >
        <DisplayObjectiveImage></DisplayObjectiveImage>
        {false && (
          <div
            style={{
              marginBottom: size / 40 + "px",
              backgroundColor: "purple",
            }}
          ></div>
        )}
        <DisplaySimpleBanniere></DisplaySimpleBanniere>
      </div>
    );
  };

  const DisplayMission = ({}) => {
    console.log("objectiveDesc=", objectiveDesc);
    const missionVP = objectiveDesc.vp;
    const missionDescription = objectiveDesc.description;
    const missionDone = objectiveDesc.missionDone;

    const logoName = missionDone ? "mission_accomplished" : "mission";
    const buttonType = missionDone ? "navigation" : "white";
    return (
      <div>
        <BaseButton
          type={buttonType}
          onClick={() => {
            MinorFactionMissionInput.routeMinorFactionMissionInput(spaceObject);
          }}
        >
          <div style={{ textDecoration: "underline" }}>
            {false && <Logo logoName={logoName}></Logo>} Objective : ({" "}
            {missionVP} VP )
          </div>
          <div>{missionDescription}</div>
        </BaseButton>
      </div>
    );
  };

  const DisplayMissionIcon = () => {
    const missionDone = objectiveDesc.missionDone;
    const urlImage = missionDone
      ? ImagesUrl.URL_MISSION_ACCOMPLISHED
      : ImagesUrl.URL_MISSION;
    return (
      <div
        className="div-fill"
        style={{
          position: "absolute",
          top: "50%",
          left: "90%",
          transform: "translate(-50%, -50%)",
          width: size / 3 + "px",
          height: size / 3 + "px",
          //backgroundColor: "blue",
        }}
      >
        <img
          style={{ width: size / 3 + "px", height: size / 3 + "px" }}
          src={urlImage}
          alt={"MISSION_" + objectiveDesc.name}
        />
      </div>
    );
  };

  const DisplayMenuView = () => {
    return (
      <div className="div-ver-center" style={{ textAlign: "center" }}>
        <div>Objective : </div>
        <DisplayCard></DisplayCard>
        <DisplayMission></DisplayMission>
      </div>
    );
  };

  return (
    <div>
      {mapView && <DisplayCard></DisplayCard>}
      {!mapView && <DisplayMenuView></DisplayMenuView>}
    </div>
  );
};

export default ObjectiveView;
