import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ProposeTransactionView from "./ProposeTransactionView";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose";
import Item from "../../Data/GameData/Transactions/Item";
import ActivationNavigationView from "../ManageSystem/ActivationView/ActivationNavigationView";
import ItemView from "./ItemView";
import Color from "../../Common/Config/Colors";
import AbilityView from "../Ability/AbilityView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ItemAbilityInput from "../Ability/ItemAbilityInput";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const ItemDetailedView = ({ playerId, itemId }) => {
  const playerData = PlayerData.getPlayerDataFromInGameId(
    StaticGameData.getPlayerData(),
    playerId
  );
  const item = Item.getItemFromId(playerData.items, itemId);
  const itemDesc = Item.getDescription(item);
  const type = itemDesc.type;
  console.log("RENDER ItemDetailedView: ");
  const DisplayRelic = () => {
    return (
      <span>
        <FactionLogo factionName={itemDesc.faction}></FactionLogo>
        {itemDesc.text}
      </span>
    );
  };

  const DisplayArtifact = () => {
    return <span>{itemDesc.name}</span>;
  };

  const DisplayUseItem = () => {
    console.log("RENDER DisplayUseItem: ", item);
    const itemDesc = Item.getDescription(item);

    if (!Item.hasAnActiveAbility(itemDesc)) {
      return;
    }
    return (
      <div style={{ marginTop: "0.5em" }}>
        {item.isExhausted ? (
          <BaseButton
            type="greyOut"
            onClick={() => {
              if (itemDesc.type === Item.TYPE_RELIC) {
                UIMessage.displayInfoMessage(
                  "Exhausted",
                  "This item is exhausted, you cannot use it for now. As it is an exhausted relic, it will be readied and sent back to the faction which produced it at the beginning of the next round."
                );
              } else {
                UIMessage.displayInfoMessage(
                  "Exhausted",
                  "This item is exhausted, you cannot use it for now. It will be readied at the beginning of the next round."
                );
              }
            }}
          >
            Exhausted
          </BaseButton>
        ) : (
          <BaseButton
            onClick={() => {
              ItemAbilityInput.routeItemAbilityInput(item);
            }}
          >
            Use
          </BaseButton>
        )}
      </div>
    );
  };

  const DisplayGeneral = () => {
    const itemDesc = Item.getDescription(item);
    //console.log("itemDesc", itemDesc);
    return (
      <span>
        <ItemView item={item}></ItemView>
        <div>Item type : {itemDesc.type} </div>
        <div>State : {Item.isItemExhausted(item) ? "exhausted" : "ready"}</div>
        {itemDesc.type === Item.TYPE_RELIC && (
          <div>
            <div>
              Faction producer of the relic :{" "}
              <FactionLogo factionName={itemDesc.faction}></FactionLogo>{" "}
              {itemDesc.faction}
            </div>
            <div>
              (A relic can be given only by the faction which produced it)
            </div>
            <div>
              (An exhausted relic goes back to the faction which produced it at
              the start of a round)
            </div>
          </div>
        )}
        {itemDesc.type === Item.TYPE_ARTIFACT && (
          <div>
            {Item.hasAnActiveAbility(itemDesc) && (
              <div>
                (An exhausted artefact is destroyed at the start of a round)
              </div>
            )}
          </div>
        )}

        {itemDesc.abilities.map((abilityDesc, index) => {
          console.log("abilityDesc", abilityDesc);
          return (
            <div
              key={index}
              style={{
                backgroundColor: Color.COLOR_GREY_3,
                padding: "10px",
                borderRadius: "5px",
                marginTop: "1em",
                marginBottom: "1em",
              }}
            >
              <AbilityView abilityDesc={abilityDesc}></AbilityView>
            </div>
          );
        })}
        <div>
          <DisplayUseItem></DisplayUseItem>
        </div>
      </span>
    );
  };

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  return (
    <GameGlobalLayout backgroundType="uniform">
      <div
        className="text-box"
        style={{ textAlign: "center", lineHeight: "2em" }}
      >
        <DisplayGeneral></DisplayGeneral>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ItemDetailedView;
