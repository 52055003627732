import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import FactionLogo from "../Utils/FactionLogo";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import CostView from "../Utils/CostView";
import Logo from "../Utils/Logo";
import TechTypeView from "./TechTypeView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ResearchActionData from "../../Data/GameData/ActionData/ResearchActionData";
import PrerequisitesView from "./PrerequisitesView";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const TechTree = require("../../Data/GameData/Technology/TechTree.js");
const Tech = require("../../Data/GameData/Technology/Tech.js");
const LogBook = require("../../Data/GameData/Connection/LogBook");

const TechButtonView = ({ playerInGameId }) => {
  const playerData = StaticGameData.getPlayerData();
  const anyPlayerData = PlayerData.getPlayerDataFromInGameId(
    playerData,
    playerInGameId
  );
  const techTree = anyPlayerData.techTree;

  const ownedReq = TechTree.getOwnedRequisites(techTree);

  let content = <span>Tech</span>;
  if (ownedReq.length > 0) {
    content = <PrerequisitesView prerequisites={ownedReq}></PrerequisitesView>;
  }

  return (
    <BaseButton
      onClick={() => {
        Popup.addLayer({
          name: "TechTreeView",
          playerInGameId: anyPlayerData.playerInGameId,
        });
      }}
    >
      {content}
    </BaseButton>
  );
};

export default TechButtonView;
