import React, { useState, useEffect, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import BaseButton from "../Utils/BaseButton";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import FactionLogo from "../Utils/FactionLogo";
import ChatRoomView from "./ChatRoomView";

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";

const ChatFactionLogo = ({ playerInGameId, showFactionName = false }) => {
  const playerData = StaticGameData.getPlayerData();

  const DisplayLogos = ({}) => {
    let displayItem = null;

    const otherPlayerData = PlayerData.getPlayerDataFromInGameId(
      playerData,
      playerInGameId
    );
    if (otherPlayerData.faction) {
      displayItem = (
        <span>
          <FactionLogo factionName={otherPlayerData.faction.name}></FactionLogo>{" "}
          {showFactionName && otherPlayerData.faction.name}
        </span>
      );
    } else {
      const factionsToPickFrom = otherPlayerData.factionsToPickFrom;
      displayItem = (
        <span>
          {factionsToPickFrom.map((faction, index) => {
            if (index === 0) {
              return (
                <span key={index}>
                  <FactionLogo factionName={faction.name}></FactionLogo>
                  {showFactionName && faction.name}
                </span>
              );
            } else {
              return (
                <span key={index}>
                  {" "}
                  / <FactionLogo factionName={faction.name}></FactionLogo>
                  {showFactionName && faction.name}
                </span>
              );
            }
          })}
        </span>
      );
    }

    return (
      <span>
        <span>{displayItem}</span>
      </span>
    );
  };

  return <DisplayLogos></DisplayLogos>;
};

export default ChatFactionLogo;
