const Color = require("../../../Common/Config/Colors");

const CustomMath = require("../../../Common/Math/CustomMath");
const Tech = require("./Tech");
const TechList = require("./TechList");

class TechTree {
  static MAX_TECH_LEVEL = 5;
  //static AMOUNT_TECH_PER_LEVEL = 6;
  //static AMOUNT_TECH_MAX_LEVEL = 3;
  static AMOUNT_TECH_PER_LEVEL_ARRAY = [7, 5, 9, 4, 2];
  static EXTRA_COST_IF_ONE_LEVEL_DOWN = 2;
  static AMOUNT_COLOR_TECH = 4;
  static MIN_TECH_ONE_LEVEL_DOWN = 1;
  static MAX_TECH_ONE_LEVEL_DOWN = 1;

  static createEmptyTechTree() {
    const techTree = {
      techs: [],
    };
    return techTree;
  }

  static create() {
    const techTree = {
      techs: [],
    };

    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      techTree.techs.push([]);
    }
    return techTree;
  }

  static addTechAtLevel(techTree, tech, level) {
    techTree.techs[level - 1].push(tech);
  }

  //Get
  static getTechAbilitiesOwned(techTree) {
    const techs = this.getAllTechs(techTree);
    return techs.filter((tech) => tech.owned && tech.active);
  }

  static getTechOwned(techTree) {
    const techs = this.getAllTechs(techTree);
    return techs.filter((tech) => tech.owned);
  }

  static getTechListAtLevel(techTree, level) {
    return techTree.techs[level];
  }

  static getAllTechs(techTree) {
    let techs = [];
    for (let i = 0; i < techTree.techs.length; i++) {
      techs.push(...techTree.techs[i]);
    }

    techs = techs.sort((a, b) => {
      if (a.whiteTech === true && b.whiteTech === false) {
        return true;
      }
      if (b.whiteTech === true && a.whiteTech === false) {
        return false;
      }
      if (a.color === b.color) {
        return a.level - b.level;
      }
      return a.color.localeCompare(b.color);
    });

    return techs;
  }

  static hasPlayerTech(playerData, techName) {
    const techTree = this.getTechTree(playerData);
    const techs = this.getAllTechs(techTree);
    return techs.some((tech) => tech.name === techName && tech.owned);
  }

  static verifyHasPlayerTech(playerData, techName) {
    if (!this.hasPlayerTech(playerData, techName)) {
      throw new Error("Player does not have the tech");
    }
  }

  static getAllTechFromColor(techTree, color) {
    const techs = this.getAllTechs(techTree);
    return techs.filter((tech) => tech.color === color);
  }

  static getTechTree(playerData) {
    if (!playerData.technologyTree) {
      playerData.technologyTree = null;
    }
    return playerData.techTree;
  }

  static getAmountTechMaxLevel() {
    return this.AMOUNT_TECH_MAX_LEVEL;
  }

  static getAmountTechAtLevel(level) {
    return this.AMOUNT_TECH_PER_LEVEL_ARRAY[level - 1];
    /*if (level < this.MAX_TECH_LEVEL) {
      return this.AMOUNT_TECH_PER_LEVEL;
    } else {
      return this.getAmountTechMaxLevel();
    }*/
  }

  static getTechFromName(techTree, techName) {
    const techs = this.getAllTechs(techTree);
    return techs.find((tech) => tech.name === techName);
  }

  static getRequirementSet() {
    return [
      Color.COLOR_NAME_TECH_BLUE,
      Color.COLOR_NAME_TECH_GREEN,
      Color.COLOR_NAME_TECH_RED,
      Color.COLOR_NAME_TECH_YELLOW,
    ];
  }

  static getOwnedRequisites(techTree) {
    const techs = this.getAllTechs(techTree);
    const ownedReq = [];
    for (let i = 0; i < techs.length; i++) {
      if (techs[i].owned && techs[i].whiteTech === false) {
        ownedReq.push(techs[i].color);
      }
    }
    return ownedReq.sort((a, b) => a.localeCompare(b));
  }

  //set

  static setTechTree(playerData, techTree) {
    playerData.techTree = techTree;
  }

  //Organize

  static sortTechByColorAndLevel(techTree) {
    for (let i = 0; i < techTree.techs.length; i++) {
      techTree.techs[i].sort((a, b) => {
        if (a.whiteTech === true && b.whiteTech === false) {
          return true;
        }
        if (b.whiteTech === true && a.whiteTech === false) {
          return false;
        }
        if (a.color === b.color) {
          return a.level - b.level;
        }
        return a.color.localeCompare(b.color);
      });
    }
  }

  //Generate

  static generateTechTree() {
    //Get all techs
    const techs = JSON.parse(JSON.stringify(Object.values(TechList.TECH)));
    //console.log("tech list  : ", techs);
    const techTree = this.create();

    //Organize techs in levels

    const techsInLevels = [];
    const techsInTargetLevels = [];

    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      techsInLevels.push([]);
      techsInTargetLevels.push([]);
    }
    for (let i = 0; i < techs.length; i++) {
      const tech = techs[i];
      if (tech.whiteTech === false) {
        techsInLevels[tech.level - 1].push(tech);
      }
    }
    /*for (let i = 0; i < techsInLevels.length; i++) {
      console.log(
        "techsInLevels [" + i + "] length: ",
        techsInLevels[i].length
      );
    }*/
    for (let i = 0; i < techsInLevels.length; i++) {
      CustomMath.shuffleArray(techsInLevels[i]);
      /*console.log(
        "techsInLevels [" + i + "] length: ",
        techsInLevels[i].length
      );*/
    }

    //Add techs to techsInTargetLevels

    for (let i = this.MAX_TECH_LEVEL - 1; i >= 0; i--) {
      //console.log("current level : ", i + 1);
      // console.log("tech in current level  length: ", techsInLevels[i].length);
      /*if (i < this.MAX_TECH_LEVEL - 1)
        console.log("tech in level up length : ", techsInLevels[i + 1].length);*/
      //Set amount of techs from one level up
      let amountOfTechFromOneLevelUp = CustomMath.generateRandomNumber(
        this.MIN_TECH_ONE_LEVEL_DOWN,
        this.MAX_TECH_ONE_LEVEL_DOWN
      );
      if (i === this.MAX_TECH_LEVEL - 1) {
        amountOfTechFromOneLevelUp = 0;
      }

      //Pick random tech to add to this level
      for (
        let j = 0;
        j < this.getAmountTechAtLevel(i + 1) - amountOfTechFromOneLevelUp;
        j++
      ) {
        //Randomly pick one tech from this levelµ
        const tech = techsInLevels[i].pop();
        tech.level = i + 1;
        techsInTargetLevels[i].push(tech);
      }

      for (let j = 0; j < amountOfTechFromOneLevelUp; j++) {
        const tech = techsInLevels[i + 1].pop();
        this.adaptCostIfOneLevelDown(techTree, tech);
        tech.level = i + 1;
        techsInTargetLevels[i].push(tech);
      }
    }

    //const extraPrimaryReq = this.prepareExtraPrimaryReqPool();
    //const secondaryReqPool = this.prepareColorPool();
    //const thirdReqPool = this.prepareColorPool();
    const extraReqPool = this.prepareColorPool().concat(
      this.prepareColorPool().concat(
        this.prepareColorPool().concat(this.prepareColorPool())
      )
    );

    const colorPool = this.prepareColorPool();

    let compteur = 1;
    //Assign requirements to techs
    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      for (
        let j = 0;
        j < techsInTargetLevels[i].length &&
        j < this.getAmountTechAtLevel(i + 1);
        j++
      ) {
        const tech = techsInTargetLevels[i][j];

        //Set tech pre requisites

        compteur++;
        this.assignReqToTech(tech, colorPool, extraReqPool);

        this.addTechAtLevel(techTree, tech, i + 1);
      }
    }

    this.generateWhiteTechTree(techTree);

    this.sortTechByColorAndLevel(techTree);

    return techTree;
  }

  static generateWhiteTechTree(techTree) {
    const techs = JSON.parse(JSON.stringify(Object.values(TechList.TECH)));
    const whiteTechsInLevels = [];
    const whiteTechsInTargetLevels = [];
    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      whiteTechsInLevels.push([]);
      whiteTechsInTargetLevels.push([]);
    }
    for (let i = 0; i < techs.length; i++) {
      const tech = techs[i];
      if (tech.whiteTech === true) {
        whiteTechsInLevels[tech.level - 1].push(tech);
        whiteTechsInTargetLevels[tech.level - 1].push(tech);
      }
    }

    const colorPool = this.prepareColorPoolForWhiteTechs(10);
    const extraReqPool = this.prepareColorReqPoolForWhiteTechs(100);
    for (let i = 0; i < whiteTechsInTargetLevels.length; i++) {
      for (let j = 0; j < whiteTechsInTargetLevels[i].length; j++) {
        const tech = whiteTechsInTargetLevels[i][j];
        this.assignReqToWhiteTech(tech, colorPool, extraReqPool);
        this.addTechAtLevel(techTree, tech, i + 1);
      }
    }
  }

  static assignReqToWhiteTech(tech, colorPool, extraReqPool) {
    const primaryColor = colorPool.pop();

    tech.color = Color.COLOR_NAME_TECH_WHITE;
    Tech.addPrimaryRequirement(tech, primaryColor);
    if (Tech.getEmptyRequirementSlotSize(tech) <= 0) {
      return;
    }

    //Define secondary req
    Tech.addSecondaryRequirement(tech, extraReqPool.pop());
    if (Tech.getEmptyRequirementSlotSize(tech) <= 0) {
      return;
    }

    //Define third req
    Tech.addThirdRequirement(tech, extraReqPool.pop());
  }

  static assignReqToTech(tech, colorPool, extraReqPool, l) {
    const primaryColor = colorPool.pop();

    tech.color = primaryColor;
    Tech.addPrimaryRequirement(tech, primaryColor);
    if (Tech.getEmptyRequirementSlotSize(tech) <= 0) {
      return;
    }

    //Define secondary req
    Tech.addSecondaryRequirement(tech, extraReqPool.pop());
    if (Tech.getEmptyRequirementSlotSize(tech) <= 0) {
      return;
    }

    //Define third req
    Tech.addThirdRequirement(tech, extraReqPool.pop());
  }

  /*static preparePrimaryReqPool(level) {
    const amountTech = this.getAmountTechAtLevel(level);
    const amountCycle = Math.floor(amountTech / this.AMOUNT_COLOR_TECH);

    const poolPrimaryReq = [];
    for (let i = 0; i < amountCycle; i++) {
      poolPrimaryReq.push(...this.getRequirementSet());
    }

    CustomMath.shuffleArray(poolPrimaryReq);
    return poolPrimaryReq;
  }*/

  static prepareColorPoolForWhiteTechs(amountCycle) {
    const colorPool = [];
    //const poolTempPrimaryReq = [];
    for (let i = 0; i < amountCycle; i++) {
      const colorSet = this.getRequirementSet();
      CustomMath.shuffleArray(colorSet);
      colorPool.push(...colorSet);
    }

    return colorPool;
  }

  static prepareColorReqPoolForWhiteTechs(amountCycle) {
    const colorPool = [];
    //const poolTempPrimaryReq = [];
    for (let i = 0; i < amountCycle; i++) {
      const colorSet = this.getRequirementSet();
      CustomMath.shuffleArray(colorSet);
      colorPool.push(...colorSet);
    }

    return colorPool;
  }

  static prepareColorPool() {
    //Color of the tech is defining which pre requisite it fills
    let amountColor = 0;
    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      const amountTech = this.getAmountTechAtLevel(i + 1);
      amountColor = amountColor + amountTech;
    }

    //Prepare requirement pool for primary req color
    const colorPool = [];
    //const poolTempPrimaryReq = [];
    for (let i = 0; i < Math.ceil(amountColor / this.AMOUNT_COLOR_TECH); i++) {
      const colorSet = this.getRequirementSet();
      CustomMath.shuffleArray(colorSet);
      colorPool.push(...colorSet);
    }

    return colorPool;
  }

  /*static prepareExtraPrimaryReqPool() {
    //Count amount of extra primary req necessary
    let amount = 0;
    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      const amountTech = this.getAmountTechAtLevel(i + 1);
      let amountTechWithExtraReq = amountTech % this.AMOUNT_COLOR_TECH;
      amount = amount + amountTechWithExtraReq;
    }

    //Prepare requirement pool for primary req color
    const extraPrimaryReqPool = [];
    //const poolTempPrimaryReq = [];
    for (let i = 0; i < Math.ceil(amount / this.AMOUNT_COLOR_TECH); i++) {
      extraPrimaryReqPool.push(...this.getRequirementSet());
    }
    CustomMath.shuffleArray(extraPrimaryReqPool);

    return extraPrimaryReqPool;
  }*/

  /*static prepareSecondaryReqPool() {
    const secondaryReqPool = [];

    //Count how many secondary req there could be
    let amount = 0;
    for (let i = 0; i < this.MAX_TECH_LEVEL; i++) {
      if (i > 1) {
        // No secondary for 1st and 2nd level
        amount = amount + this.AMOUNT_TECH_PER_LEVEL;
      }
    }

    //Prepare pool
    for (let i = 0; i < Math.ceil(amount / this.AMOUNT_COLOR_TECH); i++) {
      secondaryReqPool.push(...this.getRequirementSet());
    }
    CustomMath.shuffleArray(secondaryReqPool);
    return secondaryReqPool;
  }*/

  static adaptCostIfOneLevelDown(techTree, tech) {
    tech.cost.science = tech.cost.science + this.EXTRA_COST_IF_ONE_LEVEL_DOWN;
  }

  static exhaustTech(techTree, techName) {
    if (TechTree.isTechExhausted(techTree, techName)) {
      throw new Error(
        "Tech is exhausted. You already used it once during this round."
      );
    }

    const tech = this.getTechFromName(techTree, techName);
    Tech.exhaust(tech);
  }

  static isTechExhausted(techTree, techName) {
    const tech = this.getTechFromName(techTree, techName);
    return Tech.isExhausted(tech);
  }

  static readyAllTechs(playerData) {
    const techTree = this.getTechTree(playerData);
    const techs = this.getAllTechs(techTree);
    for (let i = 0; i < techs.length; i++) {
      Tech.ready(techs[i]);
    }
  }
}

module.exports = TechTree;
