const LogBook = require("../Connection/LogBook");
const CombatData = require("./CombatData");
const System = require("../MapData/System");
const CombatRecording = require("./CombatRecording");
const Unit = require("../MapData/Unit");
const ResolveData = require("../EndOfRound/ResolveData");
const CustomMath = require("../../../Common/Math/CustomMath");
const Fleet = require("../MapData/Fleet");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const PlayerData = require("../PlayerData/PlayerData");
const Item = require("../Transactions/Item");

class CombatFunctions {
  static getObject() {
    return {
      rollOnce: this.rollOnce,
      assignProducedHitsToFleetCombat: this.assignProducedHitsToFleetCombat,
      assignHits: this.assignHits,
      repairRandomDamagesOnUnits: this.repairRandomDamagesOnUnits,
      damageAllUnitsInFleet: this.damageAllUnitsInFleet,
      damageAUnit: this.damageAUnit,
      destroyAUnit: this.destroyAUnit,
    };
  }

  static rollOnce(min, max) {
    const hits = CustomMath.roundDec(CustomMath.generateRandomReal(min, max));
    return hits;
  }

  static assignProducedHitsToFleetCombat(logBook, fleetCombat, hits) {
    //const roundedHits = Math.floor(hits);
    const roundedHits = CustomMath.roundDec(hits);

    LogBook.generateAddMessage(
      logBook,
      "$faction$ produced " + roundedHits + " hits.",
      [fleetCombat.fleet.faction]
    );
    fleetCombat.hits = roundedHits;
  }

  static assignHits(
    recData,
    resolveData,
    combatData,
    system,
    planet = null,
    filtering = null,
    damageSource = null,
    context = [] //firtsRound,secondRound,thirdRound,ground,space
  ) {
    const playerData = ResolveData.getAnyPlayerData(resolveData);
    const fleetCombatList = combatData.fleetCombatList;
    const detailedLog = LogBook.createLogBook();
    const globalLog = LogBook.createLogBook();

    /*console.log(
      "Assigning hits START to amount fleets : " + fleetCombatList.length
    );*/
    //Check how many HP each fleet has
    for (let i = 0; i < fleetCombatList.length; i++) {
      const fleetForMeasuringHPTotal = Fleet.createFleet(
        playerData,
        fleetCombatList[i].fleet.faction
      );

      /*console.log(
        "Assigning hits measuring total HP of : " +
          fleetCombatList[i].fleet.faction
      );*/

      //Filterinf by types filter by types if types and classes if classes
      const filteredUnits = Fleet.getFilteredUnits(
        fleetCombatList[i].fleet,
        filtering
      );
      Fleet.addUnits(fleetForMeasuringHPTotal, filteredUnits);

      fleetCombatList[i].totalHP = Fleet.getTotalHP(
        playerData,
        fleetForMeasuringHPTotal
      );
      fleetCombatList[i].hitToAssign = 0;
      /*console.log(
        "Assigning hits total HP of " +
          fleetCombatList[i].fleet.faction +
          " is : " +
          fleetCombatList[i].totalHP
      );*/
    }

    //Check how many hits to assign to each fleet
    for (let i = 0; i < fleetCombatList.length; i++) {
      /*console.log(
        "Assigning define how many hits to assign to " +
          fleetCombatList[i].fleet.faction
      );*/
      const fleetCombatGiving = fleetCombatList[i];
      let otherFleetHPSum = 0;
      const fleetCombatReceivingList = fleetCombatList.filter(
        (fleetCombatReceiving) =>
          fleetCombatReceiving.fleet.faction !== fleetCombatGiving.fleet.faction
      );

      for (let j = 0; j < fleetCombatReceivingList.length; j++) {
        otherFleetHPSum = otherFleetHPSum + fleetCombatReceivingList[j].totalHP;
      }

      let totalAssignedHits = 0;
      for (let j = 0; j < fleetCombatReceivingList.length; j++) {
        const assignedHit = CustomMath.roundDec(
          (fleetCombatReceivingList[j].totalHP / otherFleetHPSum) *
            fleetCombatGiving.hits
        );
        fleetCombatReceivingList[j].hitToAssign = CustomMath.roundDec(
          fleetCombatReceivingList[j].hitToAssign + assignedHit
        );
        totalAssignedHits = totalAssignedHits + assignedHit;
      }

      //Assign the rest
      /*const rest = fleetCombatList.hits - totalAssignedHits;
      CustomMath.shuffleArray(fleetCombatReceivingList);
      for (let j = 0; j < rest && j < fleetCombatReceivingList.length; j++) {
        fleetCombatReceivingList[j].hitToAssign =
          fleetCombatReceivingList[j].hitToAssign + 1;
      }*/
    }

    //Assign hits to HP of units
    for (let i = 0; i < fleetCombatList.length; i++) {
      //console.log("Assigning hits  to " + fleetCombatList[i].fleet.faction);
      const logDetailAssign = LogBook.createLogBook();
      const highLevelLog = LogBook.createLogBook();

      const fleetCombat = fleetCombatList[i];

      //Damage reduced due to abilities
      {
        let multiplicationModifiers = [];
        let modifiers = [];
        let abilitiesNames = [];
        let modificationOccured = false;

        //Deplhas Scroll
        if (
          Item.playerHasExhaustedItem(playerData, Item.NAME_DELPHAS_SCROLL) &&
          (damageSource === "spaceCanonDefense" ||
            damageSource === "planetaryCanonDefense")
        ) {
          modifiers.push(-4);
          abilitiesNames.push(Item.NAME_DELPHAS_SCROLL);
          modificationOccured = true;
          LogBook.generateAddMessage(
            detailedLog,
            Item.NAME_DELPHAS_SCROLL + " : damage reduced by 4.",
            []
          );
        }

        //Voidborn Scroll
        if (
          Item.playerHasExhaustedItem(playerData, Item.NAME_VOIDBORN_SCROLL) &&
          damageSource === "bombardment"
        ) {
          modifiers.push(-2);
          abilitiesNames.push(Item.NAME_VOIDBORN_SCROLL);
          modificationOccured = true;
          LogBook.generateAddMessage(
            detailedLog,
            Item.NAME_VOIDBORN_SCROLL + " : damage reduced by 2.",
            []
          );
        }

        //Maltrion Scroll
        if (
          Item.playerHasExhaustedItem(playerData, Item.NAME_MALTRION_SCROLL) &&
          context.includes("firstRound")
        ) {
          modifiers.push(-4);
          abilitiesNames.push(Item.NAME_MALTRION_SCROLL);
          modificationOccured = true;
          LogBook.generateAddMessage(
            detailedLog,
            Item.NAME_MALTRION_SCROLL + " : damage reduced by 4.",
            []
          );
        }

        //Applying multipliers
        let previousHitToAssign = fleetCombat.hitToAssign;
        let globalMultiplicationModifier = 1;
        let globalModifier = 0;
        for (let j = 0; j < multiplicationModifiers.length; j++) {
          globalMultiplicationModifier =
            globalMultiplicationModifier * multiplicationModifiers[j];
        }
        for (let j = 0; j < modifiers.length; j++) {
          globalModifier = globalModifier + modifiers[j];
        }
        fleetCombat.hitToAssign =
          fleetCombat.hitToAssign * globalMultiplicationModifier +
          globalModifier;

        //Logging if modification occured
        if (modificationOccured) {
          LogBook.generateAddMessage(
            detailedLog,
            "Hits after applying modifiers : " +
              CustomMath.roundDec(previousHitToAssign) +
              " + " +
              globalModifier +
              " = " +
              CustomMath.roundDec(fleetCombat.hitToAssign) +
              ".",
            []
          );
          LogBook.generateAddMessage(
            detailedLog,
            "Hits to assign to $faction$ have been reduced due to " +
              abilitiesNames.join(", ") +
              " : " +
              CustomMath.roundDec(previousHitToAssign) +
              " -> " +
              CustomMath.roundDec(fleetCombat.hitToAssign),
            [fleetCombat.fleet.faction]
          );
        }
      }

      const units = Fleet.getFilteredUnits(fleetCombat.fleet, filtering);
      const unitsTargetable = units.filter(
        (unit) => unit.class !== Unit.CLASS_STRUCTURE
      );
      let totalHP = fleetCombat.totalHP;
      if (!fleetCombat.hitToAssign) fleetCombat.hitToAssign = 0;
      const hitToAssignRound = Math.floor(fleetCombat.hitToAssign);
      let hitToAssign = hitToAssignRound;

      const logDetailAssignData = [];
      for (let j = 0; j < units.length; j++) {
        const unit = units[j];
        logDetailAssignData.push({
          unit: unit,
          damage: 0,
          isDestroyed: false,
        });
      }
      const logDamage = (unit, damage, unitDestroyed) => {
        for (let j = 0; j < logDetailAssignData.length; j++) {
          if (logDetailAssignData[j].unit.id === unit.id) {
            logDetailAssignData[j].damage =
              logDetailAssignData[j].damage + damage;
            if (unitDestroyed === true) {
              logDetailAssignData[j].isDestroyed = true;
              //System.removeUnit(playerData, system, unit); Is done by damage unit
            }
            break;
          }
        }
      };

      const getListOfUnitsDestroyed = () => {
        const destroyedUnits = [];
        for (let j = 0; j < logDetailAssignData.length; j++) {
          if (logDetailAssignData[j].isDestroyed) {
            destroyedUnits.push(logDetailAssignData[j].unit);
          }
        }
        return destroyedUnits;
      };

      /*console.log(
        "Assigning hits amount units targeatable to " +
          fleetCombatList[i].fleet.faction +
          " is : " +
          unitsTargetable.length
      );*/

      //let count = 100;
      //Each unit has a chance to each pro rated by the amount of its HP
      while (hitToAssign > 0 && unitsTargetable.length > 0) {
        //count--;
        //if (count <= 0) throw new Error("Infinite loop in assignHits");
        //console.log("hitToAssign : " + hitToAssign);
        //console.log("unitsTargetable.length : " + unitsTargetable.length);
        //console.log("totalHP : " + totalHP);
        if (totalHP > 0) {
          let random = CustomMath.generateRandomNumber(1, totalHP);
          //console.log("random : " + random);
          for (let j = 0; j < unitsTargetable.length; j++) {
            const targetUnit = unitsTargetable[j];
            /*console.log(
              "targetUnit : " +
                targetUnit.type +
                ", HP : " +
                Unit.getHP(playerData, targetUnit)
            );*/
            if (random <= Unit.getHP(playerData, targetUnit)) {
              //console.log("damage targetUnit");
              const result = CombatFunctions.damageAUnit(
                recData,
                resolveData,
                system,
                targetUnit,
                fleetCombat.fleet.faction,
                1,
                fleetCombat.fleet
              );
              /*console.log(
                "targetUnit HP : " +
                  Unit.getHP(playerData, targetUnit) +
                  ", unitDestroyed : " +
                  result.unitDestroyed
              );*/
              logDamage(targetUnit, 1, result.unitDestroyed);
              if (result.unitDestroyed === true) {
                unitsTargetable.splice(j, 1);
              }
              hitToAssign = hitToAssign - 1;
              break;
            } else {
              random = random - Unit.getHP(playerData, targetUnit);
              //console.log("next random : " + random);
            }
          }
        }
        totalHP = Fleet.getTotalHP(playerData, fleetCombatList[i].fleet);
      }

      //Log
      //console.log("Assigning hits logging");

      for (let j = 0; j < logDetailAssignData.length; j++) {
        const data = logDetailAssignData[j];

        if (data.damage > 0) {
          if (data.isDestroyed) {
            LogBook.generateAddMessage(
              logDetailAssign,
              "$unit$ took " + data.damage + " damage, and was destroyed.",
              [data.unit]
            );
          }
          LogBook.generateAddMessage(
            logDetailAssign,
            "$unit$ took " + data.damage + " damage.",
            [data.unit]
          );
        }
      }

      const destroyedFleet = Fleet.createFleet(
        playerData,
        fleetCombat.fleet.faction
      );
      Fleet.addUnits(destroyedFleet, getListOfUnitsDestroyed());
      LogBook.generateAddMessage(
        highLevelLog,
        "$faction$ is assigned " +
          CustomMath.roundDec(fleetCombat.hitToAssign) +
          " hits, rounded to " +
          hitToAssignRound +
          ".",
        [fleetCombat.fleet.faction]
      );
      if (!Fleet.isEmpty(destroyedFleet)) {
        LogBook.generateAddMessage(highLevelLog, "Units destroyed : $fleet$", [
          destroyedFleet,
        ]);
      }
      if (fleetCombat.hitToAssign > 0) {
        CombatRecording.createStep(
          recData,
          highLevelLog,
          logDetailAssign,
          system
        );
      }
    }
    //console.log("Assigning hits END");
  }

  static repairRandomDamagesOnUnits(
    recData,
    resolveData,
    combatData,
    amount,
    unitsP,
    faction,
    system,
    Hlog = LogBook.createLogBook(),
    Dlog = LogBook.createLogBook()
  ) {
    const playerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      faction
    );
    const units = unitsP.filter((unit) => unit.class !== Unit.CLASS_STRUCTURE);

    const logData = [];
    for (let i = 0; i < units.length; i++) {
      logData.push({
        unit: units[i],
        repaired: 0,
      });
    }

    const getTotalDamagedHP = () => {
      return units.reduce(
        (acc, unit) =>
          acc +
          (Unit.getMaxHP(playerData, unit) - Unit.getHP(playerData, unit)),
        0
      );
    };

    let repairPoints = amount;

    for (let i = 0; i < repairPoints; i++) {
      const totalDamagedHP = getTotalDamagedHP();
      if (totalDamagedHP > 0) {
        let random = CustomMath.generateRandomNumber(1, totalDamagedHP);
        for (let j = 0; j < units.length; j++) {
          const targetUnit = units[j];
          if (
            random <=
            Unit.getMaxHP(playerData, targetUnit) -
              Unit.getHP(playerData, targetUnit)
          ) {
            Unit.setHP(
              playerData,
              targetUnit,
              Unit.getHP(playerData, targetUnit) + 1
            );
            logData[j].repaired = logData[j].repaired + 1;
            break;
          } else {
            random =
              random -
              (Unit.getMaxHP(playerData, targetUnit) -
                Unit.getHP(playerData, targetUnit));
          }
        }
      }
    }

    let repairedTotal = 0;
    for (let i = 0; i < logData.length; i++) {
      const data = logData[i];
      if (data.repaired > 0) {
        repairedTotal += data.repaired;
        LogBook.generateAddMessage(
          Dlog,
          "$unit$ repaired " + data.repaired + " damage.",
          [data.unit]
        );
      }
    }
    LogBook.generateAddMessage(
      Hlog,
      "$faction$ repaired " + repairedTotal + " damages.",
      [faction]
    );

    if (repairedTotal > 0) {
      CombatRecording.createStep(recData, Hlog, Dlog, system);
    }
  }
  static damageAllUnitsInFleet(recData, resolveData, system, fleet, damage) {
    const Dlog = LogBook.createLogBook();
    const Hlog = LogBook.createLogBook();
    let hitAmount = 0;
    const unitDestroyed = [];
    const units = Fleet.getUnits(fleet);
    const playerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      fleet.faction
    );

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const result = CombatFunctions.damageAUnit(
        recData,
        resolveData,
        system,
        unit,
        fleet.faction,
        1,
        fleet
      );
      if (result.Dlog) LogBook.concat(result.Dlog, Dlog);

      hitAmount += result.damageDone;

      if (result.unitDestroyed === true) {
        //MapUnitaryAction.removeUnitFromSystem(playerData, system, unit);
        unitDestroyed.push(unit);
      }
    }

    const fleetDestroyed = Fleet.createFleet(playerData, fleet.faction);
    Fleet.addUnits(fleetDestroyed, unitDestroyed);

    LogBook.generateAddMessage(
      Hlog,
      "Total of " + hitAmount + " damage has been assigned to $faction$.",
      [fleet.faction]
    );
    if (unitDestroyed.length > 0) {
      LogBook.generateAddMessage(Hlog, "Units destroyed : $fleet$", [
        fleetDestroyed,
      ]);
    }

    CombatRecording.createStep(recData, Hlog, Dlog, system);

    return {
      Dlog: Dlog,
      Hlog: Hlog,
      hitAmount: hitAmount,
      unitDestroyed: unitDestroyed,
    };
  }

  static destroyAUnit(
    recData,
    resolveData,
    system,
    unit,
    faction,
    fleetToRemoveUnitFrom = null
  ) {
    const playerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      faction
    );

    //Scrap recuperation drones tech resolve
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.scrapRecuperationDrones.name
      )
    ) {
      if (
        unit.type !== Unit.UNIT_TYPE_FIGHTER &&
        unit.class === Unit.CLASS_SHIP
      ) {
        const mineralGetBack = CustomMath.roundDec(
          Unit.getCost(playerData, unit).mineral * 0.3
        );
        PlayerData.gainMineral(playerData, mineralGetBack);
        PlayerData.generateLogActivity(
          playerData,
          TechList.TECH.scrapRecuperationDrones.name +
            " : gained " +
            mineralGetBack +
            " mineral from destroyed unit : $unit$.",
          [unit]
        );
      }
    }
    System.removeUnit(playerData, system, unit);
    if (fleetToRemoveUnitFrom) {
      Fleet.removeUnit(fleetToRemoveUnitFrom, unit);
    }
  }

  static damageAUnit(
    recData,
    resolveData,
    system,
    unit,
    faction,
    damage,
    fleetToRemoveUnitFrom = null
  ) {
    const result = {
      damageDone: 0,
      unitDestroyed: false,
      Dlog: LogBook.createLogBook(),
    };
    const playerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      faction
    );

    const unitHP = Unit.getHP(playerData, unit);
    const readDamage = Math.min(damage, unitHP);
    Unit.setHP(playerData, unit, unitHP - readDamage);
    result.damageDone = readDamage;
    if (Unit.getHP(playerData, unit) <= 0) {
      result.unitDestroyed = true;
      CombatFunctions.destroyAUnit(
        recData,
        resolveData,
        system,
        unit,
        faction,
        fleetToRemoveUnitFrom
      );
      if (result.Dlog) {
        LogBook.generateAddMessage(
          result.Dlog,
          "$unit$ took " + readDamage + " damage and was destroyed.",
          [unit]
        );
      }
    } else {
      if (result.Dlog) {
        LogBook.generateAddMessage(
          result.Dlog,
          "$unit$ took " + readDamage + " damage.",
          [unit]
        );
      }
    }
    return result;
  }
}

module.exports = CombatFunctions;
