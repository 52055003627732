import React, { useState } from "react";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";
const IMAGE_MINOR_FACTION_LOGO_BASE = "/Images/MinorFaction/";

const FactionLogo = ({ factionName, heightRem }) => {
  //console.log("FactionLogo factionName: ", factionName);
  if (!factionName) {
    throw new Error("No faction to display");
  }

  const getImageFileNameForMinorFaction = (factionName) => {
    return (
      IMAGE_MINOR_FACTION_LOGO_BASE +
      Faction.getMinorFactionImageFileName(factionName)
    );
  };

  const getImageFileName = (factionName) => {
    // The Arborec
    // The Barony of Letnev
    // The Clan of Saar
    // The Embers of Muaat
    // The Emirates of Hacan
    // The Federation of Sol
    // The Ghosts of Creuss
    // The L1Z1X Mindnet
    // The Mentak Coalition
    // The Naalu Collective
    // The Nekro Virus
    // Sardakk N’orr
    // The Universities of Jol-Nar
    // The Winnu
    // The Xxcha Kingdom
    // The Yin Brotherhood
    // The Yssaril Tribes
    // The Argent Flight
    // The Empyrean
    // The Mahact Gene-Sorcerers
    // The Naaz-Rokha Alliance
    // The Nomad
    // The Titans of Ul
    // The Vuil'Raith Cabal

    if (factionName === Faction.FACTION_NAME_ARBOREC) {
      return ImagesUrl.URL_FACTION_ARBOREC;
    }
    if (factionName === Faction.FACTION_NAME_LETNEV) {
      return ImagesUrl.URL_FACTION_LETNEV;
    }
    if (factionName === Faction.FACTION_NAME_SAAR) {
      return ImagesUrl.URL_FACTION_SAAR;
    }
    if (factionName === Faction.FACTION_NAME_MUAAT) {
      return ImagesUrl.URL_FACTION_MUAAT;
    }
    if (factionName === Faction.FACTION_NAME_HACAN) {
      return ImagesUrl.URL_FACTION_HACAN;
    }
    if (factionName === Faction.FACTION_NAME_SOL) {
      return ImagesUrl.URL_FACTION_SOL;
    }
    if (factionName === Faction.FACTION_NAME_CREUSS) {
      return ImagesUrl.URL_FACTION_CREUSS;
    }
    if (factionName === Faction.FACTION_NAME_L1Z1X) {
      return ImagesUrl.URL_FACTION_L1Z1X;
    }
    if (factionName === Faction.FACTION_NAME_MENTAK) {
      return ImagesUrl.URL_FACTION_MENTAK;
    }
    if (factionName === Faction.FACTION_NAME_NAALU) {
      return ImagesUrl.URL_FACTION_NAALU;
    }
    if (factionName === Faction.FACTION_NAME_NEKRO) {
      return ImagesUrl.URL_FACTION_NEKRO;
    }
    if (factionName === Faction.FACTION_NAME_SARDAKK) {
      return ImagesUrl.URL_FACTION_SARDAKK;
    }
    if (factionName === Faction.FACTION_NAME_JOL) {
      return ImagesUrl.URL_FACTION_JOL;
    }
    if (factionName === Faction.FACTION_NAME_WINNU) {
      return ImagesUrl.URL_FACTION_WINNU;
    }
    if (factionName === Faction.FACTION_NAME_XXCHA) {
      return ImagesUrl.URL_FACTION_XXCHA;
    }
    if (factionName === Faction.FACTION_NAME_YIN) {
      return ImagesUrl.URL_FACTION_YIN;
    }
    if (factionName === Faction.FACTION_NAME_YSSARIL) {
      return ImagesUrl.URL_FACTION_YSSARIL;
    }
    if (factionName === Faction.FACTION_NAME_ARGENT) {
      return ImagesUrl.URL_FACTION_ARGENT;
    }
    if (factionName === Faction.FACTION_NAME_EMPYREAN) {
      return ImagesUrl.URL_FACTION_EMPYREAN;
    }
    if (factionName === Faction.FACTION_NAME_MAHACT) {
      return ImagesUrl.URL_FACTION_MAHACT;
    }
    if (factionName === Faction.FACTION_NAME_NAAZ) {
      return ImagesUrl.URL_FACTION_NAAZ;
    }
    if (factionName === Faction.FACTION_NAME_NOMAD) {
      return ImagesUrl.URL_FACTION_NOMAD;
    }
    if (factionName === Faction.FACTION_NAME_TITANS) {
      return ImagesUrl.URL_FACTION_TITAN;
    }
    if (factionName === Faction.FACTION_NAME_CABAL) {
      return ImagesUrl.URL_FACTION_CABAL;
    }
    if (Faction.isMinorFaction(factionName)) {
      return getImageFileNameForMinorFaction(factionName);
    }
    throw new Error("No faction logo found for " + factionName);
  };

  let style = {
    color: "white",
    //backgroundColor: "yellow",
    fontSize: "inherit", // Inherit the font size from the parent};
    font: "inherit",
    //padding: "inherit",
    //margin: "inherit",
    //boxShadow: "0 0 0 " + "1px" + " gold", // Simulated thin border
  };

  //console.log("FactionLogo heightRem: ", heightRem);
  const imageStyle = {
    width: "auto", // Adjust this percentage as needed
    height: heightRem ? `${heightRem}` : "1em", // Conditional height
    //height: "1rem",
    //border: "1px solid pink",
    //boxShadow: "0 0 0 " + "0.5px" + " gold", // Simulated thin border
  };

  //console.log("FactionLogo getImageFileName: ", getImageFileName(factionName));

  return (
    <span style={style}>
      <img
        style={imageStyle}
        src={getImageFileName(factionName)} // Replace with the actual image URL
        alt={"LOGO_" + factionName}
      />
    </span>
  );
};

export default FactionLogo;
